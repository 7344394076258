import { IRouteInfo } from "./interfaces/IRouteInfo";

export const routeInfoJsonArray: IRouteInfo[] = [
  {
    Id: "1",
    Name: "1",
  },
  {
    Id: "2",
    Name: "2",
  },
  {
    Id: "3",
    Name: "3",
  },
  {
    Id: "4",
    Name: "4",
  },
  {
    Id: "5",
    Name: "5",
  },
  {
    Id: "6",
    Name: "6",
  },
  {
    Id: "7",
    Name: "7",
  },
  {
    Id: "A",
    Name: "A",
  },
  {
    Id: "C",
    Name: "C",
  },
  {
    Id: "E",
    Name: "E",
  },
  {
    Id: "B",
    Name: "B",
  },
  {
    Id: "D",
    Name: "D",
  },
  {
    Id: "F",
    Name: "F",
  },
  {
    Id: "M",
    Name: "M",
  },
  {
    Id: "L",
    Name: "L",
  },
  {
    Id: "G",
    Name: "G",
  },
  {
    Id: "J",
    Name: "J",
  },
  {
    Id: "Z",
    Name: "Z",
  },
  {
    Id: "N",
    Name: "N",
  },
  {
    Id: "Q",
    Name: "Q",
  },
  {
    Id: "R",
    Name: "R",
  },
  {
    Id: "W",
    Name: "W",
  },
  {
    Id: "SIR",
    Name: "SIR",
  }
];
