import React, { Component } from "react";

export class LoadingSpinner extends Component {
    render() {
        return (
            <div className="text-center">
                <div className="spinner-border" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
            </div>
        )
    }
}