import axios from "axios";
import * as constants from "../constants";
import { IDeviceDto } from "./interfaces/IDeviceDto";

export async function GetScreens() {
  try {
    const response = await axios.get<IDeviceDto[]>(`${constants.heliumUrl}`, {
      timeout: 3000,
      headers: { Accept: "application/json" },
      validateStatus: (status) => status === 200,
      withCredentials: false,
    });

    return  getFormattedDeviceList(response.data);

  } catch (error) {
    console.log("API ERROR", error);
    return new Array<IDeviceDto>();
  }
}

export function GetStations(screens: IDeviceDto[]) {
  // Get unique stations
  // Use a Set to track unique ids and filter out duplicates
  return screens.filter(
    (screen, index, self) => index === self.findIndex((s) => s.id === screen.id)
  );
}

export function GetDevices(screens: IDeviceDto[], id: string) {
  return screens.filter((screen) => screen.id === id);
}

const getFormattedDeviceList = (devices: IDeviceDto[]) : IDeviceDto[] => {
  return devices.map((device) => {
        // Remove space from station_name
        const stationNameWithoutSpace = device.station_name.replace(/\s/g, "");

        // Combine station_name with lines array to form the id
        const id = stationNameWithoutSpace + device.lines.join("");

        // Return the updated station object with the new id
        return {
            ...device,
            id: id,
        };
    });
};
