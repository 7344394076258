import React, { Component } from "react";
import styled from "styled-components";
import { IAuthComponentProps } from "../../interfaces/ITemplateViewer";
import { NavbarComponent } from "../navbar/NavbarComponent";
import BlockUi from "react-block-ui";
import { LoadingSpinner } from "../common/LoadingSpinner";
import { NotificationContainer } from "react-notifications";
import { stationInfoJsonArray } from "../common/lirrStationInfoJson";
import { templateJsonArray } from "../common/templateInfoJson";
import { ILIRRStationInfo } from "../common/interfaces/ILIRRStationInfo";
import { ITemplateInfo } from "../common/interfaces/ITemplateInfo";
import { getDefaultScreenUrl, getScreenUrl } from "../../AppConfig";

interface ILIRRComponentState {
  loading: boolean;
  showError: any;
  blocking: boolean;
  showScreen: boolean;
  selectedStation: ILIRRStationInfo;
  selectedTemplate: ITemplateInfo;
  apiError?: any;
  routes: string[];
  stations: ILIRRStationInfo[];
  templates: ITemplateInfo[];
  currTemplateIndex: number;
}

export class LIRRComponent extends Component<
  IAuthComponentProps,
  ILIRRComponentState
> {
  //set up initial state here
  InitialITemplateInfo: ITemplateInfo = {
    Agency: "",
    Id: "",
    Name: "",
    Path: "",
    Direction: "",
    Screen: "",
    SortOrder: 0,
    Active: true,
    Width: 0,
    Height: 0,
    IncludeTrack: false,
    useOTPStopId: false,
    env: ""
  };

  InitialLIRRStationInfo: ILIRRStationInfo = {
    code: "",
    GTFS_StopID: "",
    branch: "",
    name: "",
    latitude: 0,
    longitude: 0,
    westName: "",
    eastName: "",
    zone: 0,
    exclude: false,
    old_StopID: "",
  };

  constructor(props: IAuthComponentProps) {
    super(props);
    this.state = {
      loading: false, // true
      showError: false,
      blocking: false,
      showScreen: false,
      selectedStation: this.InitialLIRRStationInfo,
      selectedTemplate: this.InitialITemplateInfo,
      apiError: null,
      routes: [],
      stations: new Array<ILIRRStationInfo>(),
      templates: new Array<ITemplateInfo>(),
      currTemplateIndex: -1,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    try {
      const templates = templateJsonArray
        .filter(({ Agency, Active }) => Agency === "LIRR" && Active === true)
        .sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));

      const sortedStations = stationInfoJsonArray.sort(function (a, b) {
        var nameA = a.name.toUpperCase(); // ignore upper and lowercase
        var nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1; //nameA comes first
        }
        if (nameA > nameB) {
          return 1; // nameB comes first
        }
        return 0; // names must be equal
      });

      this.setState({
        stations: sortedStations,
        templates,
        loading: false,
      });
    } catch (error) {
      this.setState({ showError: true, apiError: error, loading: false });
    }
  };

  render() {
    const { loading, showError } = this.state;
    if (loading) {
      return <LoadingSpinner />;
    }

    if (showError) {
      //return <ErrorFullPageComponent apiError={apiError} location={this.props.location} authUser={this.props.wrapper.authUser} />
    }

    return this.renderLIRRForm();
  }

  renderLIRRForm = () => {
    const isEnabled = this.canBeSubmitted();
    const isPrevBtnEnabled = this.enablePrevButton();
    const isNextBtnEnabled = this.enableNextButton();
    const { selectedTemplate, selectedStation, showScreen } = this.state;
    const stopId = selectedStation.GTFS_StopID;
                  // selectedTemplate.useOTPStopId === true
                  //   ? selectedStation.old_StopID
                  //   : selectedStation.GTFS_StopID;
    return (
      <div className="container">
        <NavbarComponent
          currentPath={this.props.location.pathname}
          activeCheck="idLIRR"
        />
        <div className="card mt-2 bg-white pt-5">
          <div className="card-body">
            <BlockUi tag="div" blocking={this.state.blocking}>
              <h5 className="card-title ">Long Island Rail Road</h5>
              <hr />
              <form autoComplete="off">
                <fieldset>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-md-10">{this.renderStation()}</div>
                      </div>
                      <div className="row">
                        <div className="col-md-10">{this.renderTemplate()}</div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div className="form-group">
                            <button
                              type="button"
                              className="btn btn-success btn-sm"
                              disabled={!isEnabled}
                              onClick={() =>
                                this.openInNewTab(
                                  `/#/screen/${stopId}/${selectedTemplate.Id}`
                                )
                              }
                            >
                              Show in Full Screen
                            </button>
                            &nbsp;&nbsp;
                            <button
                              type="button"
                              className="btn btn-success btn-sm"
                              disabled={!isPrevBtnEnabled}
                              onClick={this.previousScreen}
                            >
                              Previous
                            </button>
                            &nbsp;&nbsp;
                            <button
                              type="button"
                              className="btn btn-success btn-sm"
                              disabled={!isNextBtnEnabled}
                              onClick={this.nextScreen}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-12">
                          {showScreen === false && (
                            <VerticalContainer>
                              <VerticalIFrame
                                src={getDefaultScreenUrl()}
                                frameBorder="0"
                                scrolling="no"
                              />
                            </VerticalContainer>
                          )}

                          {showScreen === true &&
                            selectedTemplate.Id !== "" &&
                            selectedTemplate.Screen === "V" && (
                              <VerticalContainer>
                                <VerticalIFrame
                                  src={getScreenUrl(
                                    stopId,
                                    selectedTemplate,
                                    ""
                                  )}
                                  frameBorder="0"
                                  scrolling="no"
                                />
                              </VerticalContainer>
                            )}

                          {showScreen === true &&
                            selectedTemplate.Id !== "" &&
                            selectedTemplate.Screen === "H" && (
                              <HorizontalContainer>
                                <HorizontalIFrame
                                  src={getScreenUrl(
                                    stopId,
                                    selectedTemplate,
                                    ""
                                  )}
                                  frameborder="0"
                                  scrolling="no"
                                />
                              </HorizontalContainer>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </form>
            </BlockUi>
          </div>
        </div>
        <NotificationContainer />
      </div>
    );
  };

  renderStation = () => {
    return (
      <div className="form-group required">
        <label className="control-label">
          <b>Station</b>
        </label>
        <select
          className="form-control"
          id="actionSelect"
          onChange={this.handleStationChange}
        >
          <option key="-1" value={-1}>
            Select Station
          </option>
          {this.state.stations.map((station, index) => {
            return (
              <option key={station.GTFS_StopID} value={index}>
                {station.name}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  renderTemplate = () => {
    return (
      <div className="form-group required">
        <label className="control-label">
          <b>Template</b>
        </label>
        <select
          className="form-control"
          id="actionSelect"
          onChange={this.handleTemplateChange}
          value={this.state.currTemplateIndex}
        >
          <option key="0" value={-1}>
            Select Template
          </option>
          {this.state.templates.map((template, index) => {
            return (
              <option key={template.Id} value={index}>
                {template.Name}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  canBeSubmitted = () => {
    const { selectedStation, selectedTemplate } = this.state;

    if (selectedStation.GTFS_StopID === "" || selectedTemplate.Id === "") {
      return false;
    }
    return true;
  };

  enablePrevButton = () => {
    const { currTemplateIndex } = this.state;
    const canBeSubmitted = this.canBeSubmitted();

    if (!canBeSubmitted) {
      return false;
    } else if (currTemplateIndex <= 0) {
      return false;
    }
    return true;
  };

  enableNextButton = () => {
    const { currTemplateIndex, templates } = this.state;
    const canBeSubmitted = this.canBeSubmitted();
    if (!canBeSubmitted) {
      return false;
    } else if (currTemplateIndex === -1) {
      return false;
    } else if (currTemplateIndex === templates.length - 1) {
      return false;
    }
    return true;
  };

  previousScreen = () => {
    const currTemplateIndex =
      this.state.currTemplateIndex - 1 <= 0
        ? 0
        : this.state.currTemplateIndex - 1;
    const selectedTemplate = this.state.templates[currTemplateIndex];
    this.setState({
      showScreen: this.showScreen(this.state.selectedStation, selectedTemplate),
      currTemplateIndex,
      selectedTemplate,
    });
  };

  nextScreen = () => {
    const currTemplateIndex =
      this.state.currTemplateIndex + 1 >= this.state.templates.length - 1
        ? this.state.templates.length - 1
        : this.state.currTemplateIndex + 1;
    const selectedTemplate = this.state.templates[currTemplateIndex];
    this.setState({
      showScreen: this.showScreen(this.state.selectedStation, selectedTemplate),
      currTemplateIndex,
      selectedTemplate,
    });
  };

  showScreen = (
    selectedStation: ILIRRStationInfo,
    selectedTemplate: ITemplateInfo
  ) => {
    const showScreen =
      selectedStation.GTFS_StopID === "" || selectedTemplate.Id === ""
        ? false
        : true;

    return showScreen;
  };

  handleStationChange = (e: any) => {
    const selectedIndex = parseInt(e.target.value);
    const selectedStation =
      selectedIndex < 0
        ? this.InitialLIRRStationInfo
        : this.state.stations[selectedIndex];

    this.setState({
      selectedStation,
      showScreen: this.showScreen(selectedStation, this.state.selectedTemplate),
    });
  };

  handleTemplateChange = (e: any) => {
    const selectedIndex = parseInt(e.target.value);
    const selectedTemplate =
      selectedIndex < 0
        ? this.InitialITemplateInfo
        : this.state.templates[selectedIndex];

    this.setState({
      selectedTemplate,
      showScreen: this.showScreen(this.state.selectedStation, selectedTemplate),
      currTemplateIndex: selectedIndex,
    });
  };

  openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };
}

const VerticalContainer = styled.div`
  width: calc(1080px * 0.5);
  height: calc(1920px * 0.5);
  position: relative;
  margin-left: 50px;
`;

const VerticalIFrame = styled.iframe`
  width: 1080px;
  height: 1920px;
  transform: scale(0.4);
  transform-origin: 0 0;
  position: absolute;
  left: 0;
  top: 0;
`;

const HorizontalContainer = styled.div`
  width: calc(1920px * 0.5);
  height: calc(1080px * 0.5);
  position: relative;
`;

const HorizontalIFrame = styled.iframe`
  width: 1920px;
  height: 1080px;
  transform: scale(0.37);
  transform-origin: 0 0;
  position: absolute;
  left: 0;
  top: 0;
`;

const Wrapper = styled.div`
  margin: 10px auto;
`;
