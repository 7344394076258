import React, { Component, ReactNode } from "react";
import { Redirect } from "react-router-dom";
import { IAuthComponentProps } from "../../interfaces/ITemplateViewer";
import { IReleasesInfo } from "../common/interfaces/IReleasesInfo";
import { releaseInfoJson } from "../common/releaseInfoJson";
import { NavbarComponent } from "../navbar/NavbarComponent";

export class ReleaseComponent extends Component<IAuthComponentProps, {}> {
  public render() {
    var env = process.env.REACT_APP_ENV;
    if (env?.toUpperCase() === "PROD") {
      return (
        <div>
          {/* <NavbarComponent currentPath={this.props.location.pathname} activeCheck="idReleases" />
          <div className="card mt-2 bg-white pt-5">
            <div className="card-body">
              <h1>Release</h1>
              {this.renderReleases()}
            </div>
          </div> */}
        </div>
      );
    } else {
      return <Redirect to="/home" />;
    }
  }

  renderReleases = () => {
    const releases = releaseInfoJson.map((rel, index) => {
      return (
        <div>
          <hr />
          <h5>
            {rel.ReleaseId} - {rel.ReleaseDate}
          </h5>
          {rel.agencyInfo.map((agency, index) => {
            return (
              <div>
                <h6>{agency.Agency}</h6>
                {agency.screens.map((a, ind) => {
                  return (
                    <div>
                      {a.ReleaseInfo}
                      <br />
                      {/* <text>
                        <b>Screens Changed</b> :{" "}
                      </text>
                      {a.ScreensChanged} */}
                    </div>
                  );
                })}
              </div>
            );
          })}
          <br></br>
          <a href={rel.DownloadPath}>Download</a>
        </div>
      );
    });
    return releases;
  };
}
