import React, { Component } from "react";
import { Link } from "react-router-dom";
import {
    Collapse,
    Nav,
    Navbar,
    NavbarToggler,
    NavItem
} from "reactstrap";
import { getLogoutUrl } from "../../AppSettings";
import * as constants from "../../constants";
import * as currentUserapi from "../../apis/CurrentUserApi";
import { IUserDto } from "../../apis/interfaces/IUserDto";

interface INavbarProps {
    currentPath: string;
    activeCheck: string;
}

interface INavbarState {
    isOpen: boolean;
    userProfile: IUserDto | null;
}
export class NavbarComponent extends Component<INavbarProps, INavbarState> {
    constructor(props: INavbarProps) {
        super(props);
        this.state = {
            isOpen: false,
            userProfile: null,
        };
    }

    public async componentDidMount() {
        try {
            this.setState({ userProfile: null })
            const userDto = await currentUserapi.GetCachedAuthUser();
            this.setState({ userProfile: userDto });
        } catch (err) {
            this.setState({ userProfile: null });
        }
    }

    public render() {
        return (
            <div>
                <Navbar className="navbar-dark custom-darkNav fixed-top" expand="md">
                    <a className="navbar-brand" href="https://new.mta.info/">
                        <img src="logo.svg" alt="MTA" width="30" height="30" className="mr-2" />
                    </a>
                    <Link className="navbar-brand" to="/home">
                        Outfront Screens Viewer
                    </Link>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="ml-auto" navbar>
                        <NavItem>
                                <Link className={`nav-link ${this.props.activeCheck === "idSubwayScreens" ? 'active' : ''}`} id="idSubwayScreens" to="/subwayscreens" >Subway-New </Link>
                            </NavItem>
                            <NavItem>
                                <Link className={`nav-link ${this.props.activeCheck === "idSubway" ? 'active' : ''}`} id="idSubway" to="/subway" >Subway </Link>
                            </NavItem>
                            <NavItem>
                                <Link className={`nav-link ${this.props.activeCheck === "idLIRR" ? 'active' : ''}`} id="idLIRR" to="/lirr" >LIRR </Link>
                            </NavItem>
                            <NavItem>
                                <Link className={`nav-link ${this.props.activeCheck === "idMNR" ? 'active' : ''}`} id="idMNR" to="/mnr" >MNR </Link>
                            </NavItem>
                            {/* {this.renderReleaseMenu()} */}
                        </Nav>
                        {this.getUserNameNavText()}
                        <button
                            onClick={this.onLogoutClick}
                            type="button"
                            className="btn btn-outline-success ml-2 my-2 my-sm-0 ">
                            Logout
                        </button>
                    </Collapse>
                </Navbar>
            </div>
        );
    }

    private onLogoutClick = () => {
        // setting an invalid value in the user id key to force any other tabs open to logout
        // see PrivateRoute.ts code to see how this is managed
        window.localStorage[constants.APP_CURRENT_USERID_KEY] = -9999;
        window.location.href = getLogoutUrl();
    };

    private toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    private getUserNameNavText = () => {
        if (this.state.userProfile && this.state.userProfile.emailAddress) {
            return (
                <span className="navbar-text">
                    {this.state.userProfile.emailAddress}
                </span>
            );
        } else {
            return (
                <span className="navbar-text">
                </span>
            )
        }
    }

    // private renderReleaseMenu() {
    //     var env = process.env.REACT_APP_ENV;
    //     if (env?.toUpperCase() === "PROD") {
    //         return (
    //             <NavItem>
    //                 <Link className={`nav-link ${this.props.activeCheck === "idReleases" ? 'active' : ''}`} id="idReleases" to="/releases" >Releases </Link>
    //             </NavItem>
    //         );
    //     }
    // }

}
