import React, { Component } from "react";
import styled from "styled-components";
import { IAuthComponentProps } from "../../interfaces/ITemplateViewer";
import { NavbarComponent } from "../navbar/NavbarComponent";
import BlockUi from "react-block-ui";
import { LoadingSpinner } from "../common/LoadingSpinner";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { stationInfoJsonArray } from "../common/subwayStationInfoJson";
import { templateJsonArray } from "../common/templateInfoJson";
import { routeInfoJsonArray } from "../common/routeInfoJsonInfo";
import { ISubwayStationInfo } from "../common/interfaces/ISubwayStationInfo";
import { ITemplateInfo } from "../common/interfaces/ITemplateInfo";
import { getDefaultScreenUrl, getScreenUrl } from "../../AppConfig";
import Select from "react-select";
import { IRouteInfo } from "../common/interfaces/IRouteInfo";

interface ISubwayComponentState {
  loading: boolean;
  showError: any;
  blocking: boolean;
  showScreen: boolean;
  selectedRoute: string;
  selectedStation: ISubwayStationInfo;
  selectedTemplate: ITemplateInfo;
  selectedTrack: string;
  apiError?: any;
  routes: IRouteInfo[];
  stations: ISubwayStationInfo[];
  templates: ITemplateInfo[];
  tracks: string[];
  showTracks: boolean;
  currStationIndex: number;
  currTemplateIndex: number;
}

export class SubwayComponent extends Component<
  IAuthComponentProps,
  ISubwayComponentState
> {
  //set up initial state here
  InitialITemplateInfo: ITemplateInfo = {
    Agency: "",
    Id: "",
    Name: "",
    Path: "",
    Direction: "",
    Screen: "",
    SortOrder: 0,
    Active: true,
    Width: 0,
    Height: 0,
    IncludeTrack: false,
    useOTPStopId: false,
    env: ""
  };

  InitialSubwayStationInfo: ISubwayStationInfo = {
    StationID: "",
    ComplexID: "",
    GTFS_StopID: "",
    Division: "",
    Line: "",
    StopName: "",
    Borough: "",
    DaytimeRoutes: "",
    Structure: "",
    GTFSLatitude: "",
    GTFSLongitude: "",
    NorthTracks: [],
    SouthTracks: [],
  };

  constructor(props: IAuthComponentProps) {
    super(props);
    this.state = {
      loading: false, // true
      showError: false,
      blocking: false,
      showScreen: false,
      selectedRoute: "all",
      selectedStation: this.InitialSubwayStationInfo,
      selectedTemplate: this.InitialITemplateInfo,
      selectedTrack: "",
      apiError: null,
      routes: new Array<IRouteInfo>(),
      stations: new Array<ISubwayStationInfo>(),
      templates: new Array<ITemplateInfo>(),
      tracks: [],
      showTracks: false,
      currStationIndex: -1,
      currTemplateIndex: -1,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    try {
      const routes = routeInfoJsonArray;
      const templates = templateJsonArray
        .filter(({ Agency, Active }) => Agency === "MTASBWY" && Active === true)
        .sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));
      const stations = stationInfoJsonArray.sort((a, b) =>
        a.StopName > b.StopName ? 1 : -1
      );

      this.setState({
        routes,
        stations,
        templates,
        selectedStation: stations[0],
        loading: false,
      });
    } catch (error) {
      this.setState({ showError: true, apiError: error, loading: false });
    }
  };

  render() {
    const { loading, showError } = this.state;
    if (loading) {
      return <LoadingSpinner />;
    }

    if (showError) {
      //return <ErrorFullPageComponent apiError={apiError} location={this.props.location} authUser={this.props.wrapper.authUser} />
    }

    return this.renderSubwayForm();
  }

  renderSubwayForm = () => {
    const isEnabled = this.canBeSubmitted();
    const isPrevBtnEnabled = this.enablePrevButton();
    const isNextBtnEnabled = this.enableNextButton();
    const {
      selectedTemplate,
      selectedStation,
      selectedTrack,
      showScreen,
      showTracks,
    } = this.state;

    return (
      <div className="container">
        <NavbarComponent
          currentPath={this.props.location.pathname}
          activeCheck="idSubway"
        // authUser={this.props.wrapper.authUser}
        />
        <div className="card mt-2 bg-white pt-5">
          <div className="card-body">
            <BlockUi tag="div" blocking={this.state.blocking}>
              <h5 className="card-title ">Subway</h5>
              <hr />
              <form autoComplete="off">
                <fieldset>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-md-10">{this.renderRoutes()}</div>
                      </div>
                      <div className="row">
                        <div className="col-md-10">{this.renderStations()}</div>
                      </div>
                      <div className="row">
                        <div className="col-md-10">
                          {this.renderTemplates()}
                        </div>
                      </div>
                      {showTracks === true &&
                        selectedTemplate.IncludeTrack === true &&
                        (!selectedStation.NorthTracks ||
                          selectedStation.NorthTracks.length > 0) && (
                          <div className="row">
                            <div className="col-md-10">
                              {this.renderTrack()}
                            </div>
                          </div>
                        )}
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div className="form-group">
                            <button
                              type="button"
                              className="btn btn-success btn-sm"
                              disabled={!isEnabled}
                              onClick={() =>
                                this.openInNewTab(
                                  `/#/screen/${selectedStation.GTFS_StopID}/${selectedTemplate.Id}/${selectedTrack}`
                                )
                              }
                            >
                              Show in Full Screen
                            </button>
                            &nbsp;&nbsp;
                            <button
                              type="button"
                              className="btn btn-success btn-sm"
                              disabled={!isPrevBtnEnabled}
                              onClick={this.previousScreen}
                            >
                              Previous
                            </button>
                            &nbsp;&nbsp;
                            <button
                              type="button"
                              className="btn btn-success btn-sm"
                              disabled={!isNextBtnEnabled}
                              onClick={this.nextScreen}
                            >
                              Next
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-12">
                          {showScreen === false && (
                            <VerticalContainer>
                              <VerticalIFrame
                                src={getDefaultScreenUrl()}
                                frameBorder="0"
                                scrolling="no"
                              />
                            </VerticalContainer>
                          )}

                          {showScreen === true &&
                            selectedTemplate.Id !== "" &&
                            selectedTemplate.Screen === "V" && (
                              <VerticalContainer>
                                <VerticalIFrame
                                  src={getScreenUrl(
                                    selectedStation.GTFS_StopID,
                                    selectedTemplate,
                                    selectedTrack
                                  )}
                                  frameBorder="0"
                                  scrolling="no"
                                />
                              </VerticalContainer>
                            )}

                          {showScreen === true &&
                            selectedTemplate.Id !== "" &&
                            selectedTemplate.Screen === "H" && (
                              <HorizontalContainer>
                                <HorizontalIFrame
                                  src={getScreenUrl(
                                    selectedStation.GTFS_StopID,
                                    selectedTemplate,
                                    selectedTrack
                                  )}
                                  frameborder="0"
                                  scrolling="no"
                                />
                              </HorizontalContainer>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </form>
            </BlockUi>
          </div>
        </div>
        <NotificationContainer />
      </div>
    );
  };

  renderRoutes = () => {
    return (
      <div className="form-group required">
        <label className="control-label">
          <b>Route</b>
        </label>
        <Select
          options={this.state.routes}
          placeholder="All Routes"
          onChange={this.handleRouteChange}
          formatOptionLabel={(route) => (
            <div>
              <img
                src={`${process.env.REACT_APP_AppBaseUrl}/assets/images/routes/${route.Id}.svg`}
                alt="route-image"
              />
            </div>
          )}
          getOptionValue={(route) => route.Name} // this will avoid the default blue background color all rows
          isClearable={true}
          isSearchable={true}
        />
      </div>
    );
  };

  renderStations = () => {
    return (
      <div className="form-group required">
        <label className="control-label">
          <b>Station</b>
        </label>
        <Select
          options={this.state.stations}
          placeholder="Select Station"
          onChange={this.handleStationChange}
          formatOptionLabel={(station) => (
            <div>
              {this.state.selectedRoute === "all" &&
                this.renderStationInfo(station)}
              {this.state.selectedRoute !== "all" && (
                <span>{station.StopName}</span>
              )}
            </div>
          )}
          getOptionValue={(station) => station.StopName} // this will avoid the default blue background color all rows
          isClearable={false}
          isSearchable={true}
          value={
            this.state.selectedRoute === "all" &&
              this.state.selectedStation.GTFS_StopID === ""
              ? this.state.stations[0]
              : this.state.selectedStation
          }
        />
      </div>
    );
  };

  renderStationInfo = (station: ISubwayStationInfo) => {
    const routes: String[] = station.DaytimeRoutes.split(" ");
    return (
      <div>
        <span>{station.StopName}</span>
        &nbsp;
        {routes.map((route) => {
          return (
            <img
              src={`${process.env.REACT_APP_AppBaseUrl}/assets/images/routes/${route}.svg`}
              key={`${route}`}
              alt="route-image"
              style={{ padding: 2 }}
            />
          );
        })}
      </div>
    );
  };

  renderTemplates = () => {
    return (
      <div className="form-group required">
        <label className="control-label">
          <b>Template</b>
        </label>
        <select
          className="form-control"
          id="actionSelect"
          onChange={this.handleTemplateChange}
          value={this.state.currTemplateIndex}
        >
          <option key="0" value={-1}>
            Select Template
          </option>
          {this.state.templates.map((template, index) => {
            return (
              <option key={template.Id} value={index}>
                {template.Name}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  renderTrack = () => {
    const { selectedStation, selectedTemplate } = this.state;
    const tracks =
      selectedTemplate.Direction === "N"
        ? selectedStation.NorthTracks || []
        : selectedStation.SouthTracks || [];

    return (
      <div className="form-group required">
        <label className="control-label">
          <b>Track</b>
        </label>
        <select
          className="form-control"
          id="actionSelect"
          onChange={this.handleTrackChange}
          value={this.state.selectedTrack}
        >
          <option key="0" value="">
            All Tracks
          </option>
          {tracks.map((track, index) => {
            return (
              <option key={index} value={track}>
                {track}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  canBeSubmitted = () => {
    const { selectedStation, selectedTemplate } = this.state;

    if (selectedStation.GTFS_StopID === "" || selectedTemplate.Id === "") {
      return false;
    }
    return true;
  };

  enablePrevButton = () => {
    const { currTemplateIndex } = this.state;
    const canBeSubmitted = this.canBeSubmitted();

    if (!canBeSubmitted) {
      return false;
    } else if (currTemplateIndex <= 0) {
      return false;
    }
    return true;
  };

  enableNextButton = () => {
    const { currTemplateIndex, templates } = this.state;
    const canBeSubmitted = this.canBeSubmitted();
    if (!canBeSubmitted) {
      return false;
    } else if (currTemplateIndex === -1) {
      return false;
    } else if (currTemplateIndex === templates.length - 1) {
      return false;
    }
    return true;
  };

  previousScreen = () => {
    const currTemplateIndex =
      this.state.currTemplateIndex - 1 <= 0
        ? 0
        : this.state.currTemplateIndex - 1;
    const selectedTemplate = this.state.templates[currTemplateIndex];
    this.setState({
      showScreen: this.showScreen(
        this.state.selectedRoute,
        this.state.selectedStation,
        selectedTemplate
      ),
      currTemplateIndex,
      selectedTemplate,
    });
  };

  nextScreen = () => {
    const currTemplateIndex =
      this.state.currTemplateIndex + 1 >= this.state.templates.length - 1
        ? this.state.templates.length - 1
        : this.state.currTemplateIndex + 1;
    const selectedTemplate = this.state.templates[currTemplateIndex];

    this.setState({
      showScreen: this.showScreen(
        this.state.selectedRoute,
        this.state.selectedStation,
        selectedTemplate
      ),
      currTemplateIndex,
      selectedTemplate,
    });
  };

  handleRouteChange = (route: any) => {
    const selectedRoute = !route || route.Id === "" ? "all" : route.Name;
    const selectedAgency = selectedRoute === "SIR" ? "SIR" : "MTASBWY";
    const stations = this.filterStationsByRoute(selectedRoute);
    const selectedStation =
      selectedRoute === "all" ? stationInfoJsonArray[0] : stations[0];
    const showTracks =
      !selectedStation.NorthTracks || selectedStation.NorthTracks.length === 0
        ? false
        : true;
    const templates = templateJsonArray
      .filter(({ Agency, Active }) => Agency === selectedAgency && Active === true)
      .sort((a, b) => (a.SortOrder > b.SortOrder ? 1 : -1));

    this.setState({
      selectedRoute,
      stations,
      templates,
      selectedStation,
      showScreen: this.showScreen(
        selectedRoute,
        selectedStation,
        this.state.selectedTemplate
      ),
      showTracks,
      currStationIndex: -1,
    });
  };

  handleStationChange = (station: any) => {
    const selectedStation =
      !station || station.GTFS_StopID === ""
        ? this.InitialSubwayStationInfo
        : station;
    const showTracks =
      !selectedStation.NorthTracks || selectedStation.NorthTracks.length === 0
        ? false
        : true;

    this.setState({
      selectedStation,
      showTracks,
      showScreen: this.showScreen(
        this.state.selectedRoute,
        selectedStation,
        this.state.selectedTemplate
      ),
      // currStationIndex: selectedIndex,
    });
  };

  handleTemplateChange = (e: any) => {
    const selectedStation = this.state.selectedStation;
    const selectedIndex = parseInt(e.target.value);
    const selectedTemplate =
      selectedIndex < 0
        ? this.InitialITemplateInfo
        : this.state.templates[selectedIndex];
    const isStationTrackSupported =
      !selectedStation.NorthTracks || selectedStation.NorthTracks.length === 0
        ? false
        : true;
    const showTracks =
      selectedTemplate.IncludeTrack === true && isStationTrackSupported === true
        ? true
        : false;

    this.setState({
      selectedTemplate,
      showTracks,
      showScreen: this.showScreen(
        this.state.selectedRoute,
        this.state.selectedStation,
        selectedTemplate
      ),
      selectedTrack: "",
      currTemplateIndex: selectedIndex,
    });
  };

  handleTrackChange = (e: any) => {
    const selectedTrack = e.target.value;
    this.setState({
      selectedTrack,
    });
  };

  showScreen = (
    selectedRoute: String,
    selectedStation: ISubwayStationInfo,
    selectedTemplate: ITemplateInfo
  ) => {
    const showScreen =
      selectedRoute === "" ||
        selectedStation.GTFS_StopID === "" ||
        selectedTemplate.Id === ""
        ? false
        : true;

    return showScreen;
  };

  openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

  filterStationsByRoute(routeId: string) {
    let result;
    if (routeId === "all") {
      // result = stationInfoJsonArray.filter(({ DaytimeRoutes }) => !DaytimeRoutes.includes('SIR'));
      result = stationInfoJsonArray;
    } else {
      result = stationInfoJsonArray.filter(({ DaytimeRoutes }) => {
        // when daytime routes contains more that one route separated by space for ex. "4 5 6"
        if (DaytimeRoutes.includes(" ")) {
          return DaytimeRoutes.includes(routeId);
        }

        return DaytimeRoutes === routeId;
      });
    }
    return result;
  }

  // Not used
  // renderRouteIcon(id: string) {
  //   console.log(id);
  //   return (
  //     <Wrapper>
  //       <RouteIcon
  //         id={id}
  //         textColor={"white"}
  //         backgroundColor={"red"}
  //         size="50px"
  //         textSize="2rem"
  //       />
  //     </Wrapper>
  //   );
  // }
}

const VerticalContainer = styled.div`
  width: calc(1080px * 0.5);
  height: calc(1920px * 0.5);
  position: relative;
  margin-left: 50px;
`;

const VerticalIFrame = styled.iframe`
  width: 1080px;
  height: 1920px;
  transform: scale(0.4);
  transform-origin: 0 0;
  position: absolute;
  left: 0;
  top: 0;
`;

const HorizontalContainer = styled.div`
  width: calc(1920px * 0.5);
  height: calc(1080px * 0.5);
  position: relative;
`;

const HorizontalIFrame = styled.iframe`
  width: 1920px;
  height: 1080px;
  transform: scale(0.37);
  transform-origin: 0 0;
  position: absolute;
  left: 0;
  top: 0;
`;

const Wrapper = styled.div`
  margin: 10px auto;
`;
