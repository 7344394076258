import 'react-app-polyfill/ie11';
import React from 'react';
import ReactDOM from 'react-dom';
import "./customBootStrap/customBootStrap.css";
import "./index.css";
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from 'axios';
import { getWellknownUrl, setAppConfig } from './AppSettings';
import { CircleSpinner } from './components/common/CircleSpinner';
import { axiosConfig } from './AxiosConfig';

ReactDOM.render(
    <div className="container">
        <div className="mt-3">
            <div className="text-center">
                <CircleSpinner />
                <div id="loading">Loading...</div>
            </div>
        </div>
    </div>
    ,
    document.getElementById('root'));

axiosConfig.configure();
ReactDOM.render(
    <div>
        <App />
    </div>
    ,
    document.getElementById('root'));

serviceWorker.unregister();
