export default {
  NOTIFICATION_TITLE: 'B&T Shuttle Bus Reservation',
  NOTIFICATION_SUCCESS: 'Saved Successfully!',
  NOTIFICATION_ERROR: 'Sorry, Error occured while creating your reservation. ',
  NOTIFICATION_INFO: 'User must login to proceed',
  NOTIFICATION_WARNING: 'User is not logged in',
  NOTIFICATION_TIMEOUT: 3000,
  NOTIFICATION_SAVE_RESERVATION: 'Reservation created Successfully!',
  NOTIFICATION_CANCEL_RESERVATION: 'Reservation canceled Successfully!',
  NOTIFICATION_DUPLICATE_RESERVATION: 'Reservation for the selected trip is already exits.',
  NOTIFICATION_REMOVE_TRIP: 'Trip removed Successfully!',
  NOTIFICATION_REMOVE_TRIP_SCHEDULE: 'Trip schedule removed Successfully!',
  NOTIFICATION_SAVE_TRIP: 'Trip(s) saved Successfully!',
  NOTIFICATION_SAVE_TRIP_SCHEDULE: 'Schedules saved Successfully!',
  REDIRECT_HOME_PAGE_MESSAGE: "Click here to goto Home page",
  NO_RESERVATION_FOUND: "You have No Reservations.",
  RESERVATION_NOT_FOUND: "Reservations are not found for the selected search criteria",
  CALL_DISPATCH_MESSAGE: "Call Fleet Operations Dispatch at 212-360-3059",
  COLOR: {
    ORANGE: '#C50',
    DARKBLUE: '#0F3274',
    LIGHTBLUE: '#6EA8DA',
    DARKGRAY: '#999',
  },
  USER_ROLES: {
    ADMIN: 'ADMIN'
  },
  ROUTE_PATH: {
    home: '/home',
    login: '/login',
    subway: '/subay',
    lirr: '/lirr',
    mnr: '/mnr',

    // TBR
    reservations: '/reservations',
    reserve: '/reserve',
    search: '/search',
  },
  ERROR_OCCURRED: "Error occured"
};

