import React, { Component } from "react";
import { getScreenUrlByDevice } from "../../AppConfig";
import { IAuthComponentProps } from "../../interfaces/ITemplateViewer";

interface IFullScreenComponentState {
    urlparams: any;
}

export class HeliumFullScreenComponent extends Component<IAuthComponentProps, IFullScreenComponentState> {
    constructor(props: IAuthComponentProps) {
        super(props);
        this.state = {
            urlparams: this.props.match.params,
        }
    }

  render() {
    const { urlparams } = this.state;
    const device = !urlparams.device ? "" : urlparams.device;
    const type = !urlparams.type ? "CIC" : urlparams.type;
    const url = getScreenUrlByDevice(device);
    
    return (
      <div>
        <div>
          <iframe
            src={url}
            frameBorder="0"
            scrolling="no"
            width={(type==="CIC" || type==="STRIP_MAP") ? 1080 : 1920}
            height={(type==="CIC" || type==="STRIP_MAP") ? 1920 : 180}
          />
        </div>
      </div>
    );
  }
}
