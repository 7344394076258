import { IMNRStationInfo } from "../common/interfaces/IMNRStationInfo";

export const stationInfoJsonArray: IMNRStationInfo[] = [
  {
    Branch: "Waterbury",
    Code: "168",
    Name: "Ansonia",
    Latitude: 41.34415,
    Longitude: -73.0799255,
    Type: "S",
    Branch_Id: 6,
    Location_Index: 162,
    Location_Id: 168,
    Tracks: "1,2",
  },
  {
    Branch: "Harlem",
    Code: "99",
    Name: "Appalachian Trail",
    Latitude: 41.592865,
    Longitude: -73.5880356,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 99,
    Location_Id: 99,
    Tracks: "1,2",
  },
  {
    Branch: "Hudson",
    Code: "24",
    Name: "Ardsley-on-Hudson",
    Latitude: 41.02696,
    Longitude: -73.876564,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 24,
    Location_Id: 24,
    Tracks: "1,2",
  },
  {
    Branch: "Hudson",
    Code: "46",
    Name: "Beacon",
    Latitude: 41.505825,
    Longitude: -73.98451,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 46,
    Location_Id: 46,
    Tracks: "1,2",
  },
  {
    Branch: "Waterbury",
    Code: "170",
    Name: "Beacon Falls",
    Latitude: 41.44068,
    Longitude: -73.06307,
    Type: "S",
    Branch_Id: 6,
    Location_Index: 164,
    Location_Id: 170,
    Tracks: "1,2",
  },
  {
    Branch: "Harlem",
    Code: "85",
    Name: "Bedford Hills",
    Latitude: 41.23697,
    Longitude: -73.70037,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 85,
    Location_Id: 85,
    Tracks: "1,2",
  },
  {
    Branch: "Danbury",
    Code: "164",
    Name: "Bethel",
    Latitude: 41.37592,
    Longitude: -73.41787,
    Type: "S",
    Branch_Id: 5,
    Location_Index: 146,
    Location_Id: 164,
    Tracks: "1,2",
  },
  {
    Branch: "Harlem",
    Code: "57",
    Name: "Botanical Garden",
    Latitude: 40.86726,
    Longitude: -73.88169,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 57,
    Location_Id: 57,
    Tracks: "1,2",
  },
  {
    Branch: "Danbury",
    Code: "162",
    Name: "Branchville",
    Latitude: 41.2663765,
    Longitude: -73.44067,
    Type: "S",
    Branch_Id: 5,
    Location_Index: 144,
    Location_Id: 162,
    Tracks: "1,2",
  },
  {
    Branch: "Hudson",
    Code: "44",
    Name: "Breakneck Ridge",
    Latitude: 41.45018,
    Longitude: -73.98246,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 44,
    Location_Id: 44,
    Tracks: "1,2",
  },
  {
    Branch: "Harlem",
    Code: "91",
    Name: "Brewster",
    Latitude: 41.394722,
    Longitude: -73.61988,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 91,
    Location_Id: 91,
    Tracks: "1,2",
  },
  {
    Branch: "New Haven",
    Code: "140",
    Name: "Bridgeport",
    Latitude: 41.17789,
    Longitude: -73.18686,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 155,
    Location_Id: 140,
    Tracks: "1,2",
  },
  {
    Branch: "Harlem",
    Code: "65",
    Name: "Bronxville",
    Latitude: 40.94134,
    Longitude: -73.8350754,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 65,
    Location_Id: 65,
    Tracks: "1,2",
  },
  {
    Branch: "Danbury",
    Code: "161",
    Name: "Cannondale",
    Latitude: 41.2171555,
    Longitude: -73.42636,
    Type: "S",
    Branch_Id: 5,
    Location_Index: 143,
    Location_Id: 161,
    Tracks: "1,2",
  },
  {
    Branch: "Harlem",
    Code: "83",
    Name: "Chappaqua",
    Latitude: 41.1579857,
    Longitude: -73.77491,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 83,
    Location_Id: 83,
    Tracks: "1,2",
  },
  {
    Branch: "Hudson",
    Code: "43",
    Name: "Cold Spring",
    Latitude: 41.4152069,
    Longitude: -73.95789,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 43,
    Location_Id: 43,
    Tracks: "1,2",
  },
  {
    Branch: "Hudson",
    Code: "37",
    Name: "Cortlandt",
    Latitude: 41.24661,
    Longitude: -73.92271,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 37,
    Location_Id: 37,
    Tracks: "1,2",
  },
  {
    Branch: "New Haven",
    Code: "118",
    Name: "Cos Cob",
    Latitude: 41.029808,
    Longitude: -73.60008,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 118,
    Location_Id: 118,
    Tracks: "1,2",
  },
  {
    Branch: "Harlem",
    Code: "68",
    Name: "Crestwood",
    Latitude: 40.9590759,
    Longitude: -73.82106,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 68,
    Location_Id: 68,
    Tracks: "1,2",
  },
  {
    Branch: "Harlem",
    Code: "90",
    Name: "Croton Falls",
    Latitude: 41.3479347,
    Longitude: -73.66213,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 90,
    Location_Id: 90,
    Tracks: "1,2",
  },
  {
    Branch: "Hudson",
    Code: "33",
    Name: "Croton-Harmon",
    Latitude: 41.1888771,
    Longitude: -73.88173,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 33,
    Location_Id: 33,
    Tracks: "1,2",
  },
  {
    Branch: "Danbury",
    Code: "165",
    Name: "Danbury",
    Latitude: 41.39608,
    Longitude: -73.4487839,
    Type: "S",
    Branch_Id: 5,
    Location_Index: 147,
    Location_Id: 165,
    Tracks: "1,2",
  },
  {
    Branch: "New Haven",
    Code: "128",
    Name: "Darien",
    Latitude: 41.077095,
    Longitude: -73.47165,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 135,
    Location_Id: 128,
    Tracks: "1,2",
  },
  {
    Branch: "Waterbury",
    Code: "167",
    Name: "Derby",
    Latitude: 41.320343,
    Longitude: -73.08356,
    Type: "S",
    Branch_Id: 6,
    Location_Index: 161,
    Location_Id: 167,
    Tracks: "1,2",
  },
  {
    Branch: "Hudson",
    Code: "23",
    Name: "Dobbs Ferry",
    Latitude: 41.0122948,
    Longitude: -73.87963,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 23,
    Location_Id: 23,
    Tracks: "1,2",
  },
  {
    Branch: "Harlem",
    Code: "101",
    Name: "Dover Plains",
    Latitude: 41.74271,
    Longitude: -73.57618,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 101,
    Location_Id: 101,
    Tracks: "1,2",
  },
  {
    Branch: "New Haven",
    Code: "133",
    Name: "East Norwalk",
    Latitude: 41.1041946,
    Longitude: -73.4042,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 148,
    Location_Id: 133,
    Tracks: "1,2",
  },
  {
    Branch: "New Haven",
    Code: "138",
    Name: "Fairfield",
    Latitude: 41.14283,
    Longitude: -73.25842,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 153,
    Location_Id: 138,
    Tracks: "1,2",
  },
  {
    Branch: "New Haven",
    Code: "188",
    Name: "Fairfield Metro",
    Latitude: 41.16024,
    Longitude: -73.2351761,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 154,
    Location_Id: 188,
    Tracks: "1,2",
  },
  {
    Branch: "Harlem",
    Code: "64",
    Name: "Fleetwood",
    Latitude: 40.9269676,
    Longitude: -73.83984,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 64,
    Location_Id: 64,
    Tracks: "1,2",
  },
  {
    Branch: "Harlem",
    Code: "56",
    Name: "Fordham",
    Latitude: 40.86147,
    Longitude: -73.8903961,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 56,
    Location_Id: 56,
    Tracks: "1,2",
  },
  {
    Branch: "Hudson",
    Code: "42",
    Name: "Garrison",
    Latitude: 41.3809967,
    Longitude: -73.9472961,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 42,
    Location_Id: 42,
    Tracks: "1,2",
  },
  {
    Branch: "New Canaan",
    Code: "153",
    Name: "Glenbrook",
    Latitude: 41.07104,
    Longitude: -73.52004,
    Type: "S",
    Branch_Id: 4,
    Location_Index: 126,
    Location_Id: 153,
    Tracks: "1,2",
  },
  {
    Branch: "Hudson",
    Code: "19",
    Name: "Glenwood",
    Latitude: 40.950634,
    Longitude: -73.89879,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 19,
    Location_Id: 19,
    Tracks: "1,2",
  },
  {
    Branch: "Harlem",
    Code: "88",
    Name: "Goldens Bridge",
    Latitude: 41.2944946,
    Longitude: -73.67758,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 88,
    Location_Id: 88,
    Tracks: "1,2",
  },
  {
    Branch: "Hudson",
    Code: "1",
    Name: "Grand Central",
    Latitude: 40.7529526,
    Longitude: -73.977066,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 1,
    Location_Id: 1,
    Tracks: "11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,102,102A,103,104,105,106,107,108,109,110,111,112,113,114,115,116,117",
  },
  {
    Branch: "New Haven",
    Code: "136",
    Name: "Green's Farms",
    Latitude: 41.12242,
    Longitude: -73.31504,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 151,
    Location_Id: 136,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "New Haven",
    Code: "116",
    Name: "Greenwich",
    Latitude: 41.02111,
    Longitude: -73.62557,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 116,
    Location_Id: 116,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "Hudson",
    Code: "20",
    Name: "Greystone",
    Latitude: 40.972744,
    Longitude: -73.88915,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 20,
    Location_Id: 20,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "Harlem",
    Code: "100",
    Name: "Harlem Valley-Wingdale",
    Latitude: 41.6374054,
    Longitude: -73.57165,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 100,
    Location_Id: 100,
    Tracks: "1",
  },
  {
    Branch: "Hudson",
    Code: "4",
    Name: "Harlem-125th St.",
    Latitude: 40.80515,
    Longitude: -73.93887,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 4,
    Location_Id: 4,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "New Haven",
    Code: "112",
    Name: "Harrison",
    Latitude: 40.9698944,
    Longitude: -73.7113342,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 112,
    Location_Id: 112,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "Harlem",
    Code: "72",
    Name: "Hartsdale",
    Latitude: 41.0111122,
    Longitude: -73.79569,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 72,
    Location_Id: 72,
    Tracks: "1,2",
  },
  {
    Branch: "Hudson",
    Code: "22",
    Name: "Hastings-on-Hudson",
    Latitude: 40.99475,
    Longitude: -73.88427,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 22,
    Location_Id: 22,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "Harlem",
    Code: "80",
    Name: "Hawthorne",
    Latitude: 41.1090546,
    Longitude: -73.7960052,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 80,
    Location_Id: 80,
    Tracks: "1,2",
  },
  {
    Branch: "Hudson",
    Code: "25",
    Name: "Irvington",
    Latitude: 41.0403671,
    Longitude: -73.87286,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 25,
    Location_Id: 25,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "Harlem",
    Code: "86",
    Name: "Katonah",
    Latitude: 41.2598228,
    Longitude: -73.68403,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 86,
    Location_Id: 86,
    Tracks: "1,2",
  },
  {
    Branch: "New Haven",
    Code: "110",
    Name: "Larchmont",
    Latitude: 40.9347534,
    Longitude: -73.75798,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 110,
    Location_Id: 110,
    Tracks: "3,4",
  },
  {
    Branch: "Hudson",
    Code: "17",
    Name: "Ludlow",
    Latitude: 40.9224129,
    Longitude: -73.9060059,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 17,
    Location_Id: 17,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "New Haven",
    Code: "111",
    Name: "Mamaroneck",
    Latitude: 40.9539452,
    Longitude: -73.73636,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 111,
    Location_Id: 111,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "Hudson",
    Code: "40",
    Name: "Manitou",
    Latitude: 41.3325653,
    Longitude: -73.97034,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 40,
    Location_Id: 40,
    Tracks: "1,2",
  },
  {
    Branch: "Hudson",
    Code: "11",
    Name: "Marble Hill",
    Latitude: 40.8746033,
    Longitude: -73.91165,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 11,
    Location_Id: 11,
    Tracks: "1,2,4",
  },
  {
    Branch: "Harlem",
    Code: "54",
    Name: "Melrose",
    Latitude: 40.8258743,
    Longitude: -73.91477,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 54,
    Location_Id: 54,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "Danbury",
    Code: "158",
    Name: "Merritt 7",
    Latitude: 41.14662,
    Longitude: -73.42779,
    Type: "S",
    Branch_Id: 5,
    Location_Index: 140,
    Location_Id: 158,
    Tracks: "1",
  },
  {
    Branch: "New Haven",
    Code: "145",
    Name: "Milford",
    Latitude: 41.2226334,
    Longitude: -73.05952,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 167,
    Location_Id: 145,
    Tracks: "1,2,4",
  },
  {
    Branch: "Hudson",
    Code: "9",
    Name: "Morris Heights",
    Latitude: 40.8539124,
    Longitude: -73.9200058,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 9,
    Location_Id: 9,
    Tracks: "1,2,4",
  },
  {
    Branch: "Harlem",
    Code: "84",
    Name: "Mount Kisco",
    Latitude: 41.2084579,
    Longitude: -73.72964,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 84,
    Location_Id: 84,
    Tracks: "1,2",
  },
  {
    Branch: "Harlem",
    Code: "79",
    Name: "Mount Pleasant",
    Latitude: 41.094883,
    Longitude: -73.79361,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 79,
    Location_Id: 79,
    Tracks: "1,2",
  },
  {
    Branch: "New Haven",
    Code: "105",
    Name: "Mt Vernon East",
    Latitude: 40.9121971,
    Longitude: -73.83195,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 105,
    Location_Id: 105,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "Harlem",
    Code: "62",
    Name: "Mt Vernon West",
    Latitude: 40.9129944,
    Longitude: -73.85008,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 62,
    Location_Id: 62,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "New Haven",
    Code: "151",
    Name: "NH-State St.",
    Latitude: 41.3050346,
    Longitude: -72.92161,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 173,
    Location_Id: 151,
    Tracks: "4,6",
  },
  {
    Branch: "Waterbury",
    Code: "171",
    Name: "Naugatuck",
    Latitude: 41.4928,
    Longitude: -73.0521851,
    Type: "S",
    Branch_Id: 6,
    Location_Index: 165,
    Location_Id: 171,
    Tracks: "1",
  },
  {
    Branch: "New Canaan",
    Code: "157",
    Name: "New Canaan",
    Latitude: 41.1458473,
    Longitude: -73.4963455,
    Type: "S",
    Branch_Id: 4,
    Location_Index: 130,
    Location_Id: 157,
    Tracks: "1",
  },
  {
    Branch: "Hudson",
    Code: "49",
    Name: "New Hamburg",
    Latitude: 41.5868225,
    Longitude: -73.94752,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 48,
    Location_Id: 49,
    Tracks: "1,2",
  },
  {
    Branch: "New Haven",
    Code: "149",
    Name: "New Haven",
    Latitude: 41.2974854,
    Longitude: -72.92644,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 171,
    Location_Id: 149,
    Tracks: "1,2,3,4,8,10,12,14",
  },
  {
    Branch: "New Haven",
    Code: "108",
    Name: "New Rochelle",
    Latitude: 40.91138,
    Longitude: -73.78409,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 108,
    Location_Id: 108,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "New Haven",
    Code: "127",
    Name: "Noroton Heights",
    Latitude: 41.06904,
    Longitude: -73.49701,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 134,
    Location_Id: 127,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "Harlem",
    Code: "76",
    Name: "North White Plains",
    Latitude: 41.05123,
    Longitude: -73.77236,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 76,
    Location_Id: 76,
    Tracks: "1,2,3",
  },
  {
    Branch: "New Haven",
    Code: "121",
    Name: "Old Greenwich",
    Latitude: 41.0333023,
    Longitude: -73.5679,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 121,
    Location_Id: 121,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "Hudson",
    Code: "31",
    Name: "Ossining",
    Latitude: 41.1575127,
    Longitude: -73.86905,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 31,
    Location_Id: 31,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "Harlem",
    Code: "97",
    Name: "Patterson",
    Latitude: 41.5117531,
    Longitude: -73.6042938,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 97,
    Location_Id: 97,
    Tracks: "1",
  },
  {
    Branch: "Harlem",
    Code: "98",
    Name: "Pawling",
    Latitude: 41.5647125,
    Longitude: -73.60032,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 98,
    Location_Id: 98,
    Tracks: "1",
  },
  {
    Branch: "Hudson",
    Code: "39",
    Name: "Peekskill",
    Latitude: 41.28509,
    Longitude: -73.9309158,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 39,
    Location_Id: 39,
    Tracks: "1,2",
  },
  {
    Branch: "New Haven",
    Code: "106",
    Name: "Pelham",
    Latitude: 40.9100952,
    Longitude: -73.80886,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 106,
    Location_Id: 106,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "Hudson",
    Code: "29",
    Name: "Philipse Manor",
    Latitude: 41.0946045,
    Longitude: -73.86955,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 29,
    Location_Id: 29,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "Harlem",
    Code: "81",
    Name: "Pleasantville",
    Latitude: 41.13483,
    Longitude: -73.79231,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 81,
    Location_Id: 81,
    Tracks: "1,2",
  },
  {
    Branch: "New Haven",
    Code: "115",
    Name: "Port Chester",
    Latitude: 41.0020523,
    Longitude: -73.6636353,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 115,
    Location_Id: 115,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "Hudson",
    Code: "51",
    Name: "Poughkeepsie",
    Latitude: 41.707058,
    Longitude: -73.93777,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 51,
    Location_Id: 51,
    Tracks: "1,2,3",
  },
  {
    Branch: "Harlem",
    Code: "89",
    Name: "Purdy's",
    Latitude: 41.3253479,
    Longitude: -73.65887,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 89,
    Location_Id: 89,
    Tracks: "1,2",
  },
  {
    Branch: "Danbury",
    Code: "163",
    Name: "Redding",
    Latitude: 41.3247871,
    Longitude: -73.4351,
    Type: "S",
    Branch_Id: 5,
    Location_Index: 145,
    Location_Id: 163,
    Tracks: "1",
  },
  {
    Branch: "Hudson",
    Code: "16",
    Name: "Riverdale",
    Latitude: 40.9044,
    Longitude: -73.91364,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 16,
    Location_Id: 16,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "New Haven",
    Code: "120",
    Name: "Riverside",
    Latitude: 41.0317,
    Longitude: -73.58719,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 120,
    Location_Id: 120,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "New Haven",
    Code: "129",
    Name: "Rowayton",
    Latitude: 41.07769,
    Longitude: -73.44427,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 136,
    Location_Id: 129,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "New Haven",
    Code: "114",
    Name: "Rye",
    Latitude: 40.9860268,
    Longitude: -73.68296,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 114,
    Location_Id: 114,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "Hudson",
    Code: "30",
    Name: "Scarborough",
    Latitude: 41.138607,
    Longitude: -73.8665161,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 30,
    Location_Id: 30,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "Harlem",
    Code: "71",
    Name: "Scarsdale",
    Latitude: 40.9896431,
    Longitude: -73.8085556,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 71,
    Location_Id: 71,
    Tracks: "1,2",
  },
  {
    Branch: "Waterbury",
    Code: "169",
    Name: "Seymour",
    Latitude: 41.394516,
    Longitude: -73.0728455,
    Type: "S",
    Branch_Id: 6,
    Location_Index: 163,
    Location_Id: 169,
    Tracks: "1",
  },
  {
    Branch: "New Haven",
    Code: "131",
    Name: "South Norwalk",
    Latitude: 41.09571,
    Longitude: -73.42173,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 138,
    Location_Id: 131,
    Tracks: "1,2,3,4,5,6",
  },
  {
    Branch: "Harlem",
    Code: "94",
    Name: "Southeast",
    Latitude: 41.412693,
    Longitude: -73.6229248,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 94,
    Location_Id: 94,
    Tracks: "1,2",
  },
  {
    Branch: "New Haven",
    Code: "137",
    Name: "Southport",
    Latitude: 41.1351967,
    Longitude: -73.2883453,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 152,
    Location_Id: 137,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "New Canaan",
    Code: "154",
    Name: "Springdale",
    Latitude: 41.089,
    Longitude: -73.51775,
    Type: "S",
    Branch_Id: 4,
    Location_Index: 127,
    Location_Id: 154,
    Tracks: "1",
  },
  {
    Branch: "Hudson",
    Code: "14",
    Name: "Spuyten Duyvil",
    Latitude: 40.8790131,
    Longitude: -73.922226,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 14,
    Location_Id: 14,
    Tracks: "1,2,4",
  },
  {
    Branch: "New Haven",
    Code: "124",
    Name: "Stamford",
    Latitude: 41.04673,
    Longitude: -73.5420456,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 124,
    Location_Id: 124,
    Tracks: "2,3,4,5",
  },
  {
    Branch: "New Haven",
    Code: "143",
    Name: "Stratford",
    Latitude: 41.1948166,
    Longitude: -73.13085,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 158,
    Location_Id: 143,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "New Canaan",
    Code: "155",
    Name: "Talmadge Hill",
    Latitude: 41.1162643,
    Longitude: -73.498,
    Type: "S",
    Branch_Id: 4,
    Location_Index: 128,
    Location_Id: 155,
    Tracks: "1",
  },
  {
    Branch: "Hudson",
    Code: "27",
    Name: "Tarrytown",
    Latitude: 41.076683,
    Longitude: -73.86472,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 27,
    Location_Id: 27,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "Harlem",
    Code: "176",
    Name: "Tenmile River",
    Latitude: 41.77946,
    Longitude: -73.5590439,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 102,
    Location_Id: 176,
    Tracks: "1",
  },
  {
    Branch: "Harlem",
    Code: "55",
    Name: "Tremont",
    Latitude: 40.846962,
    Longitude: -73.8996048,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 55,
    Location_Id: 55,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "Harlem",
    Code: "66",
    Name: "Tuckahoe",
    Latitude: 40.9505,
    Longitude: -73.8284,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 66,
    Location_Id: 66,
    Tracks: "1,2,4",
  },
  {
    Branch: "Hudson",
    Code: "10",
    Name: "University Heights",
    Latitude: 40.8618965,
    Longitude: -73.9134445,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 10,
    Location_Id: 10,
    Tracks: "1,2,4",
  },
  {
    Branch: "Harlem",
    Code: "78",
    Name: "Valhalla",
    Latitude: 41.07386,
    Longitude: -73.77434,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 78,
    Location_Id: 78,
    Tracks: "1,2",
  },
  {
    Branch: "Harlem",
    Code: "61",
    Name: "Wakefield",
    Latitude: 40.90633,
    Longitude: -73.85534,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 61,
    Location_Id: 61,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "Harlem",
    Code: "177",
    Name: "Wassaic",
    Latitude: 41.81479,
    Longitude: -73.5623245,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 103,
    Location_Id: 177,
    Tracks: "1",
  },
  {
    Branch: "Waterbury",
    Code: "172",
    Name: "Waterbury",
    Latitude: 41.55445,
    Longitude: -73.04704,
    Type: "S",
    Branch_Id: 6,
    Location_Index: 166,
    Location_Id: 172,
    Tracks: "1",
  },
  {
    Branch: "New Haven",
    Code: "190",
    Name: "West Haven",
    Latitude: 41.27112,
    Longitude: -72.9631653,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 168,
    Location_Id: 190,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "New Haven",
    Code: "134",
    Name: "Westport",
    Latitude: 41.11882,
    Longitude: -73.3719254,
    Type: "S",
    Branch_Id: 3,
    Location_Index: 149,
    Location_Id: 134,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "Harlem",
    Code: "74",
    Name: "White Plains",
    Latitude: 41.0333672,
    Longitude: -73.775,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 74,
    Location_Id: 74,
    Tracks: "1,2",
  },
  {
    Branch: "Harlem",
    Code: "58",
    Name: "Williams Bridge",
    Latitude: 40.87813,
    Longitude: -73.87122,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 58,
    Location_Id: 58,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "Danbury",
    Code: "160",
    Name: "Wilton",
    Latitude: 41.19568,
    Longitude: -73.4318542,
    Type: "S",
    Branch_Id: 5,
    Location_Index: 142,
    Location_Id: 160,
    Tracks: "1",
  },
  {
    Branch: "Harlem",
    Code: "59",
    Name: "Woodlawn",
    Latitude: 40.8953,
    Longitude: -73.8626251,
    Type: "S",
    Branch_Id: 2,
    Location_Index: 59,
    Location_Id: 59,
    Tracks: "1,2,3,4",
  },
  {
    Branch: "Hudson",
    Code: "622",
    Name: "Yankees-E153 St.",
    Latitude: 40.82573,
    Longitude: -73.93055,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 8,
    Location_Id: 622,
    Tracks: "1,2,4,6",
  },
  {
    Branch: "Hudson",
    Code: "18",
    Name: "Yonkers",
    Latitude: 40.93566,
    Longitude: -73.9023361,
    Type: "S",
    Branch_Id: 1,
    Location_Index: 18,
    Location_Id: 18,
    Tracks: "1,2,3,4",
  },
];
