import React, { Component } from "react";
import packageJson from "../../../package.json";
import { getLoginError, getLoginUrl } from "../../AppSettings";
import * as CurrentUserApi from "../../apis/CurrentUserApi";
import { Redirect, RouteComponentProps } from "react-router-dom";
import { BrowserDetectionError } from "../common/BrowserDetectionError";
import BrowserDetection from 'react-browser-detection';
import { CircleSpinner } from "../common/CircleSpinner";
import * as constants from "../../constants";
import { ApiCallStatusEnum } from "../common/enums/ApiCallStatusEnum";

interface ILoginComponentState {
    apiCallStatus: ApiCallStatusEnum;
}

export class LoginComponent extends Component<RouteComponentProps, ILoginComponentState> {
    constructor(props: any) {
        super(props);
        this.state = {
            apiCallStatus: ApiCallStatusEnum.NotStarted
        }
    }

    public async componentDidMount() {
        this.setState({ apiCallStatus: ApiCallStatusEnum.Started });
        const authUser = await CurrentUserApi.GetAuthUserOrNull();
        const apiCallStatus = authUser !== null ? ApiCallStatusEnum.Success : ApiCallStatusEnum.Failed;
        this.setState({ apiCallStatus });
    }

    public render() {
        if (this.state.apiCallStatus === ApiCallStatusEnum.NotStarted || this.state.apiCallStatus === ApiCallStatusEnum.Started) {
            return (
                <CircleSpinner />
            )
        }

        if (this.state.apiCallStatus === ApiCallStatusEnum.Success) {
            return (
                <Redirect to={constants.ROUTE_PATH.home} />
            );
        }

        const bannerMessage = (process.env.REACT_APP_BannerMessage || ' ').trim();
        const loginUrl = getLoginUrl();
        const versionStyle = {
            display: 'none'
        };

        return (
            <div>
                <div className="container">
                    <div className="mt-2">
                        <div className="custom-pageHeader">
                            <h1 className="display-4 custom-font-header-bold">Outfront Screen Viewer</h1>
                        </div>
                        <BrowserDetection>
                            {BrowserDetectionError}
                        </BrowserDetection>
                        {bannerMessage &&
                            <div className="alert alert-warning mt-2" role="alert">
                                {bannerMessage}
                            </div>}
                        <div>
                            {this.renderLoginError()}
                        </div>
                        <p className="mt-4">
                            Please login by clicking on the button below. This will take you to the MTA's single sign on page where you will be prompted for your email and password.
                        </p>
                        <div className="row">
                            <div className="col-lg-4">
                                <a className="btn btn-primary btn-lg active mr-1"
                                    href={loginUrl}
                                >Log In</a>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={versionStyle}>
                    Version {packageJson.version}
                </div>
            </div>
        )
    }

    private renderLoginError() {
        const loginError = getLoginError();
        if (loginError) {
            return (
                <div className="alert alert-danger">{loginError}</div>
            )
        }
        return (<div></div>);
    }
}