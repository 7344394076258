import { ITemplateInfo } from "./interfaces/ITemplateInfo";

export const templateJsonArray: ITemplateInfo[] = [
    {
        Agency: "MTASBWY",
        Id: "NYCT1013",
        Name: "Strip Map V3 - North",
        Path: "NYCTStripMapV3/index.html",
        Direction: "N",
        Screen: "V",
        SortOrder: 1,
        Active: false,
        Width: 1080,
        Height: 1920,
        IncludeTrack: true,
        useOTPStopId: false,
        env: "",
    }, 
    {
        Agency: "MTASBWY",
        Id: "NYCT1023",
        Name: "Strip Map V3 - South",
        Path: "NYCTStripMapV3/index.html",
        Direction: "S",
        Screen: "V",
        SortOrder: 2,
        Active: false,
        Width: 1080,
        Height: 1920,
        IncludeTrack: true,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MTASBWY",
        Id: "NYCT1033",
        Name: "Strip Map V3 - Both",
        Path: "NYCTStripMapV3/index.html",
        Direction: "",
        Screen: "V",
        SortOrder: 3,
        Active: false,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MTASBWY",
        Id: "NYCT1014",
        Name: "Strip Map V4 - North",
        Path: "NYCTStripMapV4/index.html",
        Direction: "N",
        Screen: "V",
        SortOrder: 4,
        Active: true,
        Width: 1080,
        Height: 1920,
        IncludeTrack: true,
        useOTPStopId: false,
        env: "",
    }, 
    {
        Agency: "MTASBWY",
        Id: "NYCT1024",
        Name: "Strip Map V4 - South",
        Path: "NYCTStripMapV4/index.html",
        Direction: "S",
        Screen: "V",
        SortOrder: 5,
        Active: true,
        Width: 1080,
        Height: 1920,
        IncludeTrack: true,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MTASBWY",
        Id: "NYCT1034",
        Name: "Strip Map V4 - Both",
        Path: "NYCTStripMapV4/index.html",
        Direction: "",
        Screen: "V",
        SortOrder: 6,
        Active: true,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MTASBWY",
        Id: "NYCT1015",
        Name: "Strip Map Alerts V4 - North",
        Path: "NYCTStripMapAlertsV4/index.html",
        Direction: "N",
        Screen: "V",
        SortOrder: 4,
        Active: true,
        Width: 1080,
        Height: 1920,
        IncludeTrack: true,
        useOTPStopId: false,
        env: "",
    }, 
    {
        Agency: "MTASBWY",
        Id: "NYCT1025",
        Name: "Strip Map Alerts V4 - South",
        Path: "NYCTStripMapAlertsV4/index.html",
        Direction: "S",
        Screen: "V",
        SortOrder: 5,
        Active: true,
        Width: 1080,
        Height: 1920,
        IncludeTrack: true,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MTASBWY",
        Id: "NYCT1035",
        Name: "Strip Map Alerts V4 - Both",
        Path: "NYCTStripMapAlertsV4/index.html",
        Direction: "",
        Screen: "V",
        SortOrder: 6,
        Active: true,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MTASBWY",
        Id: "NYCT103",
        Name: "Station Dashboard V2",
        Path: "NYCTStationDashboardV2/index.html",
        Direction: "",
        Screen: "V",
        SortOrder: 4,
        Active: false,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MTASBWY",
        Id: "NYCT104",
        Name: "Station Dashboard V3",
        Path: "NYCTStationDashboardV3/index.html",
        Direction: "",
        Screen: "V",
        SortOrder: 7,
        Active: true,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MTASBWY",
        Id: "NYCT114",
        Name: "Station Dashboard V4",
        Path: "NYCTStationDashboardV4/index.html",
        Direction: "",
        Screen: "V",
        SortOrder: 8,
        Active: false,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MTASBWY",
        Id: "NYCT105",
        Name: "POI",
        Path: "NYCTPOI/index.html",
        Direction: "",
        Screen: "V",
        SortOrder: 9,
        Active: true,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MTASBWY",
        Id: "NYCT106V",
        Name: "Departure Board - Vertical - V1",
        Path: "NYCTDepartureBoardV1/index.html",
        Direction: "NS",
        Screen: "V",
        SortOrder: 10,
        Active: true,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MTASBWY",
        Id: "NYCT106S",
        Name: "Subway South Departures - Horizontal",
        Path: "NYCTSubwayDepartures/index.html",
        Direction: "S",
        Screen: "H",
        SortOrder: 11,
        Active: true,
        Width: 1920,
        Height: 1080,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MTASBWY",
        Id: "NYCT107",
        Name: "DUP",
        Path: "NYCTDUP/index.html",
        Direction: "",
        Screen: "H",
        SortOrder: 12,
        Active: false,
        Width: 1920,
        Height: 1080,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MTASBWY",
        Id: "NYCT108",
        Name: "DUP - North",
        Path: "NYCTDUP/index.html",
        Direction: "N",
        Screen: "H",
        SortOrder: 13,
        Active: true,
        Width: 1920,
        Height: 1080,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MTASBWY",
        Id: "NYCT109",
        Name: "DUP - South",
        Path: "NYCTDUP/index.html",
        Direction: "S",
        Screen: "H",
        SortOrder: 14,
        Active: true,
        Width: 1920,
        Height: 1080,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MTASBWY",
        Id: "NYCT110",
        Name: "DUP - North South",
        Path: "NYCTDUP/index.html",
        Direction: "NS",
        Screen: "H",
        SortOrder: 15,
        Active: true,
        Width: 1920,
        Height: 1080,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },    
    {
        Agency: "SIR",
        Id: "SIR101",
        Name: "SIR South Departures - Vertical",
        Path: "NYCTSIRDepartureBoardVerticalV1/index.html",
        Direction: "S",
        Screen: "V",
        SortOrder: 1,
        Active: true,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MNR",
        Id: "MNR101",
        Name: "Departure Board Both - Vertical",
        Path: "MNRDepartureBoardVertical/index.html",
        Direction: "IO",
        Screen: "V",
        SortOrder: 1,
        Active: false,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MNR",
        Id: "MNR1011",
        Name: "Departure Board Both - Vertical - V3",
        Path: "MNRDepartureBoardVerticalV3/index.html",
        Direction: "IO",
        Screen: "V",
        SortOrder: 2,
        Active: true,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MNR",
        Id: "MNR102",
        Name: "Departure Board Inbound - Vertical",
        Path: "MNRDepartureBoardVertical/index.html",
        Direction: "I",
        Screen: "V",
        SortOrder: 3,
        Active: false,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MNR",
        Id: "MNR1021",
        Name: "Departure Board Inbound - Vertical - V3",
        Path: "MNRDepartureBoardVerticalV3/index.html",
        Direction: "I",
        Screen: "V",
        SortOrder: 4,
        Active: true,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MNR",
        Id: "MNR103",
        Name: "Departure Board Outbound - Vertical",
        Path: "MNRDepartureBoardVertical/index.html",
        Direction: "O",
        Screen: "V",
        SortOrder: 5,
        Active: false,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MNR",
        Id: "MNR1031",
        Name: "Departure Board Outbound - Vertical - V3",
        Path: "MNRDepartureBoardVerticalV3/index.html",
        Direction: "O",
        Screen: "V",
        SortOrder: 6,
        Active: true,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MNR",
        Id: "MNR104",
        Name: "Strip Map - Both",
        Path: "MNRStripMap/index.html",
        Direction: "IO",
        Screen: "V",
        SortOrder: 7,
        Active: false,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MNR",
        Id: "MNR1041",
        Name: "Strip Map - Both - V3",
        Path: "MNRStripMapV3/index.html",
        Direction: "IO",
        Screen: "V",
        SortOrder: 8,
        Active: true,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MNR",
        Id: "MNR105",
        Name: "Strip Map - Outbound",
        Path: "MNRStripMap/index.html",
        Direction: "O",
        Screen: "V",
        SortOrder: 9,
        Active: false,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MNR",
        Id: "MNR1051",
        Name: "Strip Map - Outbound - V3",
        Path: "MNRStripMapV3/index.html",
        Direction: "O",
        Screen: "V",
        SortOrder: 10,
        Active: true,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MNR",
        Id: "MNR106",
        Name: "Strip Map - Inbound",
        Path: "MNRStripMap/index.html",
        Direction: "I",
        Screen: "V",
        SortOrder: 11,
        Active: false,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MNR",
        Id: "MNR1061",
        Name: "Strip Map - Inbound - V3",
        Path: "MNRStripMapV3/index.html",
        Direction: "I",
        Screen: "V",
        SortOrder: 12,
        Active: true,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MNR",
        Id: "MNR107",
        Name: "Station Dashboard",
        Path: "MNRStationDashboard/index.html",
        Direction: "",
        Screen: "V",
        SortOrder: 13,
        Active: true,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MNR",
        Id: "MNR108",
        Name: "B46X",
        Path: "MNRB46X/index.html",
        Direction: "",
        Screen: "X",
        SortOrder: 14,
        Active: true,
        Width: 1920,
        Height: 480,
        IncludeTrack: true,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "MNR",
        Id: "MNR109",
        Name: "B46U",
        Path: "MNRB46U/index.html",
        Direction: "",
        Screen: "U",
        SortOrder: 14,
        Active: true,
        Width: 1920,
        Height: 480,
        IncludeTrack: true,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "LIRR",
        Id: "LIRR101",
        Name: "Departure Board Outbound - Horizontal",
        Path: "LIRRDepartureBoardHorizontal/index.html",
        Direction: "E",
        Screen: "H",
        SortOrder: 1,
        Active: true,
        Width: 1920,
        Height: 1080,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "LIRR",
        Id: "LIRR102",
        Name: "Departure Board Inbound - Horizontal",
        Path: "LIRRDepartureBoardHorizontal/index.html",
        Direction: "W",
        Screen: "H",
        SortOrder: 2,
        Active: true,
        Width: 1920,
        Height: 1080,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "LIRR",
        Id: "LIRR1031",
        Name: "Departure Board Outbound - Vertical - V3",
        Path: "LIRRDepartureBoardVerticalV3/index.html",
        Direction: "E",
        Screen: "V",
        SortOrder: 4,
        Active: true,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "LIRR",
        Id: "LIRR1041",
        Name: "Departure Board Inbound - Vertical - V3",
        Path: "LIRRDepartureBoardVerticalV3/index.html",
        Direction: "W",
        Screen: "V",
        SortOrder: 6,
        Active: true,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "LIRR",
        Id: "LIRR105",
        Name: "Station Dashboard",
        Path: "LIRRStationDashboard/index.html",
        Direction: "",
        Screen: "V",
        SortOrder: 7,
        Active: true,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: true,
        env: "",
    },
    {
        Agency: "LIRR",
        Id: "LIRR106",
        Name: "JFK Gateway - Map to Manhattan",
        Path: "JFKGatewayMaptoMN/index.html",
        Direction: "",
        Screen: "V",
        SortOrder: 8,
        Active: true,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
    {
        Agency: "LIRR",
        Id: "LIRR107",
        Name: "JFK Gateway - POI",
        Path: "JFKGatewayPOI/index.html",
        Direction: "",
        Screen: "V",
        SortOrder: 9,
        Active: true,
        Width: 1080,
        Height: 1920,
        IncludeTrack: false,
        useOTPStopId: false,
        env: "",
    },
];
