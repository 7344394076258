export const BaseApiUrl = process.env.REACT_APP_ApiBaseUrl;

export const NEW_USER_ID = 0;
export const APP_CURRENT_USERID_KEY = "app_current_user_id";
export const SKIP_AXIOS_INTERCEPT = "X-SKIP-AXIOS-INTERCEPT";

export const BannerMessage = process.env.REACT_APP_BannerMessage;

export const STORAGE_KEYS = {
    ACCESS_TOKEN: 'outfront-access-token',
    REFRESH_TOKEN: 'outfront-refresh-token',
    OAUTH_CODE_CHALLENGE: 'outfront-code-challenge',
    OAUTH_CODE_VERIFIER: 'outfront-code-verifier',
    OAUTH_STATE: 'outfront-oauth-state'
  }
export const ROUTE_PATH = {
  home: '/home',
  login: '/login',
  logout: '/logout',
}

export const heliumUrl = "https://helium-prod.mylirr.org/outfront/screens";

