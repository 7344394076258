import React, { Component } from "react";
import { RouteComponentProps } from "react-router";
import { NavbarComponent } from "../navbar/NavbarComponent";

export class PageNotFoundComponent extends Component<RouteComponentProps, {}> {
    
    render() {
        return (
            <div >
                <NavbarComponent currentPath={this.props.location.pathname} activeCheck="" />
                <div className="container ">
                    <h1 className="mt-5 display-4">Page Not Found</h1>
                </div>
            </div>
        );
    }
}
