import { ISubwayStationInfo } from "./interfaces/ISubwayStationInfo";

export const stationInfoJsonArray: ISubwayStationInfo[] = [
    {
        StationID: "1",
        ComplexID: "1",
        GTFS_StopID: "R01",
        Division: "BMT",
        Line: "Astoria",
        StopName: "Astoria - Ditmars Blvd",
        Borough: "Q",
        DaytimeRoutes: "N W",
        Structure: "Elevated",
        GTFSLatitude: "40.775036",
        GTFSLongitude: "-73.912034",
    },
    {
        StationID: "2",
        ComplexID: "2",
        GTFS_StopID: "R03",
        Division: "BMT",
        Line: "Astoria",
        StopName: "Astoria Blvd",
        Borough: "Q",
        DaytimeRoutes: "N W",
        Structure: "Elevated",
        GTFSLatitude: "40.770258",
        GTFSLongitude: "-73.917843",
    },
    {
        StationID: "3",
        ComplexID: "3",
        GTFS_StopID: "R04",
        Division: "BMT",
        Line: "Astoria",
        StopName: "30 Av",
        Borough: "Q",
        DaytimeRoutes: "N W",
        Structure: "Elevated",
        GTFSLatitude: "40.766779",
        GTFSLongitude: "-73.921479",
    },
    {
        StationID: "4",
        ComplexID: "4",
        GTFS_StopID: "R05",
        Division: "BMT",
        Line: "Astoria",
        StopName: "Broadway",
        Borough: "Q",
        DaytimeRoutes: "N W",
        Structure: "Elevated",
        GTFSLatitude: "40.76182",
        GTFSLongitude: "-73.925508",
    },
    {
        StationID: "5",
        ComplexID: "5",
        GTFS_StopID: "R06",
        Division: "BMT",
        Line: "Astoria",
        StopName: "36 Av",
        Borough: "Q",
        DaytimeRoutes: "N W",
        Structure: "Elevated",
        GTFSLatitude: "40.756804",
        GTFSLongitude: "-73.929575",
    },
    {
        StationID: "6",
        ComplexID: "6",
        GTFS_StopID: "R08",
        Division: "BMT",
        Line: "Astoria",
        StopName: "39 Av",
        Borough: "Q",
        DaytimeRoutes: "N W",
        Structure: "Elevated",
        GTFSLatitude: "40.752882",
        GTFSLongitude: "-73.932755",
    },
    {
        StationID: "7",
        ComplexID: "613",
        GTFS_StopID: "R11",
        Division: "BMT",
        Line: "Astoria",
        StopName: "Lexington Av/59 St",
        Borough: "M",
        DaytimeRoutes: "N W R",
        Structure: "Subway",
        GTFSLatitude: "40.76266",
        GTFSLongitude: "-73.967258",
    },
    {
        StationID: "8",
        ComplexID: "8",
        GTFS_StopID: "R13",
        Division: "BMT",
        Line: "Astoria",
        StopName: "5 Av/59 St",
        Borough: "M",
        DaytimeRoutes: "N W R",
        Structure: "Subway",
        GTFSLatitude: "40.764811",
        GTFSLongitude: "-73.973347",
    },
    {
        StationID: "9",
        ComplexID: "9",
        GTFS_StopID: "R14",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "57 St - 7 Av",
        Borough: "M",
        DaytimeRoutes: "N Q R W",
        Structure: "Subway",
        GTFSLatitude: "40.764664",
        GTFSLongitude: "-73.980658",
    },
    {
        StationID: "10",
        ComplexID: "10",
        GTFS_StopID: "R15",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "49 St",
        Borough: "M",
        DaytimeRoutes: "N R W",
        Structure: "Subway",
        GTFSLatitude: "40.759901",
        GTFSLongitude: "-73.984139",
    },
    {
        StationID: "11",
        ComplexID: "611",
        GTFS_StopID: "R16",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "Times Sq - 42 St",
        Borough: "M",
        DaytimeRoutes: "N Q R W",
        Structure: "Subway",
        GTFSLatitude: "40.754672",
        GTFSLongitude: "-73.986754",
    },
    {
        StationID: "12",
        ComplexID: "607",
        GTFS_StopID: "R17",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "34 St - Herald Sq",
        Borough: "M",
        DaytimeRoutes: "N Q R W",
        Structure: "Subway",
        GTFSLatitude: "40.749567",
        GTFSLongitude: "-73.98795",
    },
    {
        StationID: "13",
        ComplexID: "13",
        GTFS_StopID: "R18",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "28 St",
        Borough: "M",
        DaytimeRoutes: "R W",
        Structure: "Subway",
        GTFSLatitude: "40.745494",
        GTFSLongitude: "-73.988691",
    },
    {
        StationID: "14",
        ComplexID: "14",
        GTFS_StopID: "R19",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "23 St",
        Borough: "M",
        DaytimeRoutes: "R W",
        Structure: "Subway",
        GTFSLatitude: "40.741303",
        GTFSLongitude: "-73.989344",
    },
    {
        StationID: "15",
        ComplexID: "602",
        GTFS_StopID: "R20",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "14 St - Union Sq",
        Borough: "M",
        DaytimeRoutes: "N Q R W",
        Structure: "Subway",
        GTFSLatitude: "40.735736",
        GTFSLongitude: "-73.990568",
    },
    {
        StationID: "16",
        ComplexID: "16",
        GTFS_StopID: "R21",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "8 St - NYU",
        Borough: "M",
        DaytimeRoutes: "R W",
        Structure: "Subway",
        GTFSLatitude: "40.730328",
        GTFSLongitude: "-73.992629",
    },
    {
        StationID: "17",
        ComplexID: "17",
        GTFS_StopID: "R22",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "Prince St",
        Borough: "M",
        DaytimeRoutes: "R W",
        Structure: "Subway",
        GTFSLatitude: "40.724329",
        GTFSLongitude: "-73.997702",
    },
    {
        StationID: "18",
        ComplexID: "623",
        GTFS_StopID: "R23",
        Division: "BMT",
        Line: "Broadway",
        StopName: "Canal St",
        Borough: "M",
        DaytimeRoutes: "R W",
        Structure: "Subway",
        GTFSLatitude: "40.719527",
        GTFSLongitude: "-74.001775",
    },
    {
        StationID: "19",
        ComplexID: "623",
        GTFS_StopID: "Q01",
        Division: "BMT",
        Line: "Manhattan Bridge",
        StopName: "Canal St",
        Borough: "M",
        DaytimeRoutes: "N Q",
        Structure: "Subway",
        GTFSLatitude: "40.718383",
        GTFSLongitude: "-74.00046",
    },
    {
        StationID: "20",
        ComplexID: "20",
        GTFS_StopID: "R24",
        Division: "BMT",
        Line: "Broadway",
        StopName: "City Hall",
        Borough: "M",
        DaytimeRoutes: "R W",
        Structure: "Subway",
        GTFSLatitude: "40.713282",
        GTFSLongitude: "-74.006978",
    },
    {
        StationID: "21",
        ComplexID: "624",
        GTFS_StopID: "R25",
        Division: "BMT",
        Line: "Broadway",
        StopName: "Cortlandt St",
        Borough: "M",
        DaytimeRoutes: "R W",
        Structure: "Subway",
        GTFSLatitude: "40.710668",
        GTFSLongitude: "-74.011029",
    },
    {
        StationID: "22",
        ComplexID: "22",
        GTFS_StopID: "R26",
        Division: "BMT",
        Line: "Broadway",
        StopName: "Rector St",
        Borough: "M",
        DaytimeRoutes: "R W",
        Structure: "Subway",
        GTFSLatitude: "40.70722",
        GTFSLongitude: "-74.013342",
    },
    {
        StationID: "23",
        ComplexID: "635",
        GTFS_StopID: "R27",
        Division: "BMT",
        Line: "Broadway",
        StopName: "Whitehall St",
        Borough: "M",
        DaytimeRoutes: "R W",
        Structure: "Subway",
        GTFSLatitude: "40.703087",
        GTFSLongitude: "-74.012994",
    },
    {
        StationID: "24",
        ComplexID: "620",
        GTFS_StopID: "R28",
        Division: "BMT",
        Line: "Broadway",
        StopName: "Court St",
        Borough: "Bk",
        DaytimeRoutes: "R",
        Structure: "Subway",
        GTFSLatitude: "40.6941",
        GTFSLongitude: "-73.991777",
    },
    {
        StationID: "25",
        ComplexID: "636",
        GTFS_StopID: "R29",
        Division: "BMT",
        Line: "Broadway",
        StopName: "Jay St - MetroTech",
        Borough: "Bk",
        DaytimeRoutes: "R",
        Structure: "Subway",
        GTFSLatitude: "40.69218",
        GTFSLongitude: "-73.985942",
    },
    {
        StationID: "26",
        ComplexID: "26",
        GTFS_StopID: "R30",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "DeKalb Av",
        Borough: "Bk",
        DaytimeRoutes: "B Q R",
        Structure: "Subway",
        GTFSLatitude: "40.690635",
        GTFSLongitude: "-73.981824",
    },
    {
        StationID: "27",
        ComplexID: "617",
        GTFS_StopID: "R31",
        Division: "BMT",
        Line: "4th Av",
        StopName: "Atlantic Av - Barclays Ctr",
        Borough: "Bk",
        DaytimeRoutes: "D N R",
        Structure: "Subway",
        GTFSLatitude: "40.683666",
        GTFSLongitude: "-73.97881",
    },
    {
        StationID: "28",
        ComplexID: "28",
        GTFS_StopID: "R32",
        Division: "BMT",
        Line: "4th Av",
        StopName: "Union St",
        Borough: "Bk",
        DaytimeRoutes: "R",
        Structure: "Subway",
        GTFSLatitude: "40.677316",
        GTFSLongitude: "-73.98311",
    },
    {
        StationID: "29",
        ComplexID: "608",
        GTFS_StopID: "R33",
        Division: "BMT",
        Line: "4th Av",
        StopName: "9 St",
        Borough: "Bk",
        DaytimeRoutes: "R",
        Structure: "Subway",
        GTFSLatitude: "40.670847",
        GTFSLongitude: "-73.988302",
    },
    {
        StationID: "30",
        ComplexID: "30",
        GTFS_StopID: "R34",
        Division: "BMT",
        Line: "4th Av",
        StopName: "Prospect Av",
        Borough: "Bk",
        DaytimeRoutes: "R",
        Structure: "Subway",
        GTFSLatitude: "40.665414",
        GTFSLongitude: "-73.992872",
    },
    {
        StationID: "31",
        ComplexID: "31",
        GTFS_StopID: "R35",
        Division: "BMT",
        Line: "4th Av",
        StopName: "25 St",
        Borough: "Bk",
        DaytimeRoutes: "R",
        Structure: "Subway",
        GTFSLatitude: "40.660397",
        GTFSLongitude: "-73.998091",
    },
    {
        StationID: "32",
        ComplexID: "32",
        GTFS_StopID: "R36",
        Division: "BMT",
        Line: "4th Av",
        StopName: "36 St",
        Borough: "Bk",
        DaytimeRoutes: "D N R",
        Structure: "Subway",
        GTFSLatitude: "40.655144",
        GTFSLongitude: "-74.003549",
    },
    {
        StationID: "33",
        ComplexID: "33",
        GTFS_StopID: "R39",
        Division: "BMT",
        Line: "4th Av",
        StopName: "45 St",
        Borough: "Bk",
        DaytimeRoutes: "R",
        Structure: "Subway",
        GTFSLatitude: "40.648939",
        GTFSLongitude: "-74.010006",
    },
    {
        StationID: "34",
        ComplexID: "34",
        GTFS_StopID: "R40",
        Division: "BMT",
        Line: "4th Av",
        StopName: "53 St",
        Borough: "Bk",
        DaytimeRoutes: "R",
        Structure: "Subway",
        GTFSLatitude: "40.645069",
        GTFSLongitude: "-74.014034",
    },
    {
        StationID: "35",
        ComplexID: "35",
        GTFS_StopID: "R41",
        Division: "BMT",
        Line: "4th Av",
        StopName: "59 St",
        Borough: "Bk",
        DaytimeRoutes: "N R",
        Structure: "Subway",
        GTFSLatitude: "40.641362",
        GTFSLongitude: "-74.017881",
    },
    {
        StationID: "36",
        ComplexID: "36",
        GTFS_StopID: "R42",
        Division: "BMT",
        Line: "4th Av",
        StopName: "Bay Ridge Av",
        Borough: "Bk",
        DaytimeRoutes: "R",
        Structure: "Subway",
        GTFSLatitude: "40.634967",
        GTFSLongitude: "-74.023377",
    },
    {
        StationID: "37",
        ComplexID: "37",
        GTFS_StopID: "R43",
        Division: "BMT",
        Line: "4th Av",
        StopName: "77 St",
        Borough: "Bk",
        DaytimeRoutes: "R",
        Structure: "Subway",
        GTFSLatitude: "40.629742",
        GTFSLongitude: "-74.02551",
    },
    {
        StationID: "38",
        ComplexID: "38",
        GTFS_StopID: "R44",
        Division: "BMT",
        Line: "4th Av",
        StopName: "86 St",
        Borough: "Bk",
        DaytimeRoutes: "R",
        Structure: "Subway",
        GTFSLatitude: "40.622687",
        GTFSLongitude: "-74.028398",
    },
    {
        StationID: "39",
        ComplexID: "39",
        GTFS_StopID: "R45",
        Division: "BMT",
        Line: "4th Av",
        StopName: "Bay Ridge - 95 St",
        Borough: "Bk",
        DaytimeRoutes: "R",
        Structure: "Subway",
        GTFSLatitude: "40.616622",
        GTFSLongitude: "-74.030876",
    },
    {
        StationID: "40",
        ComplexID: "617",
        GTFS_StopID: "D24",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "Atlantic Av - Barclays Ctr",
        Borough: "Bk",
        DaytimeRoutes: "B Q",
        Structure: "Subway",
        GTFSLatitude: "40.68446",
        GTFSLongitude: "-73.97689",
    },
    {
        StationID: "41",
        ComplexID: "41",
        GTFS_StopID: "D25",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "7 Av",
        Borough: "Bk",
        DaytimeRoutes: "B Q",
        Structure: "Subway",
        GTFSLatitude: "40.67705",
        GTFSLongitude: "-73.972367",
    },
    {
        StationID: "42",
        ComplexID: "42",
        GTFS_StopID: "D26",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "Prospect Park",
        Borough: "Bk",
        DaytimeRoutes: "B Q",
        Structure: "Open Cut",
        GTFSLatitude: "40.661614",
        GTFSLongitude: "-73.962246",
    },
    {
        StationID: "43",
        ComplexID: "43",
        GTFS_StopID: "D27",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "Parkside Av",
        Borough: "Bk",
        DaytimeRoutes: "B Q",
        Structure: "Open Cut",
        GTFSLatitude: "40.655292",
        GTFSLongitude: "-73.961495",
    },
    {
        StationID: "44",
        ComplexID: "44",
        GTFS_StopID: "D28",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "Church Av",
        Borough: "Bk",
        DaytimeRoutes: "B Q",
        Structure: "Open Cut",
        GTFSLatitude: "40.650527",
        GTFSLongitude: "-73.962982",
    },
    {
        StationID: "45",
        ComplexID: "45",
        GTFS_StopID: "D29",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "Beverley Rd",
        Borough: "Bk",
        DaytimeRoutes: "B Q",
        Structure: "Open Cut",
        GTFSLatitude: "40.644031",
        GTFSLongitude: "-73.964492",
    },
    {
        StationID: "46",
        ComplexID: "46",
        GTFS_StopID: "D30",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "Cortelyou Rd",
        Borough: "Bk",
        DaytimeRoutes: "B Q",
        Structure: "Open Cut",
        GTFSLatitude: "40.640927",
        GTFSLongitude: "-73.963891",
    },
    {
        StationID: "47",
        ComplexID: "47",
        GTFS_StopID: "D31",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "Newkirk Plaza",
        Borough: "Bk",
        DaytimeRoutes: "B Q",
        Structure: "Open Cut",
        GTFSLatitude: "40.635082",
        GTFSLongitude: "-73.962793",
    },
    {
        StationID: "48",
        ComplexID: "48",
        GTFS_StopID: "D32",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "Avenue H",
        Borough: "Bk",
        DaytimeRoutes: "B Q",
        Structure: "Open Cut",
        GTFSLatitude: "40.62927",
        GTFSLongitude: "-73.961639",
    },
    {
        StationID: "49",
        ComplexID: "49",
        GTFS_StopID: "D33",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "Avenue J",
        Borough: "Bk",
        DaytimeRoutes: "B Q",
        Structure: "Open Cut",
        GTFSLatitude: "40.625039",
        GTFSLongitude: "-73.960803",
    },
    {
        StationID: "50",
        ComplexID: "50",
        GTFS_StopID: "D34",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "Avenue M",
        Borough: "Bk",
        DaytimeRoutes: "B Q",
        Structure: "Open Cut",
        GTFSLatitude: "40.617618",
        GTFSLongitude: "-73.959399",
    },
    {
        StationID: "51",
        ComplexID: "51",
        GTFS_StopID: "D35",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "Kings Hwy",
        Borough: "Bk",
        DaytimeRoutes: "B Q",
        Structure: "Open Cut",
        GTFSLatitude: "40.60867",
        GTFSLongitude: "-73.957734",
    },
    {
        StationID: "52",
        ComplexID: "52",
        GTFS_StopID: "D37",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "Avenue U",
        Borough: "Bk",
        DaytimeRoutes: "B Q",
        Structure: "Open Cut",
        GTFSLatitude: "40.5993",
        GTFSLongitude: "-73.955929",
    },
    {
        StationID: "53",
        ComplexID: "53",
        GTFS_StopID: "D38",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "Neck Rd",
        Borough: "Bk",
        DaytimeRoutes: "B Q",
        Structure: "Open Cut",
        GTFSLatitude: "40.595246",
        GTFSLongitude: "-73.955161",
    },
    {
        StationID: "54",
        ComplexID: "54",
        GTFS_StopID: "D39",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "Sheepshead Bay",
        Borough: "Bk",
        DaytimeRoutes: "B Q",
        Structure: "Open Cut",
        GTFSLatitude: "40.586896",
        GTFSLongitude: "-73.954155",
    },
    {
        StationID: "55",
        ComplexID: "55",
        GTFS_StopID: "D40",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "Brighton Beach",
        Borough: "Bk",
        DaytimeRoutes: "B Q",
        Structure: "Elevated",
        GTFSLatitude: "40.577621",
        GTFSLongitude: "-73.961376",
    },
    {
        StationID: "56",
        ComplexID: "56",
        GTFS_StopID: "D41",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "Ocean Pkwy",
        Borough: "Bk",
        DaytimeRoutes: "Q",
        Structure: "Elevated",
        GTFSLatitude: "40.576312",
        GTFSLongitude: "-73.968501",
    },
    {
        StationID: "57",
        ComplexID: "57",
        GTFS_StopID: "D42",
        Division: "BMT",
        Line: "Broadway - Brighton",
        StopName: "W 8 St - NY Aquarium",
        Borough: "Bk",
        DaytimeRoutes: "F Q",
        Structure: "Elevated",
        GTFSLatitude: "40.576127",
        GTFSLongitude: "-73.975939",
    },
    {
        StationID: "58",
        ComplexID: "58",
        GTFS_StopID: "D43",
        Division: "BMT",
        Line: "Sea Beach / West End / Culver / Brighton",
        StopName: "Coney Island - Stillwell Av",
        Borough: "Bk",
        DaytimeRoutes: "D F N Q",
        Structure: "Viaduct",
        GTFSLatitude: "40.577422",
        GTFSLongitude: "-73.981233",
    },
    {
        StationID: "59",
        ComplexID: "59",
        GTFS_StopID: "B12",
        Division: "BMT",
        Line: "West End",
        StopName: "9 Av",
        Borough: "Bk",
        DaytimeRoutes: "D",
        Structure: "Open Cut",
        GTFSLatitude: "40.646292",
        GTFSLongitude: "-73.994324",
    },
    {
        StationID: "60",
        ComplexID: "60",
        GTFS_StopID: "B13",
        Division: "BMT",
        Line: "West End",
        StopName: "Fort Hamilton Pkwy",
        Borough: "Bk",
        DaytimeRoutes: "D",
        Structure: "Elevated",
        GTFSLatitude: "40.640914",
        GTFSLongitude: "-73.994304",
    },
    {
        StationID: "61",
        ComplexID: "61",
        GTFS_StopID: "B14",
        Division: "BMT",
        Line: "West End",
        StopName: "50 St",
        Borough: "Bk",
        DaytimeRoutes: "D",
        Structure: "Elevated",
        GTFSLatitude: "40.63626",
        GTFSLongitude: "-73.994791",
    },
    {
        StationID: "62",
        ComplexID: "62",
        GTFS_StopID: "B15",
        Division: "BMT",
        Line: "West End",
        StopName: "55 St",
        Borough: "Bk",
        DaytimeRoutes: "D",
        Structure: "Elevated",
        GTFSLatitude: "40.631435",
        GTFSLongitude: "-73.995476",
    },
    {
        StationID: "63",
        ComplexID: "615",
        GTFS_StopID: "B16",
        Division: "BMT",
        Line: "West End",
        StopName: "62 St",
        Borough: "Bk",
        DaytimeRoutes: "D",
        Structure: "Elevated",
        GTFSLatitude: "40.626472",
        GTFSLongitude: "-73.996895",
    },
    {
        StationID: "64",
        ComplexID: "64",
        GTFS_StopID: "B17",
        Division: "BMT",
        Line: "West End",
        StopName: "71 St",
        Borough: "Bk",
        DaytimeRoutes: "D",
        Structure: "Elevated",
        GTFSLatitude: "40.619589",
        GTFSLongitude: "-73.998864",
    },
    {
        StationID: "65",
        ComplexID: "65",
        GTFS_StopID: "B18",
        Division: "BMT",
        Line: "West End",
        StopName: "79 St",
        Borough: "Bk",
        DaytimeRoutes: "D",
        Structure: "Elevated",
        GTFSLatitude: "40.613501",
        GTFSLongitude: "-74.00061",
    },
    {
        StationID: "66",
        ComplexID: "66",
        GTFS_StopID: "B19",
        Division: "BMT",
        Line: "West End",
        StopName: "18 Av",
        Borough: "Bk",
        DaytimeRoutes: "D",
        Structure: "Elevated",
        GTFSLatitude: "40.607954",
        GTFSLongitude: "-74.001736",
    },
    {
        StationID: "67",
        ComplexID: "67",
        GTFS_StopID: "B20",
        Division: "BMT",
        Line: "West End",
        StopName: "20 Av",
        Borough: "Bk",
        DaytimeRoutes: "D",
        Structure: "Elevated",
        GTFSLatitude: "40.604556",
        GTFSLongitude: "-73.998168",
    },
    {
        StationID: "68",
        ComplexID: "68",
        GTFS_StopID: "B21",
        Division: "BMT",
        Line: "West End",
        StopName: "Bay Pkwy",
        Borough: "Bk",
        DaytimeRoutes: "D",
        Structure: "Elevated",
        GTFSLatitude: "40.601875",
        GTFSLongitude: "-73.993728",
    },
    {
        StationID: "69",
        ComplexID: "69",
        GTFS_StopID: "B22",
        Division: "BMT",
        Line: "West End",
        StopName: "25 Av",
        Borough: "Bk",
        DaytimeRoutes: "D",
        Structure: "Elevated",
        GTFSLatitude: "40.597704",
        GTFSLongitude: "-73.986829",
    },
    {
        StationID: "70",
        ComplexID: "70",
        GTFS_StopID: "B23",
        Division: "BMT",
        Line: "West End",
        StopName: "Bay 50 St",
        Borough: "Bk",
        DaytimeRoutes: "D",
        Structure: "Elevated",
        GTFSLatitude: "40.588841",
        GTFSLongitude: "-73.983765",
    },
    {
        StationID: "71",
        ComplexID: "71",
        GTFS_StopID: "N02",
        Division: "BMT",
        Line: "Sea Beach",
        StopName: "8 Av",
        Borough: "Bk",
        DaytimeRoutes: "N",
        Structure: "Open Cut",
        GTFSLatitude: "40.635064",
        GTFSLongitude: "-74.011719",
    },
    {
        StationID: "72",
        ComplexID: "72",
        GTFS_StopID: "N03",
        Division: "BMT",
        Line: "Sea Beach",
        StopName: "Fort Hamilton Pkwy",
        Borough: "Bk",
        DaytimeRoutes: "N",
        Structure: "Open Cut",
        GTFSLatitude: "40.631386",
        GTFSLongitude: "-74.005351",
    },
    {
        StationID: "73",
        ComplexID: "615",
        GTFS_StopID: "N04",
        Division: "BMT",
        Line: "Sea Beach",
        StopName: "New Utrecht Av",
        Borough: "Bk",
        DaytimeRoutes: "N",
        Structure: "Open Cut",
        GTFSLatitude: "40.624842",
        GTFSLongitude: "-73.996353",
    },
    {
        StationID: "74",
        ComplexID: "74",
        GTFS_StopID: "N05",
        Division: "BMT",
        Line: "Sea Beach",
        StopName: "18 Av",
        Borough: "Bk",
        DaytimeRoutes: "N",
        Structure: "Open Cut",
        GTFSLatitude: "40.620671",
        GTFSLongitude: "-73.990414",
    },
    {
        StationID: "75",
        ComplexID: "75",
        GTFS_StopID: "N06",
        Division: "BMT",
        Line: "Sea Beach",
        StopName: "20 Av",
        Borough: "Bk",
        DaytimeRoutes: "N",
        Structure: "Open Cut",
        GTFSLatitude: "40.61741",
        GTFSLongitude: "-73.985026",
    },
    {
        StationID: "76",
        ComplexID: "76",
        GTFS_StopID: "N07",
        Division: "BMT",
        Line: "Sea Beach",
        StopName: "Bay Pkwy",
        Borough: "Bk",
        DaytimeRoutes: "N",
        Structure: "Open Cut",
        GTFSLatitude: "40.611815",
        GTFSLongitude: "-73.981848",
    },
    {
        StationID: "77",
        ComplexID: "77",
        GTFS_StopID: "N08",
        Division: "BMT",
        Line: "Sea Beach",
        StopName: "Kings Hwy",
        Borough: "Bk",
        DaytimeRoutes: "N",
        Structure: "Open Cut",
        GTFSLatitude: "40.603923",
        GTFSLongitude: "-73.980353",
    },
    {
        StationID: "78",
        ComplexID: "78",
        GTFS_StopID: "N09",
        Division: "BMT",
        Line: "Sea Beach",
        StopName: "Avenue U",
        Borough: "Bk",
        DaytimeRoutes: "N",
        Structure: "Open Cut",
        GTFSLatitude: "40.597473",
        GTFSLongitude: "-73.979137",
    },
    {
        StationID: "79",
        ComplexID: "79",
        GTFS_StopID: "N10",
        Division: "BMT",
        Line: "Sea Beach",
        StopName: "86 St",
        Borough: "Bk",
        DaytimeRoutes: "N",
        Structure: "Open Cut",
        GTFSLatitude: "40.592721",
        GTFSLongitude: "-73.97823",
    },
    {
        StationID: "80",
        ComplexID: "80",
        GTFS_StopID: "J12",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "121 St",
        Borough: "Q",
        DaytimeRoutes: "J Z",
        Structure: "Elevated",
        GTFSLatitude: "40.700492",
        GTFSLongitude: "-73.828294",
    },
    {
        StationID: "81",
        ComplexID: "81",
        GTFS_StopID: "J13",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "111 St",
        Borough: "Q",
        DaytimeRoutes: "J",
        Structure: "Elevated",
        GTFSLatitude: "40.697418",
        GTFSLongitude: "-73.836345",
    },
    {
        StationID: "82",
        ComplexID: "82",
        GTFS_StopID: "J14",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "104 St",
        Borough: "Q",
        DaytimeRoutes: "J Z",
        Structure: "Elevated",
        GTFSLatitude: "40.695178",
        GTFSLongitude: "-73.84433",
    },
    {
        StationID: "83",
        ComplexID: "83",
        GTFS_StopID: "J15",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "Woodhaven Blvd",
        Borough: "Q",
        DaytimeRoutes: "J Z",
        Structure: "Elevated",
        GTFSLatitude: "40.693879",
        GTFSLongitude: "-73.851576",
    },
    {
        StationID: "84",
        ComplexID: "84",
        GTFS_StopID: "J16",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "85 St - Forest Pkwy",
        Borough: "Q",
        DaytimeRoutes: "J",
        Structure: "Elevated",
        GTFSLatitude: "40.692435",
        GTFSLongitude: "-73.86001",
    },
    {
        StationID: "85",
        ComplexID: "85",
        GTFS_StopID: "J17",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "75 St",
        Borough: "Q",
        DaytimeRoutes: "J Z",
        Structure: "Elevated",
        GTFSLatitude: "40.691324",
        GTFSLongitude: "-73.867139",
    },
    {
        StationID: "86",
        ComplexID: "86",
        GTFS_StopID: "J19",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "Cypress Hills",
        Borough: "Bk",
        DaytimeRoutes: "J",
        Structure: "Elevated",
        GTFSLatitude: "40.689941",
        GTFSLongitude: "-73.87255",
    },
    {
        StationID: "87",
        ComplexID: "87",
        GTFS_StopID: "J20",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "Crescent St",
        Borough: "Bk",
        DaytimeRoutes: "J Z",
        Structure: "Elevated",
        GTFSLatitude: "40.683194",
        GTFSLongitude: "-73.873785",
    },
    {
        StationID: "88",
        ComplexID: "88",
        GTFS_StopID: "J21",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "Norwood Av",
        Borough: "Bk",
        DaytimeRoutes: "J Z",
        Structure: "Elevated",
        GTFSLatitude: "40.68141",
        GTFSLongitude: "-73.880039",
    },
    {
        StationID: "89",
        ComplexID: "89",
        GTFS_StopID: "J22",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "Cleveland St",
        Borough: "Bk",
        DaytimeRoutes: "J",
        Structure: "Elevated",
        GTFSLatitude: "40.679947",
        GTFSLongitude: "-73.884639",
    },
    {
        StationID: "90",
        ComplexID: "90",
        GTFS_StopID: "J23",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "Van Siclen Av",
        Borough: "Bk",
        DaytimeRoutes: "J Z",
        Structure: "Elevated",
        GTFSLatitude: "40.678024",
        GTFSLongitude: "-73.891688",
    },
    {
        StationID: "91",
        ComplexID: "91",
        GTFS_StopID: "J24",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "Alabama Av",
        Borough: "Bk",
        DaytimeRoutes: "J",
        Structure: "Elevated",
        GTFSLatitude: "40.676992",
        GTFSLongitude: "-73.898654",
    },
    {
        StationID: "92",
        ComplexID: "621",
        GTFS_StopID: "J27",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "Broadway Jct",
        Borough: "Bk",
        DaytimeRoutes: "J Z",
        Structure: "Elevated",
        GTFSLatitude: "40.679498",
        GTFSLongitude: "-73.904512",
    },
    {
        StationID: "93",
        ComplexID: "93",
        GTFS_StopID: "J28",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "Chauncey St",
        Borough: "Bk",
        DaytimeRoutes: "J Z",
        Structure: "Elevated",
        GTFSLatitude: "40.682893",
        GTFSLongitude: "-73.910456",
    },
    {
        StationID: "94",
        ComplexID: "94",
        GTFS_StopID: "J29",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "Halsey St",
        Borough: "Bk",
        DaytimeRoutes: "J",
        Structure: "Elevated",
        GTFSLatitude: "40.68637",
        GTFSLongitude: "-73.916559",
    },
    {
        StationID: "95",
        ComplexID: "95",
        GTFS_StopID: "J30",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "Gates Av",
        Borough: "Bk",
        DaytimeRoutes: "J Z",
        Structure: "Elevated",
        GTFSLatitude: "40.68963",
        GTFSLongitude: "-73.92227",
    },
    {
        StationID: "96",
        ComplexID: "96",
        GTFS_StopID: "J31",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "Kosciuszko St",
        Borough: "Bk",
        DaytimeRoutes: "J",
        Structure: "Elevated",
        GTFSLatitude: "40.693342",
        GTFSLongitude: "-73.928814",
    },
    {
        StationID: "97",
        ComplexID: "97",
        GTFS_StopID: "M11",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "Myrtle Av",
        Borough: "Bk",
        DaytimeRoutes: "J M Z",
        Structure: "Elevated",
        GTFSLatitude: "40.697207",
        GTFSLongitude: "-73.935657",
    },
    {
        StationID: "98",
        ComplexID: "98",
        GTFS_StopID: "M12",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "Flushing Av",
        Borough: "Bk",
        DaytimeRoutes: "J M",
        Structure: "Elevated",
        GTFSLatitude: "40.70026",
        GTFSLongitude: "-73.941126",
    },
    {
        StationID: "99",
        ComplexID: "99",
        GTFS_StopID: "M13",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "Lorimer St",
        Borough: "Bk",
        DaytimeRoutes: "J M",
        Structure: "Elevated",
        GTFSLatitude: "40.703869",
        GTFSLongitude: "-73.947408",
    },
    {
        StationID: "100",
        ComplexID: "100",
        GTFS_StopID: "M14",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "Hewes St",
        Borough: "Bk",
        DaytimeRoutes: "J M",
        Structure: "Elevated",
        GTFSLatitude: "40.70687",
        GTFSLongitude: "-73.953431",
    },
    {
        StationID: "101",
        ComplexID: "101",
        GTFS_StopID: "M16",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "Marcy Av",
        Borough: "Bk",
        DaytimeRoutes: "J M Z",
        Structure: "Elevated",
        GTFSLatitude: "40.708359",
        GTFSLongitude: "-73.957757",
    },
    {
        StationID: "102",
        ComplexID: "625",
        GTFS_StopID: "M18",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "Essex St",
        Borough: "M",
        DaytimeRoutes: "J M Z",
        Structure: "Subway",
        GTFSLatitude: "40.718315",
        GTFSLongitude: "-73.987437",
    },
    {
        StationID: "103",
        ComplexID: "103",
        GTFS_StopID: "M19",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "Bowery",
        Borough: "M",
        DaytimeRoutes: "J Z",
        Structure: "Subway",
        GTFSLatitude: "40.72028",
        GTFSLongitude: "-73.993915",
    },
    {
        StationID: "104",
        ComplexID: "623",
        GTFS_StopID: "M20",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "Canal St",
        Borough: "M",
        DaytimeRoutes: "J Z",
        Structure: "Subway",
        GTFSLatitude: "40.718092",
        GTFSLongitude: "-73.999892",
    },
    {
        StationID: "105",
        ComplexID: "622",
        GTFS_StopID: "M21",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "Chambers St",
        Borough: "M",
        DaytimeRoutes: "J Z",
        Structure: "Subway",
        GTFSLatitude: "40.713243",
        GTFSLongitude: "-74.003401",
    },
    {
        StationID: "106",
        ComplexID: "628",
        GTFS_StopID: "M22",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "Fulton St",
        Borough: "M",
        DaytimeRoutes: "J Z",
        Structure: "Subway",
        GTFSLatitude: "40.710374",
        GTFSLongitude: "-74.007582",
    },
    {
        StationID: "107",
        ComplexID: "107",
        GTFS_StopID: "M23",
        Division: "BMT",
        Line: "Jamaica",
        StopName: "Broad St",
        Borough: "M",
        DaytimeRoutes: "J Z",
        Structure: "Subway",
        GTFSLatitude: "40.706476",
        GTFSLongitude: "-74.011056",
    },
    {
        StationID: "108",
        ComplexID: "108",
        GTFS_StopID: "M01",
        Division: "BMT",
        Line: "Myrtle Av",
        StopName: "Middle Village - Metropolitan Av",
        Borough: "Q",
        DaytimeRoutes: "M",
        Structure: "Elevated",
        GTFSLatitude: "40.711396",
        GTFSLongitude: "-73.889601",
    },
    {
        StationID: "109",
        ComplexID: "109",
        GTFS_StopID: "M04",
        Division: "BMT",
        Line: "Myrtle Av",
        StopName: "Fresh Pond Rd",
        Borough: "Q",
        DaytimeRoutes: "M",
        Structure: "Elevated",
        GTFSLatitude: "40.706186",
        GTFSLongitude: "-73.895877",
    },
    {
        StationID: "110",
        ComplexID: "110",
        GTFS_StopID: "M05",
        Division: "BMT",
        Line: "Myrtle Av",
        StopName: "Forest Av",
        Borough: "Q",
        DaytimeRoutes: "M",
        Structure: "Elevated",
        GTFSLatitude: "40.704423",
        GTFSLongitude: "-73.903077",
    },
    {
        StationID: "111",
        ComplexID: "111",
        GTFS_StopID: "M06",
        Division: "BMT",
        Line: "Myrtle Av",
        StopName: "Seneca Av",
        Borough: "Q",
        DaytimeRoutes: "M",
        Structure: "Elevated",
        GTFSLatitude: "40.702762",
        GTFSLongitude: "-73.90774",
    },
    {
        StationID: "112",
        ComplexID: "630",
        GTFS_StopID: "M08",
        Division: "BMT",
        Line: "Myrtle Av",
        StopName: "Myrtle - Wyckoff Avs",
        Borough: "Bk",
        DaytimeRoutes: "M",
        Structure: "Elevated",
        GTFSLatitude: "40.69943",
        GTFSLongitude: "-73.912385",
    },
    {
        StationID: "113",
        ComplexID: "113",
        GTFS_StopID: "M09",
        Division: "BMT",
        Line: "Myrtle Av",
        StopName: "Knickerbocker Av",
        Borough: "Bk",
        DaytimeRoutes: "M",
        Structure: "Elevated",
        GTFSLatitude: "40.698664",
        GTFSLongitude: "-73.919711",
    },
    {
        StationID: "114",
        ComplexID: "114",
        GTFS_StopID: "M10",
        Division: "BMT",
        Line: "Myrtle Av",
        StopName: "Central Av",
        Borough: "Bk",
        DaytimeRoutes: "M",
        Structure: "Elevated",
        GTFSLatitude: "40.697857",
        GTFSLongitude: "-73.927397",
    },
    {
        StationID: "115",
        ComplexID: "618",
        GTFS_StopID: "L01",
        Division: "BMT",
        Line: "Canarsie",
        StopName: "8 Av",
        Borough: "M",
        DaytimeRoutes: "L",
        Structure: "Subway",
        GTFSLatitude: "40.739777",
        GTFSLongitude: "-74.002578",
    },
    {
        StationID: "116",
        ComplexID: "601",
        GTFS_StopID: "L02",
        Division: "BMT",
        Line: "Canarsie",
        StopName: "6 Av",
        Borough: "M",
        DaytimeRoutes: "L",
        Structure: "Subway",
        GTFSLatitude: "40.737335",
        GTFSLongitude: "-73.996786",
    },
    {
        StationID: "117",
        ComplexID: "602",
        GTFS_StopID: "L03",
        Division: "BMT",
        Line: "Canarsie",
        StopName: "Union Sq - 14 St",
        Borough: "M",
        DaytimeRoutes: "L",
        Structure: "Subway",
        GTFSLatitude: "40.734789",
        GTFSLongitude: "-73.99073",
    },
    {
        StationID: "118",
        ComplexID: "118",
        GTFS_StopID: "L05",
        Division: "BMT",
        Line: "Canarsie",
        StopName: "3 Av",
        Borough: "M",
        DaytimeRoutes: "L",
        Structure: "Subway",
        GTFSLatitude: "40.732849",
        GTFSLongitude: "-73.986122",
    },
    {
        StationID: "119",
        ComplexID: "119",
        GTFS_StopID: "L06",
        Division: "BMT",
        Line: "Canarsie",
        StopName: "1 Av",
        Borough: "M",
        DaytimeRoutes: "L",
        Structure: "Subway",
        GTFSLatitude: "40.730953",
        GTFSLongitude: "-73.981628",
    },
    {
        StationID: "120",
        ComplexID: "120",
        GTFS_StopID: "L08",
        Division: "BMT",
        Line: "Canarsie",
        StopName: "Bedford Av",
        Borough: "Bk",
        DaytimeRoutes: "L",
        Structure: "Subway",
        GTFSLatitude: "40.717304",
        GTFSLongitude: "-73.956872",
    },
    {
        StationID: "121",
        ComplexID: "629",
        GTFS_StopID: "L10",
        Division: "BMT",
        Line: "Canarsie",
        StopName: "Lorimer St",
        Borough: "Bk",
        DaytimeRoutes: "L",
        Structure: "Subway",
        GTFSLatitude: "40.714063",
        GTFSLongitude: "-73.950275",
    },
    {
        StationID: "122",
        ComplexID: "122",
        GTFS_StopID: "L11",
        Division: "BMT",
        Line: "Canarsie",
        StopName: "Graham Av",
        Borough: "Bk",
        DaytimeRoutes: "L",
        Structure: "Subway",
        GTFSLatitude: "40.714565",
        GTFSLongitude: "-73.944053",
    },
    {
        StationID: "123",
        ComplexID: "123",
        GTFS_StopID: "L12",
        Division: "BMT",
        Line: "Canarsie",
        StopName: "Grand St",
        Borough: "Bk",
        DaytimeRoutes: "L",
        Structure: "Subway",
        GTFSLatitude: "40.711926",
        GTFSLongitude: "-73.94067",
    },
    {
        StationID: "124",
        ComplexID: "124",
        GTFS_StopID: "L13",
        Division: "BMT",
        Line: "Canarsie",
        StopName: "Montrose Av",
        Borough: "Bk",
        DaytimeRoutes: "L",
        Structure: "Subway",
        GTFSLatitude: "40.707739",
        GTFSLongitude: "-73.93985",
    },
    {
        StationID: "125",
        ComplexID: "125",
        GTFS_StopID: "L14",
        Division: "BMT",
        Line: "Canarsie",
        StopName: "Morgan Av",
        Borough: "Bk",
        DaytimeRoutes: "L",
        Structure: "Subway",
        GTFSLatitude: "40.706152",
        GTFSLongitude: "-73.933147",
    },
    {
        StationID: "126",
        ComplexID: "126",
        GTFS_StopID: "L15",
        Division: "BMT",
        Line: "Canarsie",
        StopName: "Jefferson St",
        Borough: "Bk",
        DaytimeRoutes: "L",
        Structure: "Subway",
        GTFSLatitude: "40.706607",
        GTFSLongitude: "-73.922913",
    },
    {
        StationID: "127",
        ComplexID: "127",
        GTFS_StopID: "L16",
        Division: "BMT",
        Line: "Canarsie",
        StopName: "DeKalb Av",
        Borough: "Bk",
        DaytimeRoutes: "L",
        Structure: "Subway",
        GTFSLatitude: "40.703811",
        GTFSLongitude: "-73.918425",
    },
    {
        StationID: "128",
        ComplexID: "630",
        GTFS_StopID: "L17",
        Division: "BMT",
        Line: "Canarsie",
        StopName: "Myrtle - Wyckoff Avs",
        Borough: "Bk",
        DaytimeRoutes: "L",
        Structure: "Subway",
        GTFSLatitude: "40.699814",
        GTFSLongitude: "-73.911586",
    },
    {
        StationID: "129",
        ComplexID: "129",
        GTFS_StopID: "L19",
        Division: "BMT",
        Line: "Canarsie",
        StopName: "Halsey St",
        Borough: "Q",
        DaytimeRoutes: "L",
        Structure: "Subway",
        GTFSLatitude: "40.695602",
        GTFSLongitude: "-73.904084",
    },
    {
        StationID: "130",
        ComplexID: "130",
        GTFS_StopID: "L20",
        Division: "BMT",
        Line: "Canarsie",
        StopName: "Wilson Av",
        Borough: "Bk",
        DaytimeRoutes: "L",
        Structure: "Subway",
        GTFSLatitude: "40.688764",
        GTFSLongitude: "-73.904046",
    },
    {
        StationID: "131",
        ComplexID: "131",
        GTFS_StopID: "L21",
        Division: "BMT",
        Line: "Canarsie",
        StopName: "Bushwick Av - Aberdeen St",
        Borough: "Bk",
        DaytimeRoutes: "L",
        Structure: "Subway",
        GTFSLatitude: "40.682829",
        GTFSLongitude: "-73.905249",
    },
    {
        StationID: "132",
        ComplexID: "621",
        GTFS_StopID: "L22",
        Division: "BMT",
        Line: "Canarsie",
        StopName: "Broadway Jct",
        Borough: "Bk",
        DaytimeRoutes: "L",
        Structure: "Elevated",
        GTFSLatitude: "40.678856",
        GTFSLongitude: "-73.90324",
    },
    {
        StationID: "133",
        ComplexID: "133",
        GTFS_StopID: "L24",
        Division: "BMT",
        Line: "Canarsie",
        StopName: "Atlantic Av",
        Borough: "Bk",
        DaytimeRoutes: "L",
        Structure: "Elevated",
        GTFSLatitude: "40.675345",
        GTFSLongitude: "-73.903097",
    },
    {
        StationID: "134",
        ComplexID: "134",
        GTFS_StopID: "L25",
        Division: "BMT",
        Line: "Canarsie",
        StopName: "Sutter Av",
        Borough: "Bk",
        DaytimeRoutes: "L",
        Structure: "Elevated",
        GTFSLatitude: "40.669367",
        GTFSLongitude: "-73.901975",
    },
    {
        StationID: "135",
        ComplexID: "135",
        GTFS_StopID: "L26",
        Division: "BMT",
        Line: "Canarsie",
        StopName: "Livonia Av",
        Borough: "Bk",
        DaytimeRoutes: "L",
        Structure: "Elevated",
        GTFSLatitude: "40.664038",
        GTFSLongitude: "-73.900571",
    },
    {
        StationID: "136",
        ComplexID: "136",
        GTFS_StopID: "L27",
        Division: "BMT",
        Line: "Canarsie",
        StopName: "New Lots Av",
        Borough: "Bk",
        DaytimeRoutes: "L",
        Structure: "Elevated",
        GTFSLatitude: "40.658733",
        GTFSLongitude: "-73.899232",
    },
    {
        StationID: "137",
        ComplexID: "137",
        GTFS_StopID: "L28",
        Division: "BMT",
        Line: "Canarsie",
        StopName: "E 105 St",
        Borough: "Bk",
        DaytimeRoutes: "L",
        Structure: "At Grade",
        GTFSLatitude: "40.650573",
        GTFSLongitude: "-73.899485",
    },
    {
        StationID: "138",
        ComplexID: "138",
        GTFS_StopID: "L29",
        Division: "BMT",
        Line: "Canarsie",
        StopName: "Canarsie - Rockaway Pkwy",
        Borough: "Bk",
        DaytimeRoutes: "L",
        Structure: "At Grade",
        GTFSLatitude: "40.646654",
        GTFSLongitude: "-73.90185",
    },
    {
        StationID: "139",
        ComplexID: "627",
        GTFS_StopID: "S01",
        Division: "BMT",
        Line: "Franklin Shuttle",
        StopName: "Franklin Av-Medgar Evers College",
        Borough: "Bk",
        DaytimeRoutes: "FS",
        Structure: "Elevated",
        GTFSLatitude: "40.680596",
        GTFSLongitude: "-73.955827",
    },
    {
        StationID: "141",
        ComplexID: "141",
        GTFS_StopID: "S03",
        Division: "BMT",
        Line: "Franklin Shuttle",
        StopName: "Park Pl",
        Borough: "Bk",
        DaytimeRoutes: "FS",
        Structure: "Open Cut",
        GTFSLatitude: "40.674772",
        GTFSLongitude: "-73.957624",
    },
    {
        StationID: "142",
        ComplexID: "626",
        GTFS_StopID: "S04",
        Division: "BMT",
        Line: "Franklin Shuttle",
        StopName: "Botanic Garden",
        Borough: "Bk",
        DaytimeRoutes: "FS",
        Structure: "Open Cut",
        GTFSLatitude: "40.670343",
        GTFSLongitude: "-73.959245",
    },
    {
        StationID: "143",
        ComplexID: "143",
        GTFS_StopID: "A02",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "Inwood - 207 St",
        Borough: "M",
        DaytimeRoutes: "A",
        Structure: "Subway",
        GTFSLatitude: "40.868072",
        GTFSLongitude: "-73.919899",
    },
    {
        StationID: "144",
        ComplexID: "144",
        GTFS_StopID: "A03",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "Dyckman St",
        Borough: "M",
        DaytimeRoutes: "A",
        Structure: "Subway",
        GTFSLatitude: "40.865491",
        GTFSLongitude: "-73.927271",
    },
    {
        StationID: "145",
        ComplexID: "145",
        GTFS_StopID: "A05",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "190 St",
        Borough: "M",
        DaytimeRoutes: "A",
        Structure: "Subway",
        GTFSLatitude: "40.859022",
        GTFSLongitude: "-73.93418",
    },
    {
        StationID: "146",
        ComplexID: "146",
        GTFS_StopID: "A06",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "181 St",
        Borough: "M",
        DaytimeRoutes: "A",
        Structure: "Subway",
        GTFSLatitude: "40.851695",
        GTFSLongitude: "-73.937969",
    },
    {
        StationID: "147",
        ComplexID: "147",
        GTFS_StopID: "A07",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "175 St",
        Borough: "M",
        DaytimeRoutes: "A",
        Structure: "Subway",
        GTFSLatitude: "40.847391",
        GTFSLongitude: "-73.939704",
    },
    {
        StationID: "148",
        ComplexID: "605",
        GTFS_StopID: "A09",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "168 St",
        Borough: "M",
        DaytimeRoutes: "A C",
        Structure: "Subway",
        GTFSLatitude: "40.840719",
        GTFSLongitude: "-73.939561",
    },
    {
        StationID: "149",
        ComplexID: "149",
        GTFS_StopID: "A10",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "163 St - Amsterdam Av",
        Borough: "M",
        DaytimeRoutes: "C",
        Structure: "Subway",
        GTFSLatitude: "40.836013",
        GTFSLongitude: "-73.939892",
    },
    {
        StationID: "150",
        ComplexID: "150",
        GTFS_StopID: "A11",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "155 St",
        Borough: "M",
        DaytimeRoutes: "C",
        Structure: "Subway",
        GTFSLatitude: "40.830518",
        GTFSLongitude: "-73.941514",
    },
    {
        StationID: "151",
        ComplexID: "151",
        GTFS_StopID: "A12",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "145 St",
        Borough: "M",
        DaytimeRoutes: "A C",
        Structure: "Subway",
        GTFSLatitude: "40.824783",
        GTFSLongitude: "-73.944216",
    },
    {
        StationID: "151",
        ComplexID: "151",
        GTFS_StopID: "D13",
        Division: "IND",
        Line: "Concourse",
        StopName: "145 St",
        Borough: "M",
        DaytimeRoutes: "B D",
        Structure: "Subway",
        GTFSLatitude: "40.824783",
        GTFSLongitude: "-73.944216",
    },
    {
        StationID: "152",
        ComplexID: "152",
        GTFS_StopID: "A14",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "135 St",
        Borough: "M",
        DaytimeRoutes: "B C",
        Structure: "Subway",
        GTFSLatitude: "40.817894",
        GTFSLongitude: "-73.947649",
    },
    {
        StationID: "153",
        ComplexID: "153",
        GTFS_StopID: "A15",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "125 St",
        Borough: "M",
        DaytimeRoutes: "A B C D",
        Structure: "Subway",
        GTFSLatitude: "40.811109",
        GTFSLongitude: "-73.952343",
    },
    {
        StationID: "154",
        ComplexID: "154",
        GTFS_StopID: "A16",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "116 St",
        Borough: "M",
        DaytimeRoutes: "B C",
        Structure: "Subway",
        GTFSLatitude: "40.805085",
        GTFSLongitude: "-73.954882",
    },
    {
        StationID: "155",
        ComplexID: "155",
        GTFS_StopID: "A17",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "Cathedral Pkwy (110 St)",
        Borough: "M",
        DaytimeRoutes: "B C",
        Structure: "Subway",
        GTFSLatitude: "40.800603",
        GTFSLongitude: "-73.958161",
    },
    {
        StationID: "156",
        ComplexID: "156",
        GTFS_StopID: "A18",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "103 St",
        Borough: "M",
        DaytimeRoutes: "B C",
        Structure: "Subway",
        GTFSLatitude: "40.796092",
        GTFSLongitude: "-73.961454",
    },
    {
        StationID: "157",
        ComplexID: "157",
        GTFS_StopID: "A19",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "96 St",
        Borough: "M",
        DaytimeRoutes: "B C",
        Structure: "Subway",
        GTFSLatitude: "40.791642",
        GTFSLongitude: "-73.964696",
    },
    {
        StationID: "158",
        ComplexID: "158",
        GTFS_StopID: "A20",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "86 St",
        Borough: "M",
        DaytimeRoutes: "B C",
        Structure: "Subway",
        GTFSLatitude: "40.785868",
        GTFSLongitude: "-73.968916",
    },
    {
        StationID: "159",
        ComplexID: "159",
        GTFS_StopID: "A21",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "81 St - Museum of Natural History",
        Borough: "M",
        DaytimeRoutes: "B C",
        Structure: "Subway",
        GTFSLatitude: "40.781433",
        GTFSLongitude: "-73.972143",
    },
    {
        StationID: "160",
        ComplexID: "160",
        GTFS_StopID: "A22",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "72 St",
        Borough: "M",
        DaytimeRoutes: "B C",
        Structure: "Subway",
        GTFSLatitude: "40.775594",
        GTFSLongitude: "-73.97641",
    },
    {
        StationID: "161",
        ComplexID: "614",
        GTFS_StopID: "A24",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "59 St - Columbus Circle",
        Borough: "M",
        DaytimeRoutes: "A B C D",
        Structure: "Subway",
        GTFSLatitude: "40.768296",
        GTFSLongitude: "-73.981736",
    },
    {
        StationID: "162",
        ComplexID: "162",
        GTFS_StopID: "A25",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "50 St",
        Borough: "M",
        DaytimeRoutes: "C E",
        Structure: "Subway",
        GTFSLatitude: "40.762456",
        GTFSLongitude: "-73.985984",
    },
    {
        StationID: "163",
        ComplexID: "611",
        GTFS_StopID: "A27",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "42 St - Port Authority Bus Terminal",
        Borough: "M",
        DaytimeRoutes: "A C E",
        Structure: "Subway",
        GTFSLatitude: "40.757308",
        GTFSLongitude: "-73.989735",
    },
    {
        StationID: "164",
        ComplexID: "164",
        GTFS_StopID: "A28",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "34 St - Penn Station",
        Borough: "M",
        DaytimeRoutes: "A C E",
        Structure: "Subway",
        GTFSLatitude: "40.752287",
        GTFSLongitude: "-73.993391",
        NorthTracks: ["A2","A4"],
        SouthTracks: ["A3","A1"],
    },
    {
        StationID: "165",
        ComplexID: "165",
        GTFS_StopID: "A30",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "23 St",
        Borough: "M",
        DaytimeRoutes: "C E",
        Structure: "Subway",
        GTFSLatitude: "40.745906",
        GTFSLongitude: "-73.998041",
    },
    {
        StationID: "166",
        ComplexID: "618",
        GTFS_StopID: "A31",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "14 St",
        Borough: "M",
        DaytimeRoutes: "A C E",
        Structure: "Subway",
        GTFSLatitude: "40.740893",
        GTFSLongitude: "-74.00169",
    },
    {
        StationID: "167",
        ComplexID: "167",
        GTFS_StopID: "A32",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "W 4 St",
        Borough: "M",
        DaytimeRoutes: "A C E",
        Structure: "Subway",
        GTFSLatitude: "40.732338",
        GTFSLongitude: "-74.000495",
    },
    {
        StationID: "167",
        ComplexID: "167",
        GTFS_StopID: "D20",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "W 4 St",
        Borough: "M",
        DaytimeRoutes: "B D F M",
        Structure: "Subway",
        GTFSLatitude: "40.732338",
        GTFSLongitude: "-74.000495",
    },
    {
        StationID: "168",
        ComplexID: "168",
        GTFS_StopID: "A33",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "Spring St",
        Borough: "M",
        DaytimeRoutes: "C E",
        Structure: "Subway",
        GTFSLatitude: "40.726227",
        GTFSLongitude: "-74.003739",
    },
    {
        StationID: "169",
        ComplexID: "169",
        GTFS_StopID: "A34",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "Canal St",
        Borough: "M",
        DaytimeRoutes: "A C E",
        Structure: "Subway",
        GTFSLatitude: "40.720824",
        GTFSLongitude: "-74.005229",
    },
    {
        StationID: "170",
        ComplexID: "624",
        GTFS_StopID: "A36",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "Chambers St",
        Borough: "M",
        DaytimeRoutes: "A C",
        Structure: "Subway",
        GTFSLatitude: "40.714111",
        GTFSLongitude: "-74.008585",
    },
    {
        StationID: "171",
        ComplexID: "624",
        GTFS_StopID: "E01",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "World Trade Center",
        Borough: "M",
        DaytimeRoutes: "E",
        Structure: "Subway",
        GTFSLatitude: "40.712582",
        GTFSLongitude: "-74.009781",
    },
    {
        StationID: "172",
        ComplexID: "628",
        GTFS_StopID: "A38",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "Fulton St",
        Borough: "M",
        DaytimeRoutes: "A C",
        Structure: "Subway",
        GTFSLatitude: "40.710197",
        GTFSLongitude: "-74.007691",
    },
    {
        StationID: "173",
        ComplexID: "173",
        GTFS_StopID: "A40",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "High St",
        Borough: "Bk",
        DaytimeRoutes: "A C",
        Structure: "Subway",
        GTFSLatitude: "40.699337",
        GTFSLongitude: "-73.990531",
    },
    {
        StationID: "174",
        ComplexID: "636",
        GTFS_StopID: "A41",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "Jay St - MetroTech",
        Borough: "Bk",
        DaytimeRoutes: "A C F",
        Structure: "Subway",
        GTFSLatitude: "40.692338",
        GTFSLongitude: "-73.987342",
    },
    {
        StationID: "175",
        ComplexID: "175",
        GTFS_StopID: "A42",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "Hoyt - Schermerhorn Sts",
        Borough: "Bk",
        DaytimeRoutes: "A C G",
        Structure: "Subway",
        GTFSLatitude: "40.688484",
        GTFSLongitude: "-73.985001",
    },
    {
        StationID: "176",
        ComplexID: "176",
        GTFS_StopID: "A43",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "Lafayette Av",
        Borough: "Bk",
        DaytimeRoutes: "C",
        Structure: "Subway",
        GTFSLatitude: "40.686113",
        GTFSLongitude: "-73.973946",
    },
    {
        StationID: "177",
        ComplexID: "177",
        GTFS_StopID: "A44",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "Clinton - Washington Avs",
        Borough: "Bk",
        DaytimeRoutes: "C",
        Structure: "Subway",
        GTFSLatitude: "40.683263",
        GTFSLongitude: "-73.965838",
    },
    {
        StationID: "178",
        ComplexID: "627",
        GTFS_StopID: "A45",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "Franklin Av",
        Borough: "Bk",
        DaytimeRoutes: "C",
        Structure: "Subway",
        GTFSLatitude: "40.68138",
        GTFSLongitude: "-73.956848",
    },
    {
        StationID: "179",
        ComplexID: "179",
        GTFS_StopID: "A46",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "Nostrand Av",
        Borough: "Bk",
        DaytimeRoutes: "A C",
        Structure: "Subway",
        GTFSLatitude: "40.680438",
        GTFSLongitude: "-73.950426",
    },
    {
        StationID: "180",
        ComplexID: "180",
        GTFS_StopID: "A47",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "Kingston - Throop Avs",
        Borough: "Bk",
        DaytimeRoutes: "C",
        Structure: "Subway",
        GTFSLatitude: "40.679921",
        GTFSLongitude: "-73.940858",
    },
    {
        StationID: "181",
        ComplexID: "181",
        GTFS_StopID: "A48",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "Utica Av",
        Borough: "Bk",
        DaytimeRoutes: "A C",
        Structure: "Subway",
        GTFSLatitude: "40.679364",
        GTFSLongitude: "-73.930729",
    },
    {
        StationID: "182",
        ComplexID: "182",
        GTFS_StopID: "A49",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "Ralph Av",
        Borough: "Bk",
        DaytimeRoutes: "C",
        Structure: "Subway",
        GTFSLatitude: "40.678822",
        GTFSLongitude: "-73.920786",
    },
    {
        StationID: "183",
        ComplexID: "183",
        GTFS_StopID: "A50",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "Rockaway Av",
        Borough: "Bk",
        DaytimeRoutes: "C",
        Structure: "Subway",
        GTFSLatitude: "40.67834",
        GTFSLongitude: "-73.911946",
    },
    {
        StationID: "184",
        ComplexID: "621",
        GTFS_StopID: "A51",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "Broadway Jct",
        Borough: "Bk",
        DaytimeRoutes: "A C",
        Structure: "Subway",
        GTFSLatitude: "40.678334",
        GTFSLongitude: "-73.905316",
    },
    {
        StationID: "185",
        ComplexID: "185",
        GTFS_StopID: "A52",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "Liberty Av",
        Borough: "Bk",
        DaytimeRoutes: "C",
        Structure: "Subway",
        GTFSLatitude: "40.674542",
        GTFSLongitude: "-73.896548",
    },
    {
        StationID: "186",
        ComplexID: "186",
        GTFS_StopID: "A53",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "Van Siclen Av",
        Borough: "Bk",
        DaytimeRoutes: "C",
        Structure: "Subway",
        GTFSLatitude: "40.67271",
        GTFSLongitude: "-73.890358",
    },
    {
        StationID: "187",
        ComplexID: "187",
        GTFS_StopID: "A54",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "Shepherd Av",
        Borough: "Bk",
        DaytimeRoutes: "C",
        Structure: "Subway",
        GTFSLatitude: "40.67413",
        GTFSLongitude: "-73.88075",
    },
    {
        StationID: "188",
        ComplexID: "188",
        GTFS_StopID: "A55",
        Division: "IND",
        Line: "8th Av - Fulton St",
        StopName: "Euclid Av",
        Borough: "Bk",
        DaytimeRoutes: "A C",
        Structure: "Subway",
        GTFSLatitude: "40.675377",
        GTFSLongitude: "-73.872106",
    },
    {
        StationID: "189",
        ComplexID: "189",
        GTFS_StopID: "A57",
        Division: "IND",
        Line: "Liberty Av",
        StopName: "Grant Av",
        Borough: "Bk",
        DaytimeRoutes: "A",
        Structure: "Subway",
        GTFSLatitude: "40.677044",
        GTFSLongitude: "-73.86505",
    },
    {
        StationID: "190",
        ComplexID: "190",
        GTFS_StopID: "A59",
        Division: "IND",
        Line: "Liberty Av",
        StopName: "80 St",
        Borough: "Q",
        DaytimeRoutes: "A",
        Structure: "Elevated",
        GTFSLatitude: "40.679371",
        GTFSLongitude: "-73.858992",
    },
    {
        StationID: "191",
        ComplexID: "191",
        GTFS_StopID: "A60",
        Division: "IND",
        Line: "Liberty Av",
        StopName: "88 St",
        Borough: "Q",
        DaytimeRoutes: "A",
        Structure: "Elevated",
        GTFSLatitude: "40.679843",
        GTFSLongitude: "-73.85147",
    },
    {
        StationID: "192",
        ComplexID: "192",
        GTFS_StopID: "A61",
        Division: "IND",
        Line: "Liberty Av",
        StopName: "Rockaway Blvd",
        Borough: "Q",
        DaytimeRoutes: "A",
        Structure: "Elevated",
        GTFSLatitude: "40.680429",
        GTFSLongitude: "-73.843853",
    },
    {
        StationID: "193",
        ComplexID: "193",
        GTFS_StopID: "A63",
        Division: "IND",
        Line: "Liberty Av",
        StopName: "104 St",
        Borough: "Q",
        DaytimeRoutes: "A",
        Structure: "Elevated",
        GTFSLatitude: "40.681711",
        GTFSLongitude: "-73.837683",
    },
    {
        StationID: "194",
        ComplexID: "194",
        GTFS_StopID: "A64",
        Division: "IND",
        Line: "Liberty Av",
        StopName: "111 St",
        Borough: "Q",
        DaytimeRoutes: "A",
        Structure: "Elevated",
        GTFSLatitude: "40.684331",
        GTFSLongitude: "-73.832163",
    },
    {
        StationID: "195",
        ComplexID: "195",
        GTFS_StopID: "A65",
        Division: "IND",
        Line: "Liberty Av",
        StopName: "Ozone Park - Lefferts Blvd",
        Borough: "Q",
        DaytimeRoutes: "A",
        Structure: "Elevated",
        GTFSLatitude: "40.685951",
        GTFSLongitude: "-73.825798",
    },
    {
        StationID: "196",
        ComplexID: "196",
        GTFS_StopID: "H01",
        Division: "IND",
        Line: "Rockaway",
        StopName: "Aqueduct Racetrack",
        Borough: "Q",
        DaytimeRoutes: "A",
        Structure: "At Grade",
        GTFSLatitude: "40.672097",
        GTFSLongitude: "-73.835919",
    },
    {
        StationID: "197",
        ComplexID: "197",
        GTFS_StopID: "H02",
        Division: "IND",
        Line: "Rockaway",
        StopName: "Aqueduct - N Conduit Av",
        Borough: "Q",
        DaytimeRoutes: "A",
        Structure: "At Grade",
        GTFSLatitude: "40.668234",
        GTFSLongitude: "-73.834058",
    },
    {
        StationID: "198",
        ComplexID: "198",
        GTFS_StopID: "H03",
        Division: "IND",
        Line: "Rockaway",
        StopName: "Howard Beach - JFK Airport",
        Borough: "Q",
        DaytimeRoutes: "A",
        Structure: "At Grade",
        GTFSLatitude: "40.660476",
        GTFSLongitude: "-73.830301",
    },
    {
        StationID: "199",
        ComplexID: "199",
        GTFS_StopID: "H04",
        Division: "IND",
        Line: "Rockaway",
        StopName: "Broad Channel",
        Borough: "Q",
        DaytimeRoutes: "A H",
        Structure: "At Grade",
        GTFSLatitude: "40.608382",
        GTFSLongitude: "-73.815925",
    },
    {
        StationID: "199",
        ComplexID: "199",
        GTFS_StopID: "H19",
        Division: "IND",
        Line: "Rockaway",
        StopName: "Broad Channel",
        Borough: "Q",
        DaytimeRoutes: "A H",
        Structure: "At Grade",
        GTFSLatitude: "40.608382",
        GTFSLongitude: "-73.815925",
    },
    {
        StationID: "200",
        ComplexID: "200",
        GTFS_StopID: "H12",
        Division: "IND",
        Line: "Rockaway",
        StopName: "Beach 90 St",
        Borough: "Q",
        DaytimeRoutes: "A H",
        Structure: "Viaduct",
        GTFSLatitude: "40.588034",
        GTFSLongitude: "-73.813641",
    },
    {
        StationID: "201",
        ComplexID: "201",
        GTFS_StopID: "H13",
        Division: "IND",
        Line: "Rockaway",
        StopName: "Beach 98 St",
        Borough: "Q",
        DaytimeRoutes: "A H",
        Structure: "Viaduct",
        GTFSLatitude: "40.585307",
        GTFSLongitude: "-73.820558",
    },
    {
        StationID: "202",
        ComplexID: "202",
        GTFS_StopID: "H14",
        Division: "IND",
        Line: "Rockaway",
        StopName: "Beach 105 St",
        Borough: "Q",
        DaytimeRoutes: "A H",
        Structure: "Viaduct",
        GTFSLatitude: "40.583209",
        GTFSLongitude: "-73.827559",
    },
    {
        StationID: "203",
        ComplexID: "203",
        GTFS_StopID: "H15",
        Division: "IND",
        Line: "Rockaway",
        StopName: "Rockaway Park - Beach 116 St",
        Borough: "Q",
        DaytimeRoutes: "A H",
        Structure: "At Grade",
        GTFSLatitude: "40.580903",
        GTFSLongitude: "-73.835592",
    },
    {
        StationID: "204",
        ComplexID: "204",
        GTFS_StopID: "H06",
        Division: "IND",
        Line: "Rockaway",
        StopName: "Beach 67 St",
        Borough: "Q",
        DaytimeRoutes: "A",
        Structure: "Viaduct",
        GTFSLatitude: "40.590927",
        GTFSLongitude: "-73.796924",
    },
    {
        StationID: "205",
        ComplexID: "205",
        GTFS_StopID: "H07",
        Division: "IND",
        Line: "Rockaway",
        StopName: "Beach 60 St",
        Borough: "Q",
        DaytimeRoutes: "A",
        Structure: "Viaduct",
        GTFSLatitude: "40.592374",
        GTFSLongitude: "-73.788522",
    },
    {
        StationID: "206",
        ComplexID: "206",
        GTFS_StopID: "H08",
        Division: "IND",
        Line: "Rockaway",
        StopName: "Beach 44 St",
        Borough: "Q",
        DaytimeRoutes: "A",
        Structure: "Viaduct",
        GTFSLatitude: "40.592943",
        GTFSLongitude: "-73.776013",
    },
    {
        StationID: "207",
        ComplexID: "207",
        GTFS_StopID: "H09",
        Division: "IND",
        Line: "Rockaway",
        StopName: "Beach 36 St",
        Borough: "Q",
        DaytimeRoutes: "A",
        Structure: "Viaduct",
        GTFSLatitude: "40.595398",
        GTFSLongitude: "-73.768175",
    },
    {
        StationID: "208",
        ComplexID: "208",
        GTFS_StopID: "H10",
        Division: "IND",
        Line: "Rockaway",
        StopName: "Beach 25 St",
        Borough: "Q",
        DaytimeRoutes: "A",
        Structure: "Viaduct",
        GTFSLatitude: "40.600066",
        GTFSLongitude: "-73.761353",
    },
    {
        StationID: "209",
        ComplexID: "209",
        GTFS_StopID: "H11",
        Division: "IND",
        Line: "Rockaway",
        StopName: "Far Rockaway - Mott Av",
        Borough: "Q",
        DaytimeRoutes: "A",
        Structure: "Viaduct",
        GTFSLatitude: "40.603995",
        GTFSLongitude: "-73.755405",
    },
    {
        StationID: "210",
        ComplexID: "210",
        GTFS_StopID: "D01",
        Division: "IND",
        Line: "Concourse",
        StopName: "Norwood - 205 St",
        Borough: "Bx",
        DaytimeRoutes: "D",
        Structure: "Subway",
        GTFSLatitude: "40.874811",
        GTFSLongitude: "-73.878855",
    },
    {
        StationID: "211",
        ComplexID: "211",
        GTFS_StopID: "D03",
        Division: "IND",
        Line: "Concourse",
        StopName: "Bedford Park Blvd",
        Borough: "Bx",
        DaytimeRoutes: "B D",
        Structure: "Subway",
        GTFSLatitude: "40.873244",
        GTFSLongitude: "-73.887138",
    },
    {
        StationID: "212",
        ComplexID: "212",
        GTFS_StopID: "D04",
        Division: "IND",
        Line: "Concourse",
        StopName: "Kingsbridge Rd",
        Borough: "Bx",
        DaytimeRoutes: "B D",
        Structure: "Subway",
        GTFSLatitude: "40.866978",
        GTFSLongitude: "-73.893509",
    },
    {
        StationID: "213",
        ComplexID: "213",
        GTFS_StopID: "D05",
        Division: "IND",
        Line: "Concourse",
        StopName: "Fordham Rd",
        Borough: "Bx",
        DaytimeRoutes: "B D",
        Structure: "Subway",
        GTFSLatitude: "40.861296",
        GTFSLongitude: "-73.897749",
    },
    {
        StationID: "214",
        ComplexID: "214",
        GTFS_StopID: "D06",
        Division: "IND",
        Line: "Concourse",
        StopName: "182-183 Sts",
        Borough: "Bx",
        DaytimeRoutes: "B D",
        Structure: "Subway",
        GTFSLatitude: "40.856093",
        GTFSLongitude: "-73.900741",
    },
    {
        StationID: "215",
        ComplexID: "215",
        GTFS_StopID: "D07",
        Division: "IND",
        Line: "Concourse",
        StopName: "Tremont Av",
        Borough: "Bx",
        DaytimeRoutes: "B D",
        Structure: "Subway",
        GTFSLatitude: "40.85041",
        GTFSLongitude: "-73.905227",
    },
    {
        StationID: "216",
        ComplexID: "216",
        GTFS_StopID: "D08",
        Division: "IND",
        Line: "Concourse",
        StopName: "174-175 Sts",
        Borough: "Bx",
        DaytimeRoutes: "B D",
        Structure: "Subway",
        GTFSLatitude: "40.8459",
        GTFSLongitude: "-73.910136",
    },
    {
        StationID: "217",
        ComplexID: "217",
        GTFS_StopID: "D09",
        Division: "IND",
        Line: "Concourse",
        StopName: "170 St",
        Borough: "Bx",
        DaytimeRoutes: "B D",
        Structure: "Subway",
        GTFSLatitude: "40.839306",
        GTFSLongitude: "-73.9134",
    },
    {
        StationID: "218",
        ComplexID: "218",
        GTFS_StopID: "D10",
        Division: "IND",
        Line: "Concourse",
        StopName: "167 St",
        Borough: "Bx",
        DaytimeRoutes: "B D",
        Structure: "Subway",
        GTFSLatitude: "40.833771",
        GTFSLongitude: "-73.91844",
    },
    {
        StationID: "219",
        ComplexID: "604",
        GTFS_StopID: "D11",
        Division: "IND",
        Line: "Concourse",
        StopName: "161 St - Yankee Stadium",
        Borough: "Bx",
        DaytimeRoutes: "B D",
        Structure: "Subway",
        GTFSLatitude: "40.827905",
        GTFSLongitude: "-73.925651",
    },
    {
        StationID: "220",
        ComplexID: "220",
        GTFS_StopID: "D12",
        Division: "IND",
        Line: "Concourse",
        StopName: "155 St",
        Borough: "M",
        DaytimeRoutes: "B D",
        Structure: "Subway",
        GTFSLatitude: "40.830135",
        GTFSLongitude: "-73.938209",
    },
    {
        StationID: "221",
        ComplexID: "221",
        GTFS_StopID: "B04",
        Division: "IND",
        Line: "63rd St",
        StopName: "21 St - Queensbridge",
        Borough: "Q",
        DaytimeRoutes: "F",
        Structure: "Subway",
        GTFSLatitude: "40.754203",
        GTFSLongitude: "-73.942836",
    },
    {
        StationID: "222",
        ComplexID: "222",
        GTFS_StopID: "B06",
        Division: "IND",
        Line: "63rd St",
        StopName: "Roosevelt Island",
        Borough: "M",
        DaytimeRoutes: "F",
        Structure: "Subway",
        GTFSLatitude: "40.759145",
        GTFSLongitude: "-73.95326",
    },
    {
        StationID: "223",
        ComplexID: "223",
        GTFS_StopID: "B08",
        Division: "IND",
        Line: "63rd St",
        StopName: "Lexington Av/63 St",
        Borough: "M",
        DaytimeRoutes: "F Q",
        Structure: "Subway",
        GTFSLatitude: "40.764629",
        GTFSLongitude: "-73.966113",
    },
    {
        StationID: "224",
        ComplexID: "224",
        GTFS_StopID: "B10",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "57 St",
        Borough: "M",
        DaytimeRoutes: "F",
        Structure: "Subway",
        GTFSLatitude: "40.763972",
        GTFSLongitude: "-73.97745",
    },
    {
        StationID: "225",
        ComplexID: "225",
        GTFS_StopID: "D15",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "47-50 Sts - Rockefeller Ctr",
        Borough: "M",
        DaytimeRoutes: "B D F M",
        Structure: "Subway",
        GTFSLatitude: "40.758663",
        GTFSLongitude: "-73.981329",
    },
    {
        StationID: "226",
        ComplexID: "609",
        GTFS_StopID: "D16",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "42 St - Bryant Pk",
        Borough: "M",
        DaytimeRoutes: "B D F M",
        Structure: "Subway",
        GTFSLatitude: "40.754222",
        GTFSLongitude: "-73.984569",
    },
    {
        StationID: "227",
        ComplexID: "607",
        GTFS_StopID: "D17",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "34 St - Herald Sq",
        Borough: "M",
        DaytimeRoutes: "B D F M",
        Structure: "Subway",
        GTFSLatitude: "40.749719",
        GTFSLongitude: "-73.987823",
    },
    {
        StationID: "228",
        ComplexID: "228",
        GTFS_StopID: "D18",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "23 St",
        Borough: "M",
        DaytimeRoutes: "F M",
        Structure: "Subway",
        GTFSLatitude: "40.742878",
        GTFSLongitude: "-73.992821",
    },
    {
        StationID: "229",
        ComplexID: "601",
        GTFS_StopID: "D19",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "14 St",
        Borough: "M",
        DaytimeRoutes: "F M",
        Structure: "Subway",
        GTFSLatitude: "40.738228",
        GTFSLongitude: "-73.996209",
    },
    {
        StationID: "230",
        ComplexID: "619",
        GTFS_StopID: "D21",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "Broadway-Lafayette St",
        Borough: "M",
        DaytimeRoutes: "B D F M",
        Structure: "Subway",
        GTFSLatitude: "40.725297",
        GTFSLongitude: "-73.996204",
    },
    {
        StationID: "231",
        ComplexID: "231",
        GTFS_StopID: "D22",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "Grand St",
        Borough: "M",
        DaytimeRoutes: "B D",
        Structure: "Subway",
        GTFSLatitude: "40.718267",
        GTFSLongitude: "-73.993753",
    },
    {
        StationID: "232",
        ComplexID: "232",
        GTFS_StopID: "F14",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "2 Av",
        Borough: "M",
        DaytimeRoutes: "F",
        Structure: "Subway",
        GTFSLatitude: "40.723402",
        GTFSLongitude: "-73.989938",
    },
    {
        StationID: "233",
        ComplexID: "625",
        GTFS_StopID: "F15",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "Delancey St",
        Borough: "M",
        DaytimeRoutes: "F",
        Structure: "Subway",
        GTFSLatitude: "40.718611",
        GTFSLongitude: "-73.988114",
    },
    {
        StationID: "234",
        ComplexID: "234",
        GTFS_StopID: "F16",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "East Broadway",
        Borough: "M",
        DaytimeRoutes: "F",
        Structure: "Subway",
        GTFSLatitude: "40.713715",
        GTFSLongitude: "-73.990173",
    },
    {
        StationID: "235",
        ComplexID: "235",
        GTFS_StopID: "F18",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "York St",
        Borough: "Bk",
        DaytimeRoutes: "F",
        Structure: "Subway",
        GTFSLatitude: "40.701397",
        GTFSLongitude: "-73.986751",
    },
    {
        StationID: "236",
        ComplexID: "236",
        GTFS_StopID: "F20",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "Bergen St",
        Borough: "Bk",
        DaytimeRoutes: "F G",
        Structure: "Subway",
        GTFSLatitude: "40.686145",
        GTFSLongitude: "-73.990862",
    },
    {
        StationID: "237",
        ComplexID: "237",
        GTFS_StopID: "F21",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "Carroll St",
        Borough: "Bk",
        DaytimeRoutes: "F G",
        Structure: "Subway",
        GTFSLatitude: "40.680303",
        GTFSLongitude: "-73.995048",
    },
    {
        StationID: "238",
        ComplexID: "238",
        GTFS_StopID: "F22",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "Smith - 9 Sts",
        Borough: "Bk",
        DaytimeRoutes: "F G",
        Structure: "Viaduct",
        GTFSLatitude: "40.67358",
        GTFSLongitude: "-73.995959",
    },
    {
        StationID: "239",
        ComplexID: "608",
        GTFS_StopID: "F23",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "4 Av",
        Borough: "Bk",
        DaytimeRoutes: "F G",
        Structure: "Viaduct",
        GTFSLatitude: "40.670272",
        GTFSLongitude: "-73.989779",
    },
    {
        StationID: "240",
        ComplexID: "240",
        GTFS_StopID: "F24",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "7 Av",
        Borough: "Bk",
        DaytimeRoutes: "F G",
        Structure: "Subway",
        GTFSLatitude: "40.666271",
        GTFSLongitude: "-73.980305",
    },
    {
        StationID: "241",
        ComplexID: "241",
        GTFS_StopID: "F25",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "15 St - Prospect Park",
        Borough: "Bk",
        DaytimeRoutes: "F G",
        Structure: "Subway",
        GTFSLatitude: "40.660365",
        GTFSLongitude: "-73.979493",
    },
    {
        StationID: "242",
        ComplexID: "242",
        GTFS_StopID: "F26",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "Fort Hamilton Pkwy",
        Borough: "Bk",
        DaytimeRoutes: "F G",
        Structure: "Subway",
        GTFSLatitude: "40.650782",
        GTFSLongitude: "-73.975776",
    },
    {
        StationID: "243",
        ComplexID: "243",
        GTFS_StopID: "F27",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "Church Av",
        Borough: "Bk",
        DaytimeRoutes: "F",
        Structure: "Subway",
        GTFSLatitude: "40.644041",
        GTFSLongitude: "-73.979678",
    },
    {
        StationID: "244",
        ComplexID: "244",
        GTFS_StopID: "F29",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "Ditmas Av",
        Borough: "Bk",
        DaytimeRoutes: "F",
        Structure: "Elevated",
        GTFSLatitude: "40.636119",
        GTFSLongitude: "-73.978172",
    },
    {
        StationID: "245",
        ComplexID: "245",
        GTFS_StopID: "F30",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "18 Av",
        Borough: "Bk",
        DaytimeRoutes: "F",
        Structure: "Elevated",
        GTFSLatitude: "40.629755",
        GTFSLongitude: "-73.976971",
    },
    {
        StationID: "246",
        ComplexID: "246",
        GTFS_StopID: "F31",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "Avenue I",
        Borough: "Bk",
        DaytimeRoutes: "F",
        Structure: "Elevated",
        GTFSLatitude: "40.625322",
        GTFSLongitude: "-73.976127",
    },
    {
        StationID: "247",
        ComplexID: "247",
        GTFS_StopID: "F32",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "Bay Pkwy",
        Borough: "Bk",
        DaytimeRoutes: "F",
        Structure: "Elevated",
        GTFSLatitude: "40.620769",
        GTFSLongitude: "-73.975264",
    },
    {
        StationID: "248",
        ComplexID: "248",
        GTFS_StopID: "F33",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "Avenue N",
        Borough: "Bk",
        DaytimeRoutes: "F",
        Structure: "Elevated",
        GTFSLatitude: "40.61514",
        GTFSLongitude: "-73.974197",
    },
    {
        StationID: "249",
        ComplexID: "249",
        GTFS_StopID: "F34",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "Avenue P",
        Borough: "Bk",
        DaytimeRoutes: "F",
        Structure: "Elevated",
        GTFSLatitude: "40.608944",
        GTFSLongitude: "-73.973022",
    },
    {
        StationID: "250",
        ComplexID: "250",
        GTFS_StopID: "F35",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "Kings Hwy",
        Borough: "Bk",
        DaytimeRoutes: "F",
        Structure: "Elevated",
        GTFSLatitude: "40.603217",
        GTFSLongitude: "-73.972361",
    },
    {
        StationID: "251",
        ComplexID: "251",
        GTFS_StopID: "F36",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "Avenue U",
        Borough: "Bk",
        DaytimeRoutes: "F",
        Structure: "Elevated",
        GTFSLatitude: "40.596063",
        GTFSLongitude: "-73.973357",
    },
    {
        StationID: "252",
        ComplexID: "252",
        GTFS_StopID: "F38",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "Avenue X",
        Borough: "Bk",
        DaytimeRoutes: "F",
        Structure: "Elevated",
        GTFSLatitude: "40.58962",
        GTFSLongitude: "-73.97425",
    },
    {
        StationID: "253",
        ComplexID: "253",
        GTFS_StopID: "F39",
        Division: "IND",
        Line: "6th Av - Culver",
        StopName: "Neptune Av",
        Borough: "Bk",
        DaytimeRoutes: "F",
        Structure: "Elevated",
        GTFSLatitude: "40.581011",
        GTFSLongitude: "-73.974574",
    },
    {
        StationID: "254",
        ComplexID: "254",
        GTFS_StopID: "F01",
        Division: "IND",
        Line: "Queens Blvd",
        StopName: "Jamaica - 179 St",
        Borough: "Q",
        DaytimeRoutes: "F",
        Structure: "Subway",
        GTFSLatitude: "40.712646",
        GTFSLongitude: "-73.783817",
    },
    {
        StationID: "255",
        ComplexID: "255",
        GTFS_StopID: "F02",
        Division: "IND",
        Line: "Queens Blvd",
        StopName: "169 St",
        Borough: "Q",
        DaytimeRoutes: "F",
        Structure: "Subway",
        GTFSLatitude: "40.71047",
        GTFSLongitude: "-73.793604",
    },
    {
        StationID: "256",
        ComplexID: "256",
        GTFS_StopID: "F03",
        Division: "IND",
        Line: "Queens Blvd",
        StopName: "Parsons Blvd",
        Borough: "Q",
        DaytimeRoutes: "F",
        Structure: "Subway",
        GTFSLatitude: "40.707564",
        GTFSLongitude: "-73.803326",
    },
    {
        StationID: "257",
        ComplexID: "257",
        GTFS_StopID: "F04",
        Division: "IND",
        Line: "Queens Blvd",
        StopName: "Sutphin Blvd",
        Borough: "Q",
        DaytimeRoutes: "F",
        Structure: "Subway",
        GTFSLatitude: "40.70546",
        GTFSLongitude: "-73.810708",
    },
    {
        StationID: "258",
        ComplexID: "258",
        GTFS_StopID: "F05",
        Division: "IND",
        Line: "Queens Blvd",
        StopName: "Briarwood - Van Wyck Blvd",
        Borough: "Q",
        DaytimeRoutes: "E F",
        Structure: "Subway",
        GTFSLatitude: "40.709179",
        GTFSLongitude: "-73.820574",
    },
    {
        StationID: "259",
        ComplexID: "259",
        GTFS_StopID: "F06",
        Division: "IND",
        Line: "Queens Blvd",
        StopName: "Kew Gardens - Union Tpke",
        Borough: "Q",
        DaytimeRoutes: "E F",
        Structure: "Subway",
        GTFSLatitude: "40.714441",
        GTFSLongitude: "-73.831008",
    },
    {
        StationID: "260",
        ComplexID: "260",
        GTFS_StopID: "F07",
        Division: "IND",
        Line: "Queens Blvd",
        StopName: "75 Av",
        Borough: "Q",
        DaytimeRoutes: "E F",
        Structure: "Subway",
        GTFSLatitude: "40.718331",
        GTFSLongitude: "-73.837324",
    },
    {
        StationID: "261",
        ComplexID: "261",
        GTFS_StopID: "G08",
        Division: "IND",
        Line: "Queens Blvd",
        StopName: "Forest Hills - 71 Av",
        Borough: "Q",
        DaytimeRoutes: "E F M R",
        Structure: "Subway",
        GTFSLatitude: "40.721691",
        GTFSLongitude: "-73.844521",
    },
    {
        StationID: "262",
        ComplexID: "262",
        GTFS_StopID: "G09",
        Division: "IND",
        Line: "Queens Blvd",
        StopName: "67 Av",
        Borough: "Q",
        DaytimeRoutes: "M R",
        Structure: "Subway",
        GTFSLatitude: "40.726523",
        GTFSLongitude: "-73.852719",
    },
    {
        StationID: "263",
        ComplexID: "263",
        GTFS_StopID: "G10",
        Division: "IND",
        Line: "Queens Blvd",
        StopName: "63 Dr - Rego Park",
        Borough: "Q",
        DaytimeRoutes: "M R",
        Structure: "Subway",
        GTFSLatitude: "40.729846",
        GTFSLongitude: "-73.861604",
    },
    {
        StationID: "264",
        ComplexID: "264",
        GTFS_StopID: "G11",
        Division: "IND",
        Line: "Queens Blvd",
        StopName: "Woodhaven Blvd",
        Borough: "Q",
        DaytimeRoutes: "M R",
        Structure: "Subway",
        GTFSLatitude: "40.733106",
        GTFSLongitude: "-73.869229",
    },
    {
        StationID: "265",
        ComplexID: "265",
        GTFS_StopID: "G12",
        Division: "IND",
        Line: "Queens Blvd",
        StopName: "Grand Av - Newtown",
        Borough: "Q",
        DaytimeRoutes: "M R",
        Structure: "Subway",
        GTFSLatitude: "40.737015",
        GTFSLongitude: "-73.877223",
    },
    {
        StationID: "266",
        ComplexID: "266",
        GTFS_StopID: "G13",
        Division: "IND",
        Line: "Queens Blvd",
        StopName: "Elmhurst Av",
        Borough: "Q",
        DaytimeRoutes: "M R",
        Structure: "Subway",
        GTFSLatitude: "40.742454",
        GTFSLongitude: "-73.882017",
    },
    {
        StationID: "267",
        ComplexID: "616",
        GTFS_StopID: "G14",
        Division: "IND",
        Line: "Queens Blvd",
        StopName: "Jackson Hts - Roosevelt Av",
        Borough: "Q",
        DaytimeRoutes: "E F M R",
        Structure: "Subway",
        GTFSLatitude: "40.746644",
        GTFSLongitude: "-73.891338",
    },
    {
        StationID: "268",
        ComplexID: "268",
        GTFS_StopID: "G15",
        Division: "IND",
        Line: "Queens Blvd",
        StopName: "65 St",
        Borough: "Q",
        DaytimeRoutes: "M R",
        Structure: "Subway",
        GTFSLatitude: "40.749669",
        GTFSLongitude: "-73.898453",
    },
    {
        StationID: "269",
        ComplexID: "269",
        GTFS_StopID: "G16",
        Division: "IND",
        Line: "Queens Blvd",
        StopName: "Northern Blvd",
        Borough: "Q",
        DaytimeRoutes: "M R",
        Structure: "Subway",
        GTFSLatitude: "40.752885",
        GTFSLongitude: "-73.906006",
    },
    {
        StationID: "270",
        ComplexID: "270",
        GTFS_StopID: "G18",
        Division: "IND",
        Line: "Queens Blvd",
        StopName: "46 St",
        Borough: "Q",
        DaytimeRoutes: "M R",
        Structure: "Subway",
        GTFSLatitude: "40.756312",
        GTFSLongitude: "-73.913333",
    },
    {
        StationID: "271",
        ComplexID: "271",
        GTFS_StopID: "G19",
        Division: "IND",
        Line: "Queens Blvd",
        StopName: "Steinway St",
        Borough: "Q",
        DaytimeRoutes: "M R",
        Structure: "Subway",
        GTFSLatitude: "40.756879",
        GTFSLongitude: "-73.92074",
    },
    {
        StationID: "272",
        ComplexID: "272",
        GTFS_StopID: "G20",
        Division: "IND",
        Line: "Queens Blvd",
        StopName: "36 St",
        Borough: "Q",
        DaytimeRoutes: "M R",
        Structure: "Subway",
        GTFSLatitude: "40.752039",
        GTFSLongitude: "-73.928781",
    },
    {
        StationID: "273",
        ComplexID: "273",
        GTFS_StopID: "G21",
        Division: "IND",
        Line: "Queens Blvd",
        StopName: "Queens Plaza",
        Borough: "Q",
        DaytimeRoutes: "E M R",
        Structure: "Subway",
        GTFSLatitude: "40.748973",
        GTFSLongitude: "-73.937243",
    },
    {
        StationID: "274",
        ComplexID: "606",
        GTFS_StopID: "F09",
        Division: "IND",
        Line: "Queens Blvd",
        StopName: "Court Sq",
        Borough: "Q",
        DaytimeRoutes: "E M",
        Structure: "Subway",
        GTFSLatitude: "40.747846",
        GTFSLongitude: "-73.946",
    },
    {
        StationID: "275",
        ComplexID: "612",
        GTFS_StopID: "F11",
        Division: "IND",
        Line: "Queens Blvd",
        StopName: "Lexington Av/53 St",
        Borough: "M",
        DaytimeRoutes: "E M",
        Structure: "Subway",
        GTFSLatitude: "40.757552",
        GTFSLongitude: "-73.969055",
    },
    {
        StationID: "276",
        ComplexID: "276",
        GTFS_StopID: "F12",
        Division: "IND",
        Line: "Queens Blvd",
        StopName: "5 Av/53 St",
        Borough: "M",
        DaytimeRoutes: "E M",
        Structure: "Subway",
        GTFSLatitude: "40.760167",
        GTFSLongitude: "-73.975224",
    },
    {
        StationID: "277",
        ComplexID: "277",
        GTFS_StopID: "D14",
        Division: "IND",
        Line: "Queens Blvd",
        StopName: "7 Av",
        Borough: "M",
        DaytimeRoutes: "B D E",
        Structure: "Subway",
        GTFSLatitude: "40.762862",
        GTFSLongitude: "-73.981637",
    },
    {
        StationID: "278",
        ComplexID: "278",
        GTFS_StopID: "G05",
        Division: "IND",
        Line: "Queens - Archer",
        StopName: "Jamaica Center - Parsons/Archer",
        Borough: "Q",
        DaytimeRoutes: "E J Z",
        Structure: "Subway",
        GTFSLatitude: "40.702147",
        GTFSLongitude: "-73.801109",
    },
    {
        StationID: "279",
        ComplexID: "279",
        GTFS_StopID: "G06",
        Division: "IND",
        Line: "Queens - Archer",
        StopName: "Sutphin Blvd - Archer Av - JFK Airport",
        Borough: "Q",
        DaytimeRoutes: "E J Z",
        Structure: "Subway",
        GTFSLatitude: "40.700486",
        GTFSLongitude: "-73.807969",
    },
    {
        StationID: "280",
        ComplexID: "280",
        GTFS_StopID: "G07",
        Division: "IND",
        Line: "Queens - Archer",
        StopName: "Jamaica - Van Wyck",
        Borough: "Q",
        DaytimeRoutes: "E",
        Structure: "Subway",
        GTFSLatitude: "40.702566",
        GTFSLongitude: "-73.816859",
    },
    {
        StationID: "281",
        ComplexID: "606",
        GTFS_StopID: "G22",
        Division: "IND",
        Line: "Crosstown",
        StopName: "Court Sq",
        Borough: "Q",
        DaytimeRoutes: "G",
        Structure: "Subway",
        GTFSLatitude: "40.746554",
        GTFSLongitude: "-73.943832",
    },
    {
        StationID: "282",
        ComplexID: "282",
        GTFS_StopID: "G24",
        Division: "IND",
        Line: "Crosstown",
        StopName: "21 St",
        Borough: "Q",
        DaytimeRoutes: "G",
        Structure: "Subway",
        GTFSLatitude: "40.744065",
        GTFSLongitude: "-73.949724",
    },
    {
        StationID: "283",
        ComplexID: "283",
        GTFS_StopID: "G26",
        Division: "IND",
        Line: "Crosstown",
        StopName: "Greenpoint Av",
        Borough: "Bk",
        DaytimeRoutes: "G",
        Structure: "Subway",
        GTFSLatitude: "40.731352",
        GTFSLongitude: "-73.954449",
    },
    {
        StationID: "284",
        ComplexID: "284",
        GTFS_StopID: "G28",
        Division: "IND",
        Line: "Crosstown",
        StopName: "Nassau Av",
        Borough: "Bk",
        DaytimeRoutes: "G",
        Structure: "Subway",
        GTFSLatitude: "40.724635",
        GTFSLongitude: "-73.951277",
    },
    {
        StationID: "285",
        ComplexID: "629",
        GTFS_StopID: "G29",
        Division: "IND",
        Line: "Crosstown",
        StopName: "Metropolitan Av",
        Borough: "Bk",
        DaytimeRoutes: "G",
        Structure: "Subway",
        GTFSLatitude: "40.712792",
        GTFSLongitude: "-73.951418",
    },
    {
        StationID: "286",
        ComplexID: "286",
        GTFS_StopID: "G30",
        Division: "IND",
        Line: "Crosstown",
        StopName: "Broadway",
        Borough: "Bk",
        DaytimeRoutes: "G",
        Structure: "Subway",
        GTFSLatitude: "40.706092",
        GTFSLongitude: "-73.950308",
    },
    {
        StationID: "287",
        ComplexID: "287",
        GTFS_StopID: "G31",
        Division: "IND",
        Line: "Crosstown",
        StopName: "Flushing Av",
        Borough: "Bk",
        DaytimeRoutes: "G",
        Structure: "Subway",
        GTFSLatitude: "40.700377",
        GTFSLongitude: "-73.950234",
    },
    {
        StationID: "288",
        ComplexID: "288",
        GTFS_StopID: "G32",
        Division: "IND",
        Line: "Crosstown",
        StopName: "Myrtle - Willoughby Avs",
        Borough: "Bk",
        DaytimeRoutes: "G",
        Structure: "Subway",
        GTFSLatitude: "40.694568",
        GTFSLongitude: "-73.949046",
    },
    {
        StationID: "289",
        ComplexID: "289",
        GTFS_StopID: "G33",
        Division: "IND",
        Line: "Crosstown",
        StopName: "Bedford - Nostrand Avs",
        Borough: "Bk",
        DaytimeRoutes: "G",
        Structure: "Subway",
        GTFSLatitude: "40.689627",
        GTFSLongitude: "-73.953522",
    },
    {
        StationID: "290",
        ComplexID: "290",
        GTFS_StopID: "G34",
        Division: "IND",
        Line: "Crosstown",
        StopName: "Classon Av",
        Borough: "Bk",
        DaytimeRoutes: "G",
        Structure: "Subway",
        GTFSLatitude: "40.688873",
        GTFSLongitude: "-73.96007",
    },
    {
        StationID: "291",
        ComplexID: "291",
        GTFS_StopID: "G35",
        Division: "IND",
        Line: "Crosstown",
        StopName: "Clinton - Washington Avs",
        Borough: "Bk",
        DaytimeRoutes: "G",
        Structure: "Subway",
        GTFSLatitude: "40.688089",
        GTFSLongitude: "-73.966839",
    },
    {
        StationID: "292",
        ComplexID: "292",
        GTFS_StopID: "G36",
        Division: "IND",
        Line: "Crosstown",
        StopName: "Fulton St",
        Borough: "Bk",
        DaytimeRoutes: "G",
        Structure: "Subway",
        GTFSLatitude: "40.687119",
        GTFSLongitude: "-73.975375",
    },
    {
        StationID: "293",
        ComplexID: "293",
        GTFS_StopID: "101",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "Van Cortlandt Park - 242 St",
        Borough: "Bx",
        DaytimeRoutes: "1",
        Structure: "Elevated",
        GTFSLatitude: "40.889248",
        GTFSLongitude: "-73.898583",
    },
    {
        StationID: "294",
        ComplexID: "294",
        GTFS_StopID: "103",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "238 St",
        Borough: "Bx",
        DaytimeRoutes: "1",
        Structure: "Elevated",
        GTFSLatitude: "40.884667",
        GTFSLongitude: "-73.90087",
    },
    {
        StationID: "295",
        ComplexID: "295",
        GTFS_StopID: "104",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "231 St",
        Borough: "Bx",
        DaytimeRoutes: "1",
        Structure: "Elevated",
        GTFSLatitude: "40.878856",
        GTFSLongitude: "-73.904834",
    },
    {
        StationID: "296",
        ComplexID: "296",
        GTFS_StopID: "106",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "Marble Hill - 225 St",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Elevated",
        GTFSLatitude: "40.874561",
        GTFSLongitude: "-73.909831",
    },
    {
        StationID: "297",
        ComplexID: "297",
        GTFS_StopID: "107",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "215 St",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Elevated",
        GTFSLatitude: "40.869444",
        GTFSLongitude: "-73.915279",
    },
    {
        StationID: "298",
        ComplexID: "298",
        GTFS_StopID: "108",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "207 St",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Elevated",
        GTFSLatitude: "40.864621",
        GTFSLongitude: "-73.918822",
    },
    {
        StationID: "299",
        ComplexID: "299",
        GTFS_StopID: "109",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "Dyckman St",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Elevated",
        GTFSLatitude: "40.860531",
        GTFSLongitude: "-73.925536",
    },
    {
        StationID: "300",
        ComplexID: "300",
        GTFS_StopID: "110",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "191 St",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Subway",
        GTFSLatitude: "40.855225",
        GTFSLongitude: "-73.929412",
    },
    {
        StationID: "301",
        ComplexID: "301",
        GTFS_StopID: "111",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "181 St",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Subway",
        GTFSLatitude: "40.849505",
        GTFSLongitude: "-73.933596",
    },
    {
        StationID: "302",
        ComplexID: "605",
        GTFS_StopID: "112",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "168 St - Washington Hts",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Subway",
        GTFSLatitude: "40.840556",
        GTFSLongitude: "-73.940133",
    },
    {
        StationID: "303",
        ComplexID: "303",
        GTFS_StopID: "113",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "157 St",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Subway",
        GTFSLatitude: "40.834041",
        GTFSLongitude: "-73.94489",
    },
    {
        StationID: "304",
        ComplexID: "304",
        GTFS_StopID: "114",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "145 St",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Subway",
        GTFSLatitude: "40.826551",
        GTFSLongitude: "-73.95036",
    },
    {
        StationID: "305",
        ComplexID: "305",
        GTFS_StopID: "115",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "137 St - City College",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Subway",
        GTFSLatitude: "40.822008",
        GTFSLongitude: "-73.953676",
    },
    {
        StationID: "306",
        ComplexID: "306",
        GTFS_StopID: "116",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "125 St",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Elevated",
        GTFSLatitude: "40.815581",
        GTFSLongitude: "-73.958372",
    },
    {
        StationID: "307",
        ComplexID: "307",
        GTFS_StopID: "117",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "116 St - Columbia University",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Subway",
        GTFSLatitude: "40.807722",
        GTFSLongitude: "-73.96411",
    },
    {
        StationID: "308",
        ComplexID: "308",
        GTFS_StopID: "118",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "Cathedral Pkwy",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Subway",
        GTFSLatitude: "40.803967",
        GTFSLongitude: "-73.966847",
    },
    {
        StationID: "309",
        ComplexID: "309",
        GTFS_StopID: "119",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "103 St",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Subway",
        GTFSLatitude: "40.799446",
        GTFSLongitude: "-73.968379",
    },
    {
        StationID: "310",
        ComplexID: "310",
        GTFS_StopID: "120",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "96 St",
        Borough: "M",
        DaytimeRoutes: "1 2 3",
        Structure: "Subway",
        GTFSLatitude: "40.793919",
        GTFSLongitude: "-73.972323",
    },
    {
        StationID: "311",
        ComplexID: "311",
        GTFS_StopID: "121",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "86 St",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Subway",
        GTFSLatitude: "40.788644",
        GTFSLongitude: "-73.976218",
    },
    {
        StationID: "312",
        ComplexID: "312",
        GTFS_StopID: "122",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "79 St",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Subway",
        GTFSLatitude: "40.783934",
        GTFSLongitude: "-73.979917",
    },
    {
        StationID: "313",
        ComplexID: "313",
        GTFS_StopID: "123",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "72 St",
        Borough: "M",
        DaytimeRoutes: "1 2 3",
        Structure: "Subway",
        GTFSLatitude: "40.778453",
        GTFSLongitude: "-73.98197",
    },
    {
        StationID: "314",
        ComplexID: "314",
        GTFS_StopID: "124",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "66 St - Lincoln Center",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Subway",
        GTFSLatitude: "40.77344",
        GTFSLongitude: "-73.982209",
    },
    {
        StationID: "315",
        ComplexID: "614",
        GTFS_StopID: "125",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "59 St - Columbus Circle",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Subway",
        GTFSLatitude: "40.768247",
        GTFSLongitude: "-73.981929",
    },
    {
        StationID: "316",
        ComplexID: "316",
        GTFS_StopID: "126",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "50 St",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Subway",
        GTFSLatitude: "40.761728",
        GTFSLongitude: "-73.983849",
    },
    {
        StationID: "317",
        ComplexID: "611",
        GTFS_StopID: "127",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "Times Sq - 42 St",
        Borough: "M",
        DaytimeRoutes: "1 2 3",
        Structure: "Subway",
        GTFSLatitude: "40.75529",
        GTFSLongitude: "-73.987495",
    },
    {
        StationID: "318",
        ComplexID: "318",
        GTFS_StopID: "128",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "34 St - Penn Station",
        Borough: "M",
        DaytimeRoutes: "1 2 3",
        Structure: "Subway",
        GTFSLatitude: "40.750373",
        GTFSLongitude: "-73.991057",
        NorthTracks: ['3','4'],
        SouthTracks: ['1','2']
    },
    {
        StationID: "319",
        ComplexID: "319",
        GTFS_StopID: "129",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "28 St",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Subway",
        GTFSLatitude: "40.747215",
        GTFSLongitude: "-73.993365",
    },
    {
        StationID: "320",
        ComplexID: "320",
        GTFS_StopID: "130",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "23 St",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Subway",
        GTFSLatitude: "40.744081",
        GTFSLongitude: "-73.995657",
    },
    {
        StationID: "321",
        ComplexID: "321",
        GTFS_StopID: "131",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "18 St",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Subway",
        GTFSLatitude: "40.74104",
        GTFSLongitude: "-73.997871",
    },
    {
        StationID: "322",
        ComplexID: "601",
        GTFS_StopID: "132",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "14 St",
        Borough: "M",
        DaytimeRoutes: "1 2 3",
        Structure: "Subway",
        GTFSLatitude: "40.737826",
        GTFSLongitude: "-74.000201",
    },
    {
        StationID: "323",
        ComplexID: "323",
        GTFS_StopID: "133",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "Christopher St - Sheridan Sq",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Subway",
        GTFSLatitude: "40.733422",
        GTFSLongitude: "-74.002906",
    },
    {
        StationID: "324",
        ComplexID: "324",
        GTFS_StopID: "134",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "Houston St",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Subway",
        GTFSLatitude: "40.728251",
        GTFSLongitude: "-74.005367",
    },
    {
        StationID: "325",
        ComplexID: "325",
        GTFS_StopID: "135",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "Canal St",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Subway",
        GTFSLatitude: "40.722854",
        GTFSLongitude: "-74.006277",
    },
    {
        StationID: "326",
        ComplexID: "326",
        GTFS_StopID: "136",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "Franklin St",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Subway",
        GTFSLatitude: "40.719318",
        GTFSLongitude: "-74.006886",
    },
    {
        StationID: "327",
        ComplexID: "327",
        GTFS_StopID: "137",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "Chambers St",
        Borough: "M",
        DaytimeRoutes: "1 2 3",
        Structure: "Subway",
        GTFSLatitude: "40.715478",
        GTFSLongitude: "-74.009266",
    },
    {
        StationID: "328",
        ComplexID: "328",
        GTFS_StopID: "138",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "WTC Cortlandt",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Subway",
        GTFSLatitude: "40.711835",
        GTFSLongitude: "-74.012188",
    },
    {
        StationID: "329",
        ComplexID: "329",
        GTFS_StopID: "139",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "Rector St",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Subway",
        GTFSLatitude: "40.707513",
        GTFSLongitude: "-74.013783",
    },
    {
        StationID: "330",
        ComplexID: "635",
        GTFS_StopID: "142",
        Division: "IRT",
        Line: "Broadway - 7Av",
        StopName: "South Ferry",
        Borough: "M",
        DaytimeRoutes: "1",
        Structure: "Subway",
        GTFSLatitude: "40.702068",
        GTFSLongitude: "-74.013664",
    },
    {
        StationID: "331",
        ComplexID: "624",
        GTFS_StopID: "228",
        Division: "IRT",
        Line: "Clark St",
        StopName: "Park Pl",
        Borough: "M",
        DaytimeRoutes: "2 3",
        Structure: "Subway",
        GTFSLatitude: "40.713051",
        GTFSLongitude: "-74.008811",
    },
    {
        StationID: "332",
        ComplexID: "628",
        GTFS_StopID: "229",
        Division: "IRT",
        Line: "Clark St",
        StopName: "Fulton St",
        Borough: "M",
        DaytimeRoutes: "2 3",
        Structure: "Subway",
        GTFSLatitude: "40.709416",
        GTFSLongitude: "-74.006571",
    },
    {
        StationID: "333",
        ComplexID: "333",
        GTFS_StopID: "230",
        Division: "IRT",
        Line: "Clark St",
        StopName: "Wall St",
        Borough: "M",
        DaytimeRoutes: "2 3",
        Structure: "Subway",
        GTFSLatitude: "40.706821",
        GTFSLongitude: "-74.0091",
    },
    {
        StationID: "334",
        ComplexID: "334",
        GTFS_StopID: "231",
        Division: "IRT",
        Line: "Clark St",
        StopName: "Clark St",
        Borough: "Bk",
        DaytimeRoutes: "2 3",
        Structure: "Subway",
        GTFSLatitude: "40.697466",
        GTFSLongitude: "-73.993086",
    },
    {
        StationID: "335",
        ComplexID: "620",
        GTFS_StopID: "232",
        Division: "IRT",
        Line: "Clark St",
        StopName: "Borough Hall",
        Borough: "Bk",
        DaytimeRoutes: "2 3",
        Structure: "Subway",
        GTFSLatitude: "40.693219",
        GTFSLongitude: "-73.989998",
    },
    {
        StationID: "336",
        ComplexID: "336",
        GTFS_StopID: "233",
        Division: "IRT",
        Line: "Eastern Pky",
        StopName: "Hoyt St",
        Borough: "Bk",
        DaytimeRoutes: "2 3",
        Structure: "Subway",
        GTFSLatitude: "40.690545",
        GTFSLongitude: "-73.985065",
    },
    {
        StationID: "337",
        ComplexID: "337",
        GTFS_StopID: "234",
        Division: "IRT",
        Line: "Eastern Pky",
        StopName: "Nevins St",
        Borough: "Bk",
        DaytimeRoutes: "2 3 4 5",
        Structure: "Subway",
        GTFSLatitude: "40.688246",
        GTFSLongitude: "-73.980492",
    },
    {
        StationID: "338",
        ComplexID: "617",
        GTFS_StopID: "235",
        Division: "IRT",
        Line: "Eastern Pky",
        StopName: "Atlantic Av - Barclays Ctr",
        Borough: "Bk",
        DaytimeRoutes: "2 3",
        Structure: "Subway",
        GTFSLatitude: "40.684359",
        GTFSLongitude: "-73.977666",
        NorthTracks: ['3','4'],
        SouthTracks: ['1','2'],
    },
    {
        StationID: "339",
        ComplexID: "339",
        GTFS_StopID: "236",
        Division: "IRT",
        Line: "Eastern Pky",
        StopName: "Bergen St",
        Borough: "Bk",
        DaytimeRoutes: "2 3",
        Structure: "Subway",
        GTFSLatitude: "40.680829",
        GTFSLongitude: "-73.975098",
    },
    {
        StationID: "340",
        ComplexID: "340",
        GTFS_StopID: "237",
        Division: "IRT",
        Line: "Eastern Pky",
        StopName: "Grand Army Plaza",
        Borough: "Bk",
        DaytimeRoutes: "2 3",
        Structure: "Subway",
        GTFSLatitude: "40.675235",
        GTFSLongitude: "-73.971046",
    },
    {
        StationID: "341",
        ComplexID: "341",
        GTFS_StopID: "238",
        Division: "IRT",
        Line: "Eastern Pky",
        StopName: "Eastern Pkwy - Brooklyn Museum",
        Borough: "Bk",
        DaytimeRoutes: "2 3",
        Structure: "Subway",
        GTFSLatitude: "40.671987",
        GTFSLongitude: "-73.964375",
    },
    {
        StationID: "342",
        ComplexID: "626",
        GTFS_StopID: "239",
        Division: "IRT",
        Line: "Eastern Pky",
        StopName: "Franklin Av-Medgar Evers College",
        Borough: "Bk",
        DaytimeRoutes: "2 3 4 5",
        Structure: "Subway",
        GTFSLatitude: "40.670682",
        GTFSLongitude: "-73.958131",
    },
    {
        StationID: "343",
        ComplexID: "343",
        GTFS_StopID: "248",
        Division: "IRT",
        Line: "Eastern Pky",
        StopName: "Nostrand Av",
        Borough: "Bk",
        DaytimeRoutes: "3",
        Structure: "Subway",
        GTFSLatitude: "40.669847",
        GTFSLongitude: "-73.950466",
    },
    {
        StationID: "344",
        ComplexID: "344",
        GTFS_StopID: "249",
        Division: "IRT",
        Line: "Eastern Pky",
        StopName: "Kingston Av",
        Borough: "Bk",
        DaytimeRoutes: "3",
        Structure: "Subway",
        GTFSLatitude: "40.669399",
        GTFSLongitude: "-73.942161",
    },
    {
        StationID: "345",
        ComplexID: "345",
        GTFS_StopID: "250",
        Division: "IRT",
        Line: "Eastern Pky",
        StopName: "Crown Hts - Utica Av",
        Borough: "Bk",
        DaytimeRoutes: "3 4",
        Structure: "Subway",
        GTFSLatitude: "40.668897",
        GTFSLongitude: "-73.932942",
    },
    {
        StationID: "346",
        ComplexID: "346",
        GTFS_StopID: "251",
        Division: "IRT",
        Line: "Eastern Pky",
        StopName: "Sutter Av - Rutland Rd",
        Borough: "Bk",
        DaytimeRoutes: "3",
        Structure: "Elevated",
        GTFSLatitude: "40.664717",
        GTFSLongitude: "-73.92261",
    },
    {
        StationID: "347",
        ComplexID: "347",
        GTFS_StopID: "252",
        Division: "IRT",
        Line: "Eastern Pky",
        StopName: "Saratoga Av",
        Borough: "Bk",
        DaytimeRoutes: "3",
        Structure: "Elevated",
        GTFSLatitude: "40.661453",
        GTFSLongitude: "-73.916327",
    },
    {
        StationID: "348",
        ComplexID: "348",
        GTFS_StopID: "253",
        Division: "IRT",
        Line: "Eastern Pky",
        StopName: "Rockaway Av",
        Borough: "Bk",
        DaytimeRoutes: "3",
        Structure: "Elevated",
        GTFSLatitude: "40.662549",
        GTFSLongitude: "-73.908946",
    },
    {
        StationID: "349",
        ComplexID: "349",
        GTFS_StopID: "254",
        Division: "IRT",
        Line: "Eastern Pky",
        StopName: "Junius St",
        Borough: "Bk",
        DaytimeRoutes: "3",
        Structure: "Elevated",
        GTFSLatitude: "40.663515",
        GTFSLongitude: "-73.902447",
    },
    {
        StationID: "350",
        ComplexID: "350",
        GTFS_StopID: "255",
        Division: "IRT",
        Line: "Eastern Pky",
        StopName: "Pennsylvania Av",
        Borough: "Bk",
        DaytimeRoutes: "3",
        Structure: "Elevated",
        GTFSLatitude: "40.664635",
        GTFSLongitude: "-73.894895",
    },
    {
        StationID: "351",
        ComplexID: "351",
        GTFS_StopID: "256",
        Division: "IRT",
        Line: "Eastern Pky",
        StopName: "Van Siclen Av",
        Borough: "Bk",
        DaytimeRoutes: "3",
        Structure: "Elevated",
        GTFSLatitude: "40.665449",
        GTFSLongitude: "-73.889395",
    },
    {
        StationID: "352",
        ComplexID: "352",
        GTFS_StopID: "257",
        Division: "IRT",
        Line: "Eastern Pky",
        StopName: "New Lots Av",
        Borough: "Bk",
        DaytimeRoutes: "3",
        Structure: "Elevated",
        GTFSLatitude: "40.666235",
        GTFSLongitude: "-73.884079",
    },
    {
        StationID: "353",
        ComplexID: "353",
        GTFS_StopID: "241",
        Division: "IRT",
        Line: "Nostrand",
        StopName: "President St-Medgar Evers College",
        Borough: "Bk",
        DaytimeRoutes: "2 5",
        Structure: "Subway",
        GTFSLatitude: "40.667883",
        GTFSLongitude: "-73.950683",
    },
    {
        StationID: "354",
        ComplexID: "354",
        GTFS_StopID: "242",
        Division: "IRT",
        Line: "Nostrand",
        StopName: "Sterling St",
        Borough: "Bk",
        DaytimeRoutes: "2 5",
        Structure: "Subway",
        GTFSLatitude: "40.662742",
        GTFSLongitude: "-73.95085",
    },
    {
        StationID: "355",
        ComplexID: "355",
        GTFS_StopID: "243",
        Division: "IRT",
        Line: "Nostrand",
        StopName: "Winthrop St",
        Borough: "Bk",
        DaytimeRoutes: "2 5",
        Structure: "Subway",
        GTFSLatitude: "40.656652",
        GTFSLongitude: "-73.9502",
    },
    {
        StationID: "356",
        ComplexID: "356",
        GTFS_StopID: "244",
        Division: "IRT",
        Line: "Nostrand",
        StopName: "Church Av",
        Borough: "Bk",
        DaytimeRoutes: "2 5",
        Structure: "Subway",
        GTFSLatitude: "40.650843",
        GTFSLongitude: "-73.949575",
    },
    {
        StationID: "357",
        ComplexID: "357",
        GTFS_StopID: "245",
        Division: "IRT",
        Line: "Nostrand",
        StopName: "Beverly Rd",
        Borough: "Bk",
        DaytimeRoutes: "2 5",
        Structure: "Subway",
        GTFSLatitude: "40.645098",
        GTFSLongitude: "-73.948959",
    },
    {
        StationID: "358",
        ComplexID: "358",
        GTFS_StopID: "246",
        Division: "IRT",
        Line: "Nostrand",
        StopName: "Newkirk Av - Little Haiti",
        Borough: "Bk",
        DaytimeRoutes: "2 5",
        Structure: "Subway",
        GTFSLatitude: "40.639967",
        GTFSLongitude: "-73.948411",
    },
    {
        StationID: "359",
        ComplexID: "359",
        GTFS_StopID: "247",
        Division: "IRT",
        Line: "Nostrand",
        StopName: "Flatbush Av - Brooklyn College",
        Borough: "Bk",
        DaytimeRoutes: "2 5",
        Structure: "Subway",
        GTFSLatitude: "40.632836",
        GTFSLongitude: "-73.947642",
    },
    {
        StationID: "360",
        ComplexID: "360",
        GTFS_StopID: "601",
        Division: "IRT",
        Line: "Pelham",
        StopName: "Pelham Bay Park",
        Borough: "Bx",
        DaytimeRoutes: "6",
        Structure: "Elevated",
        GTFSLatitude: "40.852462",
        GTFSLongitude: "-73.828121",
    },
    {
        StationID: "361",
        ComplexID: "361",
        GTFS_StopID: "602",
        Division: "IRT",
        Line: "Pelham",
        StopName: "Buhre Av",
        Borough: "Bx",
        DaytimeRoutes: "6",
        Structure: "Elevated",
        GTFSLatitude: "40.84681",
        GTFSLongitude: "-73.832569",
    },
    {
        StationID: "362",
        ComplexID: "362",
        GTFS_StopID: "603",
        Division: "IRT",
        Line: "Pelham",
        StopName: "Middletown Rd",
        Borough: "Bx",
        DaytimeRoutes: "6",
        Structure: "Elevated",
        GTFSLatitude: "40.843863",
        GTFSLongitude: "-73.836322",
    },
    {
        StationID: "363",
        ComplexID: "363",
        GTFS_StopID: "604",
        Division: "IRT",
        Line: "Pelham",
        StopName: "Westchester Sq - E Tremont Av",
        Borough: "Bx",
        DaytimeRoutes: "6",
        Structure: "Elevated",
        GTFSLatitude: "40.839892",
        GTFSLongitude: "-73.842952",
    },
    {
        StationID: "364",
        ComplexID: "364",
        GTFS_StopID: "606",
        Division: "IRT",
        Line: "Pelham",
        StopName: "Zerega Av",
        Borough: "Bx",
        DaytimeRoutes: "6",
        Structure: "Elevated",
        GTFSLatitude: "40.836488",
        GTFSLongitude: "-73.847036",
    },
    {
        StationID: "365",
        ComplexID: "365",
        GTFS_StopID: "607",
        Division: "IRT",
        Line: "Pelham",
        StopName: "Castle Hill Av",
        Borough: "Bx",
        DaytimeRoutes: "6",
        Structure: "Elevated",
        GTFSLatitude: "40.834255",
        GTFSLongitude: "-73.851222",
    },
    {
        StationID: "366",
        ComplexID: "366",
        GTFS_StopID: "608",
        Division: "IRT",
        Line: "Pelham",
        StopName: "Parkchester",
        Borough: "Bx",
        DaytimeRoutes: "6",
        Structure: "Elevated",
        GTFSLatitude: "40.833226",
        GTFSLongitude: "-73.860816",
    },
    {
        StationID: "367",
        ComplexID: "367",
        GTFS_StopID: "609",
        Division: "IRT",
        Line: "Pelham",
        StopName: "St Lawrence Av",
        Borough: "Bx",
        DaytimeRoutes: "6",
        Structure: "Elevated",
        GTFSLatitude: "40.831509",
        GTFSLongitude: "-73.867618",
    },
    {
        StationID: "368",
        ComplexID: "368",
        GTFS_StopID: "610",
        Division: "IRT",
        Line: "Pelham",
        StopName: "Morrison Av- Sound View",
        Borough: "Bx",
        DaytimeRoutes: "6",
        Structure: "Elevated",
        GTFSLatitude: "40.829521",
        GTFSLongitude: "-73.874516",
    },
    {
        StationID: "369",
        ComplexID: "369",
        GTFS_StopID: "611",
        Division: "IRT",
        Line: "Pelham",
        StopName: "Elder Av",
        Borough: "Bx",
        DaytimeRoutes: "6",
        Structure: "Elevated",
        GTFSLatitude: "40.828584",
        GTFSLongitude: "-73.879159",
    },
    {
        StationID: "370",
        ComplexID: "370",
        GTFS_StopID: "612",
        Division: "IRT",
        Line: "Pelham",
        StopName: "Whitlock Av",
        Borough: "Bx",
        DaytimeRoutes: "6",
        Structure: "Elevated",
        GTFSLatitude: "40.826525",
        GTFSLongitude: "-73.886283",
    },
    {
        StationID: "371",
        ComplexID: "371",
        GTFS_StopID: "613",
        Division: "IRT",
        Line: "Pelham",
        StopName: "Hunts Point Av",
        Borough: "Bx",
        DaytimeRoutes: "6",
        Structure: "Subway",
        GTFSLatitude: "40.820948",
        GTFSLongitude: "-73.890549",
    },
    {
        StationID: "372",
        ComplexID: "372",
        GTFS_StopID: "614",
        Division: "IRT",
        Line: "Pelham",
        StopName: "Longwood Av",
        Borough: "Bx",
        DaytimeRoutes: "6",
        Structure: "Subway",
        GTFSLatitude: "40.816104",
        GTFSLongitude: "-73.896435",
    },
    {
        StationID: "373",
        ComplexID: "373",
        GTFS_StopID: "615",
        Division: "IRT",
        Line: "Pelham",
        StopName: "E 149 St",
        Borough: "Bx",
        DaytimeRoutes: "6",
        Structure: "Subway",
        GTFSLatitude: "40.812118",
        GTFSLongitude: "-73.904098",
    },
    {
        StationID: "374",
        ComplexID: "374",
        GTFS_StopID: "616",
        Division: "IRT",
        Line: "Pelham",
        StopName: "E 143 St - St Mary's St",
        Borough: "Bx",
        DaytimeRoutes: "6",
        Structure: "Subway",
        GTFSLatitude: "40.808719",
        GTFSLongitude: "-73.907657",
    },
    {
        StationID: "375",
        ComplexID: "375",
        GTFS_StopID: "617",
        Division: "IRT",
        Line: "Pelham",
        StopName: "Cypress Av",
        Borough: "Bx",
        DaytimeRoutes: "6",
        Structure: "Subway",
        GTFSLatitude: "40.805368",
        GTFSLongitude: "-73.914042",
    },
    {
        StationID: "376",
        ComplexID: "376",
        GTFS_StopID: "618",
        Division: "IRT",
        Line: "Pelham",
        StopName: "Brook Av",
        Borough: "Bx",
        DaytimeRoutes: "6",
        Structure: "Subway",
        GTFSLatitude: "40.807566",
        GTFSLongitude: "-73.91924",
    },
    {
        StationID: "377",
        ComplexID: "377",
        GTFS_StopID: "619",
        Division: "IRT",
        Line: "Pelham",
        StopName: "3 Av - 138 St",
        Borough: "Bx",
        DaytimeRoutes: "6",
        Structure: "Subway",
        GTFSLatitude: "40.810476",
        GTFSLongitude: "-73.926138",
    },
    {
        StationID: "378",
        ComplexID: "378",
        GTFS_StopID: "401",
        Division: "IRT",
        Line: "Jerome Av",
        StopName: "Woodlawn",
        Borough: "Bx",
        DaytimeRoutes: "4",
        Structure: "Elevated",
        GTFSLatitude: "40.886037",
        GTFSLongitude: "-73.878751",
    },
    {
        StationID: "379",
        ComplexID: "379",
        GTFS_StopID: "402",
        Division: "IRT",
        Line: "Jerome Av",
        StopName: "Mosholu Pkwy",
        Borough: "Bx",
        DaytimeRoutes: "4",
        Structure: "Elevated",
        GTFSLatitude: "40.87975",
        GTFSLongitude: "-73.884655",
    },
    {
        StationID: "380",
        ComplexID: "380",
        GTFS_StopID: "405",
        Division: "IRT",
        Line: "Jerome Av",
        StopName: "Bedford Park Blvd - Lehman College",
        Borough: "Bx",
        DaytimeRoutes: "4",
        Structure: "Elevated",
        GTFSLatitude: "40.873412",
        GTFSLongitude: "-73.890064",
    },
    {
        StationID: "381",
        ComplexID: "381",
        GTFS_StopID: "406",
        Division: "IRT",
        Line: "Jerome Av",
        StopName: "Kingsbridge Rd",
        Borough: "Bx",
        DaytimeRoutes: "4",
        Structure: "Elevated",
        GTFSLatitude: "40.86776",
        GTFSLongitude: "-73.897174",
    },
    {
        StationID: "382",
        ComplexID: "382",
        GTFS_StopID: "407",
        Division: "IRT",
        Line: "Jerome Av",
        StopName: "Fordham Rd",
        Borough: "Bx",
        DaytimeRoutes: "4",
        Structure: "Elevated",
        GTFSLatitude: "40.862803",
        GTFSLongitude: "-73.901034",
    },
    {
        StationID: "383",
        ComplexID: "383",
        GTFS_StopID: "408",
        Division: "IRT",
        Line: "Jerome Av",
        StopName: "183 St",
        Borough: "Bx",
        DaytimeRoutes: "4",
        Structure: "Elevated",
        GTFSLatitude: "40.858407",
        GTFSLongitude: "-73.903879",
    },
    {
        StationID: "384",
        ComplexID: "384",
        GTFS_StopID: "409",
        Division: "IRT",
        Line: "Jerome Av",
        StopName: "Burnside Av",
        Borough: "Bx",
        DaytimeRoutes: "4",
        Structure: "Elevated",
        GTFSLatitude: "40.853453",
        GTFSLongitude: "-73.907684",
    },
    {
        StationID: "385",
        ComplexID: "385",
        GTFS_StopID: "410",
        Division: "IRT",
        Line: "Jerome Av",
        StopName: "176 St",
        Borough: "Bx",
        DaytimeRoutes: "4",
        Structure: "Elevated",
        GTFSLatitude: "40.84848",
        GTFSLongitude: "-73.911794",
    },
    {
        StationID: "386",
        ComplexID: "386",
        GTFS_StopID: "411",
        Division: "IRT",
        Line: "Jerome Av",
        StopName: "Mt Eden Av",
        Borough: "Bx",
        DaytimeRoutes: "4",
        Structure: "Elevated",
        GTFSLatitude: "40.844434",
        GTFSLongitude: "-73.914685",
    },
    {
        StationID: "387",
        ComplexID: "387",
        GTFS_StopID: "412",
        Division: "IRT",
        Line: "Jerome Av",
        StopName: "170 St",
        Borough: "Bx",
        DaytimeRoutes: "4",
        Structure: "Elevated",
        GTFSLatitude: "40.840075",
        GTFSLongitude: "-73.917791",
    },
    {
        StationID: "388",
        ComplexID: "388",
        GTFS_StopID: "413",
        Division: "IRT",
        Line: "Jerome Av",
        StopName: "167 St",
        Borough: "Bx",
        DaytimeRoutes: "4",
        Structure: "Elevated",
        GTFSLatitude: "40.835537",
        GTFSLongitude: "-73.9214",
    },
    {
        StationID: "389",
        ComplexID: "604",
        GTFS_StopID: "414",
        Division: "IRT",
        Line: "Jerome Av",
        StopName: "161 St - Yankee Stadium",
        Borough: "Bx",
        DaytimeRoutes: "4",
        Structure: "Elevated",
        GTFSLatitude: "40.827994",
        GTFSLongitude: "-73.925831",
    },
    {
        StationID: "390",
        ComplexID: "603",
        GTFS_StopID: "415",
        Division: "IRT",
        Line: "Jerome Av",
        StopName: "149 St - Grand Concourse",
        Borough: "Bx",
        DaytimeRoutes: "4",
        Structure: "Subway",
        GTFSLatitude: "40.818375",
        GTFSLongitude: "-73.927351",
    },
    {
        StationID: "391",
        ComplexID: "391",
        GTFS_StopID: "416",
        Division: "IRT",
        Line: "Jerome Av",
        StopName: "138 St - Grand Concourse",
        Borough: "Bx",
        DaytimeRoutes: "4 5",
        Structure: "Subway",
        GTFSLatitude: "40.813224",
        GTFSLongitude: "-73.929849",
    },
    {
        StationID: "392",
        ComplexID: "392",
        GTFS_StopID: "621",
        Division: "IRT",
        Line: "Lexington Av",
        StopName: "125 St",
        Borough: "M",
        DaytimeRoutes: "4 5 6",
        Structure: "Subway",
        GTFSLatitude: "40.804138",
        GTFSLongitude: "-73.937594",
    },
    {
        StationID: "393",
        ComplexID: "393",
        GTFS_StopID: "622",
        Division: "IRT",
        Line: "Lexington Av",
        StopName: "116 St",
        Borough: "M",
        DaytimeRoutes: "6",
        Structure: "Subway",
        GTFSLatitude: "40.798629",
        GTFSLongitude: "-73.941617",
    },
    {
        StationID: "394",
        ComplexID: "394",
        GTFS_StopID: "623",
        Division: "IRT",
        Line: "Lexington Av",
        StopName: "110 St",
        Borough: "M",
        DaytimeRoutes: "6",
        Structure: "Subway",
        GTFSLatitude: "40.79502",
        GTFSLongitude: "-73.94425",
    },
    {
        StationID: "395",
        ComplexID: "395",
        GTFS_StopID: "624",
        Division: "IRT",
        Line: "Lexington Av",
        StopName: "103 St",
        Borough: "M",
        DaytimeRoutes: "6",
        Structure: "Subway",
        GTFSLatitude: "40.7906",
        GTFSLongitude: "-73.947478",
    },
    {
        StationID: "396",
        ComplexID: "396",
        GTFS_StopID: "625",
        Division: "IRT",
        Line: "Lexington Av",
        StopName: "96 St",
        Borough: "M",
        DaytimeRoutes: "6",
        Structure: "Subway",
        GTFSLatitude: "40.785672",
        GTFSLongitude: "-73.95107",
    },
    {
        StationID: "397",
        ComplexID: "397",
        GTFS_StopID: "626",
        Division: "IRT",
        Line: "Lexington Av",
        StopName: "86 St",
        Borough: "M",
        DaytimeRoutes: "4 5 6",
        Structure: "Subway",
        GTFSLatitude: "40.779492",
        GTFSLongitude: "-73.955589",
    },
    {
        StationID: "398",
        ComplexID: "398",
        GTFS_StopID: "627",
        Division: "IRT",
        Line: "Lexington Av",
        StopName: "77 St",
        Borough: "M",
        DaytimeRoutes: "6",
        Structure: "Subway",
        GTFSLatitude: "40.77362",
        GTFSLongitude: "-73.959874",
    },
    {
        StationID: "399",
        ComplexID: "399",
        GTFS_StopID: "628",
        Division: "IRT",
        Line: "Lexington Av",
        StopName: "68 St - Hunter College",
        Borough: "M",
        DaytimeRoutes: "6",
        Structure: "Subway",
        GTFSLatitude: "40.768141",
        GTFSLongitude: "-73.96387",
    },
    {
        StationID: "400",
        ComplexID: "613",
        GTFS_StopID: "629",
        Division: "IRT",
        Line: "Lexington Av",
        StopName: "59 St",
        Borough: "M",
        DaytimeRoutes: "4 5 6",
        Structure: "Subway",
        GTFSLatitude: "40.762526",
        GTFSLongitude: "-73.967967",
    },
    {
        StationID: "401",
        ComplexID: "612",
        GTFS_StopID: "630",
        Division: "IRT",
        Line: "Lexington Av",
        StopName: "51 St",
        Borough: "M",
        DaytimeRoutes: "6",
        Structure: "Subway",
        GTFSLatitude: "40.757107",
        GTFSLongitude: "-73.97192",
    },
    {
        StationID: "402",
        ComplexID: "610",
        GTFS_StopID: "631",
        Division: "IRT",
        Line: "Lexington Av",
        StopName: "Grand Central - 42 St",
        Borough: "M",
        DaytimeRoutes: "4 5 6",
        Structure: "Subway",
        GTFSLatitude: "40.751776",
        GTFSLongitude: "-73.976848",
    },
    {
        StationID: "403",
        ComplexID: "403",
        GTFS_StopID: "632",
        Division: "IRT",
        Line: "Lexington Av",
        StopName: "33 St",
        Borough: "M",
        DaytimeRoutes: "6",
        Structure: "Subway",
        GTFSLatitude: "40.746081",
        GTFSLongitude: "-73.982076",
    },
    {
        StationID: "404",
        ComplexID: "404",
        GTFS_StopID: "633",
        Division: "IRT",
        Line: "Lexington Av",
        StopName: "28 St",
        Borough: "M",
        DaytimeRoutes: "6",
        Structure: "Subway",
        GTFSLatitude: "40.74307",
        GTFSLongitude: "-73.984264",
    },
    {
        StationID: "405",
        ComplexID: "405",
        GTFS_StopID: "634",
        Division: "IRT",
        Line: "Lexington Av",
        StopName: "23 St",
        Borough: "M",
        DaytimeRoutes: "6",
        Structure: "Subway",
        GTFSLatitude: "40.739864",
        GTFSLongitude: "-73.986599",
    },
    {
        StationID: "406",
        ComplexID: "602",
        GTFS_StopID: "635",
        Division: "IRT",
        Line: "Lexington Av",
        StopName: "14 St - Union Sq",
        Borough: "M",
        DaytimeRoutes: "4 5 6",
        Structure: "Subway",
        GTFSLatitude: "40.734673",
        GTFSLongitude: "-73.989951",
    },
    {
        StationID: "407",
        ComplexID: "407",
        GTFS_StopID: "636",
        Division: "IRT",
        Line: "Lexington Av",
        StopName: "Astor Pl",
        Borough: "M",
        DaytimeRoutes: "6",
        Structure: "Subway",
        GTFSLatitude: "40.730054",
        GTFSLongitude: "-73.99107",
    },
    {
        StationID: "408",
        ComplexID: "619",
        GTFS_StopID: "637",
        Division: "IRT",
        Line: "Lexington Av",
        StopName: "Bleecker St",
        Borough: "M",
        DaytimeRoutes: "6",
        Structure: "Subway",
        GTFSLatitude: "40.725915",
        GTFSLongitude: "-73.994659",
    },
    {
        StationID: "409",
        ComplexID: "409",
        GTFS_StopID: "638",
        Division: "IRT",
        Line: "Lexington Av",
        StopName: "Spring St",
        Borough: "M",
        DaytimeRoutes: "6",
        Structure: "Subway",
        GTFSLatitude: "40.722301",
        GTFSLongitude: "-73.997141",
    },
    {
        StationID: "410",
        ComplexID: "623",
        GTFS_StopID: "639",
        Division: "IRT",
        Line: "Lexington Av",
        StopName: "Canal St",
        Borough: "M",
        DaytimeRoutes: "6",
        Structure: "Subway",
        GTFSLatitude: "40.718803",
        GTFSLongitude: "-74.000193",
    },
    {
        StationID: "411",
        ComplexID: "622",
        GTFS_StopID: "640",
        Division: "IRT",
        Line: "Lexington Av",
        StopName: "Brooklyn Bridge - City Hall",
        Borough: "M",
        DaytimeRoutes: "4 5 6",
        Structure: "Subway",
        GTFSLatitude: "40.713065",
        GTFSLongitude: "-74.004131",
    },
    {
        StationID: "412",
        ComplexID: "628",
        GTFS_StopID: "418",
        Division: "IRT",
        Line: "Lexington Av",
        StopName: "Fulton St",
        Borough: "M",
        DaytimeRoutes: "4 5",
        Structure: "Subway",
        GTFSLatitude: "40.710368",
        GTFSLongitude: "-74.009509",
    },
    {
        StationID: "413",
        ComplexID: "413",
        GTFS_StopID: "419",
        Division: "IRT",
        Line: "Lexington Av",
        StopName: "Wall St",
        Borough: "M",
        DaytimeRoutes: "4 5",
        Structure: "Subway",
        GTFSLatitude: "40.707557",
        GTFSLongitude: "-74.011862",
    },
    {
        StationID: "414",
        ComplexID: "414",
        GTFS_StopID: "420",
        Division: "IRT",
        Line: "Lexington Av",
        StopName: "Bowling Green",
        Borough: "M",
        DaytimeRoutes: "4 5",
        Structure: "Subway",
        GTFSLatitude: "40.704817",
        GTFSLongitude: "-74.014065",
    },
    {
        StationID: "415",
        ComplexID: "620",
        GTFS_StopID: "423",
        Division: "IRT",
        Line: "Eastern Pky",
        StopName: "Borough Hall",
        Borough: "Bk",
        DaytimeRoutes: "4 5",
        Structure: "Subway",
        GTFSLatitude: "40.692404",
        GTFSLongitude: "-73.990151",
    },
    {
        StationID: "416",
        ComplexID: "416",
        GTFS_StopID: "201",
        Division: "IRT",
        Line: "Lenox - White Plains Rd",
        StopName: "Wakefield - 241 St",
        Borough: "Bx",
        DaytimeRoutes: "2",
        Structure: "Elevated",
        GTFSLatitude: "40.903125",
        GTFSLongitude: "-73.85062",
    },
    {
        StationID: "417",
        ComplexID: "417",
        GTFS_StopID: "204",
        Division: "IRT",
        Line: "Lenox - White Plains Rd",
        StopName: "Nereid Av",
        Borough: "Bx",
        DaytimeRoutes: "2 5",
        Structure: "Elevated",
        GTFSLatitude: "40.898379",
        GTFSLongitude: "-73.854376",
    },
    {
        StationID: "418",
        ComplexID: "418",
        GTFS_StopID: "205",
        Division: "IRT",
        Line: "Lenox - White Plains Rd",
        StopName: "233 St",
        Borough: "Bx",
        DaytimeRoutes: "2 5",
        Structure: "Elevated",
        GTFSLatitude: "40.893193",
        GTFSLongitude: "-73.857473",
    },
    {
        StationID: "419",
        ComplexID: "419",
        GTFS_StopID: "206",
        Division: "IRT",
        Line: "Lenox - White Plains Rd",
        StopName: "225 St",
        Borough: "Bx",
        DaytimeRoutes: "2 5",
        Structure: "Elevated",
        GTFSLatitude: "40.888022",
        GTFSLongitude: "-73.860341",
    },
    {
        StationID: "420",
        ComplexID: "420",
        GTFS_StopID: "207",
        Division: "IRT",
        Line: "Lenox - White Plains Rd",
        StopName: "219 St",
        Borough: "Bx",
        DaytimeRoutes: "2 5",
        Structure: "Elevated",
        GTFSLatitude: "40.883895",
        GTFSLongitude: "-73.862633",
    },
    {
        StationID: "421",
        ComplexID: "421",
        GTFS_StopID: "208",
        Division: "IRT",
        Line: "Lenox - White Plains Rd",
        StopName: "Gun Hill Rd",
        Borough: "Bx",
        DaytimeRoutes: "2 5",
        Structure: "Elevated",
        GTFSLatitude: "40.87785",
        GTFSLongitude: "-73.866256",
    },
    {
        StationID: "422",
        ComplexID: "422",
        GTFS_StopID: "209",
        Division: "IRT",
        Line: "Lenox - White Plains Rd",
        StopName: "Burke Av",
        Borough: "Bx",
        DaytimeRoutes: "2 5",
        Structure: "Elevated",
        GTFSLatitude: "40.871356",
        GTFSLongitude: "-73.867164",
    },
    {
        StationID: "423",
        ComplexID: "423",
        GTFS_StopID: "210",
        Division: "IRT",
        Line: "Lenox - White Plains Rd",
        StopName: "Allerton Av",
        Borough: "Bx",
        DaytimeRoutes: "2 5",
        Structure: "Elevated",
        GTFSLatitude: "40.865462",
        GTFSLongitude: "-73.867352",
    },
    {
        StationID: "424",
        ComplexID: "424",
        GTFS_StopID: "211",
        Division: "IRT",
        Line: "Lenox - White Plains Rd",
        StopName: "Pelham Pkwy",
        Borough: "Bx",
        DaytimeRoutes: "2 5",
        Structure: "Elevated",
        GTFSLatitude: "40.857192",
        GTFSLongitude: "-73.867615",
    },
    {
        StationID: "425",
        ComplexID: "425",
        GTFS_StopID: "212",
        Division: "IRT",
        Line: "Lenox - White Plains Rd",
        StopName: "Bronx Park East",
        Borough: "Bx",
        DaytimeRoutes: "2 5",
        Structure: "Elevated",
        GTFSLatitude: "40.848828",
        GTFSLongitude: "-73.868457",
    },
    {
        StationID: "426",
        ComplexID: "426",
        GTFS_StopID: "213",
        Division: "IRT",
        Line: "Lenox - White Plains Rd",
        StopName: "E 180 St",
        Borough: "Bx",
        DaytimeRoutes: "2 5",
        Structure: "Elevated",
        GTFSLatitude: "40.841894",
        GTFSLongitude: "-73.873488",
    },
    {
        StationID: "427",
        ComplexID: "427",
        GTFS_StopID: "214",
        Division: "IRT",
        Line: "Lenox - White Plains Rd",
        StopName: "West Farms Sq - E Tremont Av",
        Borough: "Bx",
        DaytimeRoutes: "2 5",
        Structure: "Elevated",
        GTFSLatitude: "40.840295",
        GTFSLongitude: "-73.880049",
    },
    {
        StationID: "428",
        ComplexID: "428",
        GTFS_StopID: "215",
        Division: "IRT",
        Line: "Lenox - White Plains Rd",
        StopName: "174 St",
        Borough: "Bx",
        DaytimeRoutes: "2 5",
        Structure: "Elevated",
        GTFSLatitude: "40.837288",
        GTFSLongitude: "-73.887734",
    },
    {
        StationID: "429",
        ComplexID: "429",
        GTFS_StopID: "216",
        Division: "IRT",
        Line: "Lenox - White Plains Rd",
        StopName: "Freeman St",
        Borough: "Bx",
        DaytimeRoutes: "2 5",
        Structure: "Elevated",
        GTFSLatitude: "40.829993",
        GTFSLongitude: "-73.891865",
    },
    {
        StationID: "430",
        ComplexID: "430",
        GTFS_StopID: "217",
        Division: "IRT",
        Line: "Lenox - White Plains Rd",
        StopName: "Simpson St",
        Borough: "Bx",
        DaytimeRoutes: "2 5",
        Structure: "Elevated",
        GTFSLatitude: "40.824073",
        GTFSLongitude: "-73.893064",
    },
    {
        StationID: "431",
        ComplexID: "431",
        GTFS_StopID: "218",
        Division: "IRT",
        Line: "Lenox - White Plains Rd",
        StopName: "Intervale Av",
        Borough: "Bx",
        DaytimeRoutes: "2 5",
        Structure: "Elevated",
        GTFSLatitude: "40.822181",
        GTFSLongitude: "-73.896736",
    },
    {
        StationID: "432",
        ComplexID: "432",
        GTFS_StopID: "219",
        Division: "IRT",
        Line: "Lenox - White Plains Rd",
        StopName: "Prospect Av",
        Borough: "Bx",
        DaytimeRoutes: "2 5",
        Structure: "Elevated",
        GTFSLatitude: "40.819585",
        GTFSLongitude: "-73.90177",
    },
    {
        StationID: "433",
        ComplexID: "433",
        GTFS_StopID: "220",
        Division: "IRT",
        Line: "Lenox - White Plains Rd",
        StopName: "Jackson Av",
        Borough: "Bx",
        DaytimeRoutes: "2 5",
        Structure: "Elevated",
        GTFSLatitude: "40.81649",
        GTFSLongitude: "-73.907807",
    },
    {
        StationID: "434",
        ComplexID: "434",
        GTFS_StopID: "221",
        Division: "IRT",
        Line: "Lenox - White Plains Rd",
        StopName: "3 Av - 149 St",
        Borough: "Bx",
        DaytimeRoutes: "2 5",
        Structure: "Subway",
        GTFSLatitude: "40.816109",
        GTFSLongitude: "-73.917757",
    },
    {
        StationID: "435",
        ComplexID: "603",
        GTFS_StopID: "222",
        Division: "IRT",
        Line: "Lenox - White Plains Rd",
        StopName: "149 St - Grand Concourse",
        Borough: "Bx",
        DaytimeRoutes: "2 5",
        Structure: "Subway",
        GTFSLatitude: "40.81841",
        GTFSLongitude: "-73.926718",
    },
    {
        StationID: "436",
        ComplexID: "436",
        GTFS_StopID: "301",
        Division: "IRT",
        Line: "Lenox - White Plains Rd",
        StopName: "Harlem - 148 St",
        Borough: "M",
        DaytimeRoutes: "3",
        Structure: "Subway",
        GTFSLatitude: "40.82388",
        GTFSLongitude: "-73.93647",
    },
    {
        StationID: "437",
        ComplexID: "437",
        GTFS_StopID: "302",
        Division: "IRT",
        Line: "Lenox - White Plains Rd",
        StopName: "145 St",
        Borough: "M",
        DaytimeRoutes: "3",
        Structure: "Subway",
        GTFSLatitude: "40.820421",
        GTFSLongitude: "-73.936245",
    },
    {
        StationID: "438",
        ComplexID: "438",
        GTFS_StopID: "224",
        Division: "IRT",
        Line: "Lenox - White Plains Rd",
        StopName: "135 St",
        Borough: "M",
        DaytimeRoutes: "2 3",
        Structure: "Subway",
        GTFSLatitude: "40.814229",
        GTFSLongitude: "-73.94077",
    },
    {
        StationID: "439",
        ComplexID: "439",
        GTFS_StopID: "225",
        Division: "IRT",
        Line: "Lenox - White Plains Rd",
        StopName: "125 St",
        Borough: "M",
        DaytimeRoutes: "2 3",
        Structure: "Subway",
        GTFSLatitude: "40.807754",
        GTFSLongitude: "-73.945495",
    },
    {
        StationID: "440",
        ComplexID: "440",
        GTFS_StopID: "226",
        Division: "IRT",
        Line: "Lenox - White Plains Rd",
        StopName: "116 St",
        Borough: "M",
        DaytimeRoutes: "2 3",
        Structure: "Subway",
        GTFSLatitude: "40.802098",
        GTFSLongitude: "-73.949625",
    },
    {
        StationID: "441",
        ComplexID: "441",
        GTFS_StopID: "227",
        Division: "IRT",
        Line: "Lenox - White Plains Rd",
        StopName: "Central Park North (110 St)",
        Borough: "M",
        DaytimeRoutes: "2 3",
        Structure: "Subway",
        GTFSLatitude: "40.799075",
        GTFSLongitude: "-73.951822",
    },
    {
        StationID: "442",
        ComplexID: "442",
        GTFS_StopID: "501",
        Division: "IRT",
        Line: "Dyre Av",
        StopName: "Eastchester - Dyre Av",
        Borough: "Bx",
        DaytimeRoutes: "5",
        Structure: "At Grade",
        GTFSLatitude: "40.8883",
        GTFSLongitude: "-73.830834",
    },
    {
        StationID: "443",
        ComplexID: "443",
        GTFS_StopID: "502",
        Division: "IRT",
        Line: "Dyre Av",
        StopName: "Baychester Av",
        Borough: "Bx",
        DaytimeRoutes: "5",
        Structure: "Open Cut",
        GTFSLatitude: "40.878663",
        GTFSLongitude: "-73.838591",
    },
    {
        StationID: "444",
        ComplexID: "444",
        GTFS_StopID: "503",
        Division: "IRT",
        Line: "Dyre Av",
        StopName: "Gun Hill Rd",
        Borough: "Bx",
        DaytimeRoutes: "5",
        Structure: "Open Cut",
        GTFSLatitude: "40.869526",
        GTFSLongitude: "-73.846384",
    },
    {
        StationID: "445",
        ComplexID: "445",
        GTFS_StopID: "504",
        Division: "IRT",
        Line: "Dyre Av",
        StopName: "Pelham Pkwy",
        Borough: "Bx",
        DaytimeRoutes: "5",
        Structure: "Open Cut",
        GTFSLatitude: "40.858985",
        GTFSLongitude: "-73.855359",
    },
    {
        StationID: "446",
        ComplexID: "446",
        GTFS_StopID: "505",
        Division: "IRT",
        Line: "Dyre Av",
        StopName: "Morris Park",
        Borough: "Bx",
        DaytimeRoutes: "5",
        Structure: "Open Cut",
        GTFSLatitude: "40.854364",
        GTFSLongitude: "-73.860495",
    },
    {
        StationID: "447",
        ComplexID: "447",
        GTFS_StopID: "701",
        Division: "IRT",
        Line: "Flushing",
        StopName: "Flushing - Main St",
        Borough: "Q",
        DaytimeRoutes: "7",
        Structure: "Subway",
        GTFSLatitude: "40.7596",
        GTFSLongitude: "-73.83003",
    },
    {
        StationID: "448",
        ComplexID: "448",
        GTFS_StopID: "702",
        Division: "IRT",
        Line: "Flushing",
        StopName: "Mets - Willets Point",
        Borough: "Q",
        DaytimeRoutes: "7",
        Structure: "Elevated",
        GTFSLatitude: "40.754622",
        GTFSLongitude: "-73.845625",
    },
    {
        StationID: "449",
        ComplexID: "449",
        GTFS_StopID: "705",
        Division: "IRT",
        Line: "Flushing",
        StopName: "111 St",
        Borough: "Q",
        DaytimeRoutes: "7",
        Structure: "Elevated",
        GTFSLatitude: "40.75173",
        GTFSLongitude: "-73.855334",
    },
    {
        StationID: "450",
        ComplexID: "450",
        GTFS_StopID: "706",
        Division: "IRT",
        Line: "Flushing",
        StopName: "103 St - Corona Plaza",
        Borough: "Q",
        DaytimeRoutes: "7",
        Structure: "Elevated",
        GTFSLatitude: "40.749865",
        GTFSLongitude: "-73.8627",
    },
    {
        StationID: "451",
        ComplexID: "451",
        GTFS_StopID: "707",
        Division: "IRT",
        Line: "Flushing",
        StopName: "Junction Blvd",
        Borough: "Q",
        DaytimeRoutes: "7",
        Structure: "Elevated",
        GTFSLatitude: "40.749145",
        GTFSLongitude: "-73.869527",
    },
    {
        StationID: "452",
        ComplexID: "452",
        GTFS_StopID: "708",
        Division: "IRT",
        Line: "Flushing",
        StopName: "90 St - Elmhurst Av",
        Borough: "Q",
        DaytimeRoutes: "7",
        Structure: "Elevated",
        GTFSLatitude: "40.748408",
        GTFSLongitude: "-73.876613",
    },
    {
        StationID: "453",
        ComplexID: "453",
        GTFS_StopID: "709",
        Division: "IRT",
        Line: "Flushing",
        StopName: "82 St - Jackson Hts",
        Borough: "Q",
        DaytimeRoutes: "7",
        Structure: "Elevated",
        GTFSLatitude: "40.747659",
        GTFSLongitude: "-73.883697",
    },
    {
        StationID: "454",
        ComplexID: "616",
        GTFS_StopID: "710",
        Division: "IRT",
        Line: "Flushing",
        StopName: "74 St - Broadway",
        Borough: "Q",
        DaytimeRoutes: "7",
        Structure: "Elevated",
        GTFSLatitude: "40.746848",
        GTFSLongitude: "-73.891394",
    },
    {
        StationID: "455",
        ComplexID: "455",
        GTFS_StopID: "711",
        Division: "IRT",
        Line: "Flushing",
        StopName: "69 St",
        Borough: "Q",
        DaytimeRoutes: "7",
        Structure: "Elevated",
        GTFSLatitude: "40.746325",
        GTFSLongitude: "-73.896403",
    },
    {
        StationID: "456",
        ComplexID: "456",
        GTFS_StopID: "712",
        Division: "IRT",
        Line: "Flushing",
        StopName: "Woodside - 61 St",
        Borough: "Q",
        DaytimeRoutes: "7",
        Structure: "Elevated",
        GTFSLatitude: "40.74563",
        GTFSLongitude: "-73.902984",
    },
    {
        StationID: "457",
        ComplexID: "457",
        GTFS_StopID: "713",
        Division: "IRT",
        Line: "Flushing",
        StopName: "52 St",
        Borough: "Q",
        DaytimeRoutes: "7",
        Structure: "Elevated",
        GTFSLatitude: "40.744149",
        GTFSLongitude: "-73.912549",
    },
    {
        StationID: "458",
        ComplexID: "458",
        GTFS_StopID: "714",
        Division: "IRT",
        Line: "Flushing",
        StopName: "46 St",
        Borough: "Q",
        DaytimeRoutes: "7",
        Structure: "Elevated",
        GTFSLatitude: "40.743132",
        GTFSLongitude: "-73.918435",
    },
    {
        StationID: "459",
        ComplexID: "459",
        GTFS_StopID: "715",
        Division: "IRT",
        Line: "Flushing",
        StopName: "40 St",
        Borough: "Q",
        DaytimeRoutes: "7",
        Structure: "Elevated",
        GTFSLatitude: "40.743781",
        GTFSLongitude: "-73.924016",
    },
    {
        StationID: "460",
        ComplexID: "460",
        GTFS_StopID: "716",
        Division: "IRT",
        Line: "Flushing",
        StopName: "33 St",
        Borough: "Q",
        DaytimeRoutes: "7",
        Structure: "Elevated",
        GTFSLatitude: "40.744587",
        GTFSLongitude: "-73.930997",
    },
    {
        StationID: "461",
        ComplexID: "461",
        GTFS_StopID: "718",
        Division: "IRT",
        Line: "Flushing",
        StopName: "Queensboro Plaza",
        Borough: "Q",
        DaytimeRoutes: "7",
        Structure: "Elevated",
        GTFSLatitude: "40.750582",
        GTFSLongitude: "-73.940202",
    },
    {
        StationID: "461",
        ComplexID: "461",
        GTFS_StopID: "R09",
        Division: "BMT",
        Line: "Astoria",
        StopName: "Queensboro Plaza",
        Borough: "Q",
        DaytimeRoutes: "N W",
        Structure: "Elevated",
        GTFSLatitude: "40.750582",
        GTFSLongitude: "-73.940202",
    },
    {
        StationID: "462",
        ComplexID: "606",
        GTFS_StopID: "719",
        Division: "IRT",
        Line: "Flushing",
        StopName: "Court Sq",
        Borough: "Q",
        DaytimeRoutes: "7",
        Structure: "Elevated",
        GTFSLatitude: "40.747023",
        GTFSLongitude: "-73.945264",
    },
    {
        StationID: "463",
        ComplexID: "463",
        GTFS_StopID: "720",
        Division: "IRT",
        Line: "Flushing",
        StopName: "Hunters Point Av",
        Borough: "Q",
        DaytimeRoutes: "7",
        Structure: "Subway",
        GTFSLatitude: "40.742216",
        GTFSLongitude: "-73.948916",
    },
    {
        StationID: "464",
        ComplexID: "464",
        GTFS_StopID: "721",
        Division: "IRT",
        Line: "Flushing",
        StopName: "Vernon Blvd - Jackson Av",
        Borough: "Q",
        DaytimeRoutes: "7",
        Structure: "Subway",
        GTFSLatitude: "40.742626",
        GTFSLongitude: "-73.953581",
    },
    {
        StationID: "465",
        ComplexID: "610",
        GTFS_StopID: "723",
        Division: "IRT",
        Line: "Flushing",
        StopName: "Grand Central - 42 St",
        Borough: "M",
        DaytimeRoutes: "7",
        Structure: "Subway",
        GTFSLatitude: "40.751431",
        GTFSLongitude: "-73.976041",
    },
    {
        StationID: "466",
        ComplexID: "609",
        GTFS_StopID: "724",
        Division: "IRT",
        Line: "Flushing",
        StopName: "5 Av",
        Borough: "M",
        DaytimeRoutes: "7",
        Structure: "Subway",
        GTFSLatitude: "40.753821",
        GTFSLongitude: "-73.981963",
    },
    {
        StationID: "467",
        ComplexID: "611",
        GTFS_StopID: "725",
        Division: "IRT",
        Line: "Flushing",
        StopName: "Times Sq - 42 St",
        Borough: "M",
        DaytimeRoutes: "7",
        Structure: "Subway",
        GTFSLatitude: "40.755477",
        GTFSLongitude: "-73.987691",
    },
    {
        StationID: "468",
        ComplexID: "611",
        GTFS_StopID: "902",
        Division: "IRT",
        Line: "Lexington - Shuttle",
        StopName: "Times Sq - 42 St",
        Borough: "M",
        DaytimeRoutes: "GS",
        Structure: "Subway",
        GTFSLatitude: "40.755983",
        GTFSLongitude: "-73.986229",
    },
    {
        StationID: "469",
        ComplexID: "610",
        GTFS_StopID: "901",
        Division: "IRT",
        Line: "Lexington - Shuttle",
        StopName: "Grand Central - 42 St",
        Borough: "M",
        DaytimeRoutes: "GS",
        Structure: "Subway",
        GTFSLatitude: "40.752769",
        GTFSLongitude: "-73.979189",
    },
    {
        StationID: "471",
        ComplexID: "471",
        GTFS_StopID: "726",
        Division: "IRT",
        Line: "Flushing",
        StopName: "34 St - 11 Av",
        Borough: "M",
        DaytimeRoutes: "7",
        Structure: "Subway",
        GTFSLatitude: "40.755882",
        GTFSLongitude: "-74.00191",
    },
    {
        StationID: "475",
        ComplexID: "475",
        GTFS_StopID: "Q05",
        Division: "IND",
        Line: "Second Av",
        StopName: "96 St",
        Borough: "M",
        DaytimeRoutes: "Q",
        Structure: "Subway",
        GTFSLatitude: "40.784318",
        GTFSLongitude: "-73.947152",
    },
    {
        StationID: "476",
        ComplexID: "476",
        GTFS_StopID: "Q04",
        Division: "IND",
        Line: "Second Av",
        StopName: "86 St",
        Borough: "M",
        DaytimeRoutes: "Q",
        Structure: "Subway",
        GTFSLatitude: "40.777891",
        GTFSLongitude: "-73.951787",
    },
    {
        StationID: "477",
        ComplexID: "477",
        GTFS_StopID: "Q03",
        Division: "IND",
        Line: "Second Av",
        StopName: "72 St",
        Borough: "M",
        DaytimeRoutes: "Q",
        Structure: "Subway",
        GTFSLatitude: "40.768799",
        GTFSLongitude: "-73.958424",
    },
    {
        StationID: "501",
        ComplexID: "501",
        GTFS_StopID: "S31",
        Division: "SIR",
        Line: "Staten Island",
        StopName: "St George",
        Borough: "SI",
        DaytimeRoutes: "SIR",
        Structure: "Open Cut",
        GTFSLatitude: "40.643748",
        GTFSLongitude: "-74.073643",
    },
    {
        StationID: "502",
        ComplexID: "502",
        GTFS_StopID: "S30",
        Division: "SIR",
        Line: "Staten Island",
        StopName: "Tompkinsville",
        Borough: "SI",
        DaytimeRoutes: "SIR",
        Structure: "At Grade",
        GTFSLatitude: "40.636949",
        GTFSLongitude: "-74.074835",
    },
    {
        StationID: "503",
        ComplexID: "503",
        GTFS_StopID: "S29",
        Division: "SIR",
        Line: "Staten Island",
        StopName: "Stapleton",
        Borough: "SI",
        DaytimeRoutes: "SIR",
        Structure: "Elevated",
        GTFSLatitude: "40.627915",
        GTFSLongitude: "-74.075162",
    },
    {
        StationID: "504",
        ComplexID: "504",
        GTFS_StopID: "S28",
        Division: "SIR",
        Line: "Staten Island",
        StopName: "Clifton",
        Borough: "SI",
        DaytimeRoutes: "SIR",
        Structure: "Elevated",
        GTFSLatitude: "40.621319",
        GTFSLongitude: "-74.071402",
    },
    {
        StationID: "505",
        ComplexID: "505",
        GTFS_StopID: "S27",
        Division: "SIR",
        Line: "Staten Island",
        StopName: "Grasmere",
        Borough: "SI",
        DaytimeRoutes: "SIR",
        Structure: "Open Cut",
        GTFSLatitude: "40.603117",
        GTFSLongitude: "-74.084087",
    },
    {
        StationID: "506",
        ComplexID: "506",
        GTFS_StopID: "S26",
        Division: "SIR",
        Line: "Staten Island",
        StopName: "Old Town",
        Borough: "SI",
        DaytimeRoutes: "SIR",
        Structure: "Embankment",
        GTFSLatitude: "40.596612",
        GTFSLongitude: "-74.087368",
    },
    {
        StationID: "507",
        ComplexID: "507",
        GTFS_StopID: "S25",
        Division: "SIR",
        Line: "Staten Island",
        StopName: "Dongan Hills",
        Borough: "SI",
        DaytimeRoutes: "SIR",
        Structure: "Embankment",
        GTFSLatitude: "40.588849",
        GTFSLongitude: "-74.09609",
    },
    {
        StationID: "508",
        ComplexID: "508",
        GTFS_StopID: "S24",
        Division: "SIR",
        Line: "Staten Island",
        StopName: "Jefferson Av",
        Borough: "SI",
        DaytimeRoutes: "SIR",
        Structure: "Embankment",
        GTFSLatitude: "40.583591",
        GTFSLongitude: "-74.103338",
    },
    {
        StationID: "509",
        ComplexID: "509",
        GTFS_StopID: "S23",
        Division: "SIR",
        Line: "Staten Island",
        StopName: "Grant City",
        Borough: "SI",
        DaytimeRoutes: "SIR",
        Structure: "Open Cut",
        GTFSLatitude: "40.578965",
        GTFSLongitude: "-74.109704",
    },
    {
        StationID: "510",
        ComplexID: "510",
        GTFS_StopID: "S22",
        Division: "SIR",
        Line: "Staten Island",
        StopName: "New Dorp",
        Borough: "SI",
        DaytimeRoutes: "SIR",
        Structure: "Open Cut",
        GTFSLatitude: "40.57348",
        GTFSLongitude: "-74.11721",
    },
    {
        StationID: "511",
        ComplexID: "511",
        GTFS_StopID: "S21",
        Division: "SIR",
        Line: "Staten Island",
        StopName: "Oakwood Heights",
        Borough: "SI",
        DaytimeRoutes: "SIR",
        Structure: "Open Cut",
        GTFSLatitude: "40.56511",
        GTFSLongitude: "-74.12632",
    },
    {
        StationID: "512",
        ComplexID: "512",
        GTFS_StopID: "S20",
        Division: "SIR",
        Line: "Staten Island",
        StopName: "Bay Terrace",
        Borough: "SI",
        DaytimeRoutes: "SIR",
        Structure: "Embankment",
        GTFSLatitude: "40.5564",
        GTFSLongitude: "-74.136907",
    },
    {
        StationID: "513",
        ComplexID: "513",
        GTFS_StopID: "S19",
        Division: "SIR",
        Line: "Staten Island",
        StopName: "Great Kills",
        Borough: "SI",
        DaytimeRoutes: "SIR",
        Structure: "Open Cut",
        GTFSLatitude: "40.551231",
        GTFSLongitude: "-74.151399",
    },
    {
        StationID: "514",
        ComplexID: "514",
        GTFS_StopID: "S18",
        Division: "SIR",
        Line: "Staten Island",
        StopName: "Eltingville",
        Borough: "SI",
        DaytimeRoutes: "SIR",
        Structure: "Embankment",
        GTFSLatitude: "40.544601",
        GTFSLongitude: "-74.16457",
    },
    {
        StationID: "515",
        ComplexID: "515",
        GTFS_StopID: "S17",
        Division: "SIR",
        Line: "Staten Island",
        StopName: "Annadale",
        Borough: "SI",
        DaytimeRoutes: "SIR",
        Structure: "Open Cut",
        GTFSLatitude: "40.54046",
        GTFSLongitude: "-74.178217",
    },
    {
        StationID: "516",
        ComplexID: "516",
        GTFS_StopID: "S16",
        Division: "SIR",
        Line: "Staten Island",
        StopName: "Huguenot",
        Borough: "SI",
        DaytimeRoutes: "SIR",
        Structure: "Open Cut",
        GTFSLatitude: "40.533674",
        GTFSLongitude: "-74.191794",
    },
    {
        StationID: "517",
        ComplexID: "517",
        GTFS_StopID: "S15",
        Division: "SIR",
        Line: "Staten Island",
        StopName: "Prince's Bay",
        Borough: "SI",
        DaytimeRoutes: "SIR",
        Structure: "Open Cut",
        GTFSLatitude: "40.525507",
        GTFSLongitude: "-74.200064",
    },
    {
        StationID: "518",
        ComplexID: "518",
        GTFS_StopID: "S14",
        Division: "SIR",
        Line: "Staten Island",
        StopName: "Pleasant Plains",
        Borough: "SI",
        DaytimeRoutes: "SIR",
        Structure: "Embankment",
        GTFSLatitude: "40.52241",
        GTFSLongitude: "-74.217847",
    },
    {
        StationID: "519",
        ComplexID: "519",
        GTFS_StopID: "S13",
        Division: "SIR",
        Line: "Staten Island",
        StopName: "Richmond Valley",
        Borough: "SI",
        DaytimeRoutes: "SIR",
        Structure: "Open Cut",
        GTFSLatitude: "40.519631",
        GTFSLongitude: "-74.229141",
    },
    {
        StationID: "522",
        ComplexID: "522",
        GTFS_StopID: "S09",
        Division: "SIR",
        Line: "Staten Island",
        StopName: "Tottenville",
        Borough: "SI",
        DaytimeRoutes: "SIR",
        Structure: "At Grade",
        GTFSLatitude: "40.512764",
        GTFSLongitude: "-74.251961",
    },
    {
        StationID: "523",
        ComplexID: "523",
        GTFS_StopID: "S11",
        Division: "SIR",
        Line: "Staten Island",
        StopName: "Arthur Kill",
        Borough: "SI",
        DaytimeRoutes: "SIR",
        Structure: "At Grade",
        GTFSLatitude: "40.516578",
        GTFSLongitude: "-74.242096",
    },
];
