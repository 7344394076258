import React from 'react';
import './App.css';
import { HashRouter, Switch, Route } from 'react-router-dom';
import { PageNotFoundComponent } from './components/notification/PageNotFoundComponent';
import { LoginComponent } from './components/login/LoginComponent';
import { LogoutComponent } from './components/login/LogoutComponent';
import { SubwayComponent } from './components/subway/SubwayComponent';
import { LIRRComponent } from './components/railroad/LIRRComponent' 
import { MNRComponent } from './components/railroad/MNRComponent';
import { FullScreenComponent } from './components/common/FullScreenComponent';
import { HeliumFullScreenComponent } from './components/common/HeliumFullScreenComponent';
import { authRequired } from './AuthComponentWrapper';
import Globals from './Globals';
import { ReleaseComponent } from './components/releaseNotes/ReleaseComponent';
import { SubwayScreenComponent } from './components/helium/SubwayScreenComponent';
import { PrivateRoute } from './components/common/PrivateRoute';

const App: React.FC = () => {
  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" component={LoginComponent} />
        <Route exact path={Globals.ROUTE_PATH.login} component={LoginComponent} />
        <Route exact path="/logout" component={LogoutComponent} />
        <PrivateRoute exact path={Globals.ROUTE_PATH.home} component={SubwayScreenComponent} />
        <PrivateRoute exact path="/subwayscreens" component={SubwayScreenComponent} />
        <PrivateRoute exact path="/subway" component={SubwayComponent} />
        <PrivateRoute exact path="/lirr" component={LIRRComponent} />
        <PrivateRoute exact path="/mnr" component={MNRComponent} />
        <PrivateRoute exact path="/releases" component={ReleaseComponent} />
        <PrivateRoute path="/screen/:stationId/:templateId/:track?" component={FullScreenComponent} />
        <PrivateRoute path="/heliumscreen/:device/:type" component={HeliumFullScreenComponent} />
        <Route component={PageNotFoundComponent} />
      </Switch>
    </HashRouter>
  );
}

export default App;
