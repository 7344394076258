import queryString from "query-string";
import { ClientConfigDto } from "./interfaces/IApiErrorDto";
import Globals from "./Globals";
import { ITemplateInfo } from "./components/common/interfaces/ITemplateInfo";

const APP_CONFIG_KEY: string = "__MTA_APP_CONFIG__";

export function setAppConfig(config: ClientConfigDto) {
  (window as any)[APP_CONFIG_KEY] = config;
  console.debug("process.env ", process.env);
  console.debug(config);
}

export function getAppConfig(): ClientConfigDto {
  return (window as any)[APP_CONFIG_KEY];
}

export function getBaseUrl() {
  const baseUrl = process.env.REACT_APP_AppBaseUrl;
  return baseUrl;
}

export function getDefaultScreenUrl() {
  const baseUrl = process.env.REACT_APP_AppBaseUrl;
  return `/assets/images/defaultscreen.svg`
}

export function getScreenUrl(
  stationId: string,
  template: ITemplateInfo,
  track: string
) {
  const baseUrl = process.env.REACT_APP_ScreenBaseUrl;
  const env = (template.env !== "" ? template.env : process.env.REACT_APP_ENV);
  const queryParams = `id=${stationId.trim()}&env=${env?.trim()}&dir=${template.Direction.trim()}&scr=${template.Screen.trim()}&track=${track}`;
  return `${baseUrl}/${template.Path}?${queryParams}`;
}

export function getScreenUrlByDevice(
  deviceId: string
) {
  const baseUrl = process.env.REACT_APP_Helium_ScreenBaseUrl;
  return `${baseUrl}?device=${deviceId}`;
}

export function getLoginUrl() {
  const baseUrl = process.env.REACT_APP_ApiBaseUrl;
  const config = getAppConfig();
  const queryParams = queryString.stringify({
    redirect_uri: process.env.REACT_APP_RedirectUrl,
  });

  if (config.EnableMockLogin) {
    return `${baseUrl}/${config.SSO.MockLogin}?${queryParams}`;
  }
  return `${baseUrl}/${config.SSO.Login}?${queryParams}`;
}

export function getLogoutUrl() {
  const baseUrl = process.env.REACT_APP_ApiBaseUrl;
  const config = getAppConfig();

  if (config.EnableMockLogin) {
    return `${baseUrl}/${config.SSO.MockLogout}`;
  }
  return `${baseUrl}/${config.SSO.Logout}`;
}

export function getWellknownUrl() {
  return `/.well-known/client-config`;
}

export function buildLoginUrlWithError(error: any) {
  return `#${Globals.ROUTE_PATH.login}?loginError=${error}`;
}

export function getLoginUrlError(location: any) {
  if (!location) {
    return;
  }
  const {
    query: { loginError },
  } = queryString.parseUrl(location.search);
  return loginError;
}
