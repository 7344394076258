import React, { Component } from "react";
import { RouteComponentProps } from 'react-router'
import "./Login.css";
import axios from "axios";
import { getAppConfig, getLogoutUrl } from "../../AppConfig";
import { LoadingSpinner } from "../common/LoadingSpinner";

export class LogoutComponent extends Component<RouteComponentProps> {

    constructor(props: RouteComponentProps) {
        super(props);

        const logoutUrl = getLogoutUrl();

        if (getAppConfig().EnableMockLogin) {
            axios.get(logoutUrl).finally(() => {
                this.props.history.push('/login');
            });
        }
        else {
            console.log('redirect to SLO ' + logoutUrl);
            window.location.href = logoutUrl;
        }
    }

    render() {
        return <LoadingSpinner />
    }
}
