import { ILIRRStationInfo } from "../common/interfaces/ILIRRStationInfo";

export const stationInfoJsonArray: ILIRRStationInfo[] = [
    {
        code: "GCT",
        GTFS_StopID: "GCT",
        branch: "CityTerminalZone",
        name: "Grand Central",
        latitude: 40.755162,
        longitude: -73.975455,
        westName: "Arrivals",
        eastName: "Departures",
        zone: 1,
        exclude: false,
        old_StopID: "3",
    },
	{
        code: "JAM",
        GTFS_StopID: "102",
        branch: "CityTerminalZone",
        name: "Jamaica",
        latitude: 40.69962,
        longitude: -73.808431,
        westName: "To Points West",
        eastName: "To Points East",
        zone: 3,
        exclude: false,
        old_StopID: "15",
    },
	{
        code: "NYK",
        GTFS_StopID: "237",
        branch: "CityTerminalZone",
        name: "Penn Station",
        latitude: 40.750327,
        longitude: -73.99296,
        westName: "Arrivals",
        eastName: "Long Island",
        zone: 1,
        exclude: false,
        old_StopID: "8",
    },
    {
        code: "ABT",
        GTFS_StopID: "1",
        branch: "OysterBay",
        name: "Albertson",
        latitude: 40.771983,
        longitude: -73.641702,
        westName: "To New York & Points West",
        eastName: "To Oyster Bay",
        zone: 7,
        exclude: false,
        old_StopID: "44",
    },
    {
        code: "AGT",
        GTFS_StopID: "2",
        branch: "Montauk",
        name: "Amagansett",
        latitude: 40.980035,
        longitude: -72.132594,
        westName: "To New York & Points West",
        eastName: "To Montauk",
        zone: 14,
        exclude: false,
        old_StopID: "137",
    },
    {
        code: "AVL",
        GTFS_StopID: "8",
        branch: "Babylon",
        name: "Amityville",
        latitude: 40.680303,
        longitude: -73.420321,
        westName: "To New York & Points West",
        eastName: "To Babylon & Points East",
        zone: 9,
        exclude: false,
        old_StopID: "115",
    },
    {
        code: "ATL",
        GTFS_StopID: "241",
        branch: "CityTerminalZone",
        name: "Atlantic Terminal",
        latitude: 40.684457,
        longitude: -73.97703,
        westName: "Arrivals",
        eastName: "Departures",
        zone: 1,
        exclude: false,
        old_StopID: "12",
    },
    {
        code: "ADL",
        GTFS_StopID: "2",
        branch: "PortWashington",
        name: "Auburndale",
        latitude: 40.761482,
        longitude: -73.789741,
        westName: "To New York",
        eastName: "To Great Neck & Port Washington",
        zone: 3,
        exclude: false,
        old_StopID: "21",
    },
    {
        code: "BTA",
        GTFS_StopID: "27",
        branch: "Babylon",
        name: "Babylon",
        latitude: 40.700764,
        longitude: -73.324088,
        westName: "To New York & Points West",
        eastName: "To Babylon & Points East",
        zone: 9,
        exclude: false,
        old_StopID: "118",
    },
    {
        code: "BWN",
        GTFS_StopID: "225",
        branch: "Babylon",
        name: "Baldwin",
        latitude: 40.656785,
        longitude: -73.607162,
        westName: "To New York & Points West",
        eastName: "To Babylon & Points East",
        zone: 7,
        exclude: false,
        old_StopID: "107",
    },
    {
        code: "BSR",
        GTFS_StopID: "26",
        branch: "Montauk",
        name: "Bay Shore",
        latitude: 40.724613,
        longitude: -73.253743,
        westName: "To New York & Points West",
        eastName: "To Patchogue, Speonk, & Montauk",
        zone: 10,
        exclude: false,
        old_StopID: "119",
    },
    {
        code: "BSD",
        GTFS_StopID: "25",
        branch: "PortWashington",
        name: "Bayside",
        latitude: 40.763185,
        longitude: -73.77084,
        westName: "To New York",
        eastName: "To Great Neck & Port Washington",
        zone: 3,
        exclude: false,
        old_StopID: "22",
    },
    {
        code: "BRS",
        GTFS_StopID: "23",
        branch: "Hempstead",
        name: "Bellerose",
        latitude: 40.722218,
        longitude: -73.716485,
        westName: "To New York & Points West",
        eastName: "To Hempstead",
        zone: 4,
        exclude: false,
        old_StopID: "33",
    },
    {
        code: "BMR",
        GTFS_StopID: "16",
        branch: "Babylon",
        name: "Bellmore",
        latitude: 40.668832,
        longitude: -73.528888,
        westName: "To New York & Points West",
        eastName: "To Babylon & Points East",
        zone: 7,
        exclude: false,
        old_StopID: "110",
    },
    {
        code: "BPT",
        GTFS_StopID: "21",
        branch: "Montauk",
        name: "Bellport",
        latitude: 40.773769,
        longitude: -72.943635,
        westName: "To New York & Points West",
        eastName: "To Speonk & Montauk",
        zone: 12,
        exclude: false,
        old_StopID: "126",
    },
    {
        code: "BRT",
        GTFS_StopID: "24",
        branch: "Belmont",
        name: "Belmont Park",
        latitude: 40.713688,
        longitude: -73.728297,
        westName: "Departures",
        eastName: "Arrivals",
        zone: 4,
        exclude: false,
        old_StopID: "32",
    },
    {
        code: "BPG",
        GTFS_StopID: "20",
        branch: "Ronkonkoma",
        name: "Bethpage",
        latitude: 40.743142,
        longitude: -73.483569,
        westName: "To New York & Points West",
        eastName: "To Ronkonkoma & Points East",
        zone: 7,
        exclude: false,
        old_StopID: "68",
    },
    {
        code: "BOL",
        GTFS_StopID: "19",
        branch: "CityTerminalZone",
        name: "Bolands-Employees",
        latitude: 40.695743,
        longitude: -73.82192,
        westName: "To Atlantic Terminal",
        eastName: "To Jamaica & Points East",
        zone: 1,
        exclude: true,
        old_StopID: "",
    },
    {
        code: "BWD",
        GTFS_StopID: "29",
        branch: "Ronkonkoma",
        name: "Brentwood",
        latitude: 40.780926,
        longitude: -73.243249,
        westName: "To New York & Points West",
        eastName: "To Ronkonkoma & Points East",
        zone: 10,
        exclude: false,
        old_StopID: "73",
    },
    {
        code: "BHN",
        GTFS_StopID: "13",
        branch: "Montauk",
        name: "Bridgehampton",
        latitude: 40.93911,
        longitude: -72.309799,
        westName: "To New York & Points West",
        eastName: "To Montauk",
        zone: 14,
        exclude: false,
        old_StopID: "135",
    },
    {
        code: "BDY",
        GTFS_StopID: "11",
        branch: "PortWashington",
        name: "Broadway",
        latitude: 40.761653,
        longitude: -73.80182,
        westName: "To New York",
        eastName: "To Great Neck & Port Washington",
        zone: 3,
        exclude: false,
        old_StopID: "20",
    },
    {
        code: "CPL",
        GTFS_StopID: "39",
        branch: "PortJefferson",
        name: "Carle Place",
        latitude: 40.749272,
        longitude: -73.603454,
        westName: "To New York & Points West",
        eastName: "To Points East",
        zone: 7,
        exclude: false,
        old_StopID: "54",
    },
    {
        code: "CHT",
        GTFS_StopID: "32",
        branch: "FarRockaway",
        name: "Cedarhurst",
        latitude: 40.622331,
        longitude: -73.725958,
        westName: "To New York & Points West",
        eastName: "To Far Rockaway",
        zone: 4,
        exclude: false,
        old_StopID: "96",
    },
    {
        code: "CI",
        GTFS_StopID: "33",
        branch: "Ronkonkoma",
        name: "Central Islip",
        latitude: 40.791911,
        longitude: -73.194415,
        westName: "To New York & Points West",
        eastName: "To Ronkonkoma & Points East",
        zone: 10,
        exclude: false,
        old_StopID: "74",
    },
    {
        code: "CAV",
        GTFS_StopID: "31",
        branch: "LongBeach",
        name: "Centre Av",
        latitude: 40.648283,
        longitude: -73.663906,
        westName: "To New York & Points West",
        eastName: "To Long Beach",
        zone: 7,
        exclude: false,
        old_StopID: "101",
    },
    {
        code: "CSH",
        GTFS_StopID: "40",
        branch: "PortJefferson",
        name: "Cold Spring Harbor",
        latitude: 40.835623,
        longitude: -73.451128,
        westName: "To New York & Points West",
        eastName: "To Huntington & Port Jefferson",
        zone: 9,
        exclude: false,
        old_StopID: "59",
    },
    {
        code: "CPG",
        GTFS_StopID: "38",
        branch: "Babylon",
        name: "Copiague",
        latitude: 40.681063,
        longitude: -73.398343,
        westName: "To New York & Points West",
        eastName: "To Babylon & Points East",
        zone: 9,
        exclude: false,
        old_StopID: "116",
    },
    {
        code: "CLP",
        GTFS_StopID: "36",
        branch: "Hempstead",
        name: "Country Life Press",
        latitude: 40.721375,
        longitude: -73.629572,
        westName: "To New York & Points West",
        eastName: "To Hempstead",
        zone: 4,
        exclude: false,
        old_StopID: "38",
    },
    {
        code: "DPK",
        GTFS_StopID: "44",
        branch: "Ronkonkoma",
        name: "Deer Park",
        latitude: 40.769541,
        longitude: -73.293362,
        westName: "To New York & Points West",
        eastName: "To Ronkonkoma & Points East",
        zone: 9,
        exclude: false,
        old_StopID: "72",
    },
    {
        code: "DGL",
        GTFS_StopID: "42",
        branch: "PortWashington",
        name: "Douglaston",
        latitude: 40.76812,
        longitude: -73.749332,
        westName: "To New York",
        eastName: "To Great Neck & Port Washington",
        zone: 3,
        exclude: false,
        old_StopID: "23",
    },
    {
        code: "EHN",
        GTFS_StopID: "48",
        branch: "Montauk",
        name: "East Hampton",
        latitude: 40.965138,
        longitude: -72.1929,
        westName: "To New York & Points West",
        eastName: "To Montauk",
        zone: 14,
        exclude: false,
        old_StopID: "136",
    },
    {
        code: "ENY",
        GTFS_StopID: "50",
        branch: "CityTerminalZone",
        name: "East New York",
        latitude: 40.67595,
        longitude: -73.903748,
        westName: "To Atlantic Terminal",
        eastName: "To Jamaica & Points East",
        zone: 1,
        exclude: false,
        old_StopID: "14",
    },
    {
        code: "ERY",
        GTFS_StopID: "51",
        branch: "LongBeach",
        name: "East Rockaway",
        latitude: 40.642152,
        longitude: -73.658134,
        westName: "To New York & Points West",
        eastName: "To Long Beach",
        zone: 7,
        exclude: false,
        old_StopID: "102",
    },
    {
        code: "EWN",
        GTFS_StopID: "52",
        branch: "OysterBay",
        name: "East Williston",
        latitude: 40.75629,
        longitude: -73.639463,
        westName: "To New York & Points West",
        eastName: "To Oyster Bay",
        zone: 7,
        exclude: false,
        old_StopID: "43",
    },
    {
        code: "FRY",
        GTFS_StopID: "65",
        branch: "FarRockaway",
        name: "Far Rockaway",
        latitude: 40.609159,
        longitude: -73.750594,
        westName: "Departures",
        eastName: "Arrivals",
        zone: 4,
        exclude: false,
        old_StopID: "99",
    },
    {
        code: "FMD",
        GTFS_StopID: "59",
        branch: "Ronkonkoma",
        name: "Farmingdale",
        latitude: 40.735959,
        longitude: -73.441095,
        westName: "To New York & Points West",
        eastName: "To Ronkonkoma & Points East",
        zone: 7,
        exclude: false,
        old_StopID: "69",
    },
    {
        code: "FPK",
        GTFS_StopID: "63",
        branch: "Hempstead",
        name: "Floral Park",
        latitude: 40.724657,
        longitude: -73.706059,
        westName: "To New York & Points West",
        eastName: "To Hempstead & Points East",
        zone: 4,
        exclude: false,
        old_StopID: "34",
    },
    {
        code: "FLS",
        GTFS_StopID: "56",
        branch: "PortWashington",
        name: "Flushing Main Street",
        latitude: 40.758021,
        longitude: -73.83107,
        westName: "To New York",
        eastName: "To Great Neck & Port Washington",
        zone: 3,
        exclude: false,
        old_StopID: "18",
    },
    {
        code: "FHL",
        GTFS_StopID: "55",
        branch: "CityTerminalZone",
        name: "Forest Hills",
        latitude: 40.719599,
        longitude: -73.844835,
        westName: "To New York",
        eastName: "To Jamaica & Points East",
        zone: 1,
        exclude: false,
        old_StopID: "10",
    },
    {
        code: "FPT",
        GTFS_StopID: "64",
        branch: "Babylon",
        name: "Freeport",
        latitude: 40.657497,
        longitude: -73.582341,
        westName: "To New York & Points West",
        eastName: "To Babylon & Points East",
        zone: 7,
        exclude: false,
        old_StopID: "108",
    },
    {
        code: "GCY",
        GTFS_StopID: "68",
        branch: "Hempstead",
        name: "Garden City",
        latitude: 40.723088,
        longitude: -73.64052,
        westName: "To New York & Points West",
        eastName: "To Hempstead",
        zone: 4,
        exclude: false,
        old_StopID: "37",
    },
    {
        code: "GBN",
        GTFS_StopID: "66",
        branch: "FarRockaway",
        name: "Gibson",
        latitude: 40.64921,
        longitude: -73.701834,
        westName: "To New York & Points West",
        eastName: "To Far Rockaway",
        zone: 4,
        exclude: false,
        old_StopID: "93",
    },
    {
        code: "GCV",
        GTFS_StopID: "67",
        branch: "OysterBay",
        name: "Glen Cove",
        latitude: 40.865693,
        longitude: -73.61638,
        westName: "To New York & Points West",
        eastName: "To Oyster Bay",
        zone: 7,
        exclude: false,
        old_StopID: "50",
    },
    {
        code: "GHD",
        GTFS_StopID: "71",
        branch: "OysterBay",
        name: "Glen Head",
        latitude: 40.832265,
        longitude: -73.626147,
        westName: "To New York & Points West",
        eastName: "To Oyster Bay",
        zone: 7,
        exclude: false,
        old_StopID: "47",
    },
    {
        code: "GST",
        GTFS_StopID: "76",
        branch: "OysterBay",
        name: "Glen Street",
        latitude: 40.858222,
        longitude: -73.620793,
        westName: "To New York & Points West",
        eastName: "To Oyster Bay",
        zone: 7,
        exclude: false,
        old_StopID: "49",
    },
    {
        code: "GNK",
        GTFS_StopID: "72",
        branch: "PortWashington",
        name: "Great Neck",
        latitude: 40.787182,
        longitude: -73.726264,
        westName: "To New York",
        eastName: "To Great Neck & Port Washington",
        zone: 4,
        exclude: false,
        old_StopID: "25",
    },
    {
        code: "GRV",
        GTFS_StopID: "74",
        branch: "Montauk",
        name: "Great River",
        latitude: 40.74048,
        longitude: -73.169999,
        westName: "To New York & Points West",
        eastName: "To Patchogue, Speonk, & Montauk",
        zone: 10,
        exclude: false,
        old_StopID: "121",
    },
    {
        code: "GWN",
        GTFS_StopID: "78",
        branch: "PortJefferson",
        name: "Greenlawn",
        latitude: 40.868624,
        longitude: -73.363003,
        westName: "To New York & Points West",
        eastName: "To Port Jefferson",
        zone: 9,
        exclude: false,
        old_StopID: "61",
    },
    {
        code: "GPT",
        GTFS_StopID: "73",
        branch: "Ronkonkoma",
        name: "Greenport",
        latitude: 41.099829,
        longitude: -72.362606,
        westName: "Departures",
        eastName: "Arrivals",
        zone: 14,
        exclude: false,
        old_StopID: "82",
    },
    {
        code: "GVL",
        GTFS_StopID: "77",
        branch: "OysterBay",
        name: "Greenvale",
        latitude: 40.81555,
        longitude: -73.626944,
        westName: "To New York & Points West",
        eastName: "To Oyster Bay",
        zone: 7,
        exclude: false,
        old_StopID: "46",
    },
    {
        code: "HBY",
        GTFS_StopID: "83",
        branch: "Montauk",
        name: "Hampton Bays",
        latitude: 40.87665,
        longitude: -72.523858,
        westName: "To New York & Points West",
        eastName: "To Montauk",
        zone: 14,
        exclude: false,
        old_StopID: "132",
    },
    {
        code: "HEM",
        GTFS_StopID: "84",
        branch: "Hempstead",
        name: "Hempstead",
        latitude: 40.713165,
        longitude: -73.625164,
        westName: "Departures",
        eastName: "Arrivals",
        zone: 4,
        exclude: false,
        old_StopID: "39",
    },
    {
        code: "HGN",
        GTFS_StopID: "85",
        branch: "WestHempstead",
        name: "Hempstead Gardens",
        latitude: 40.694909,
        longitude: -73.646204,
        westName: "To New York & Points West",
        eastName: "To West Hempstead",
        zone: 4,
        exclude: false,
        old_StopID: "91",
    },
    {
        code: "HWT",
        GTFS_StopID: "94",
        branch: "FarRockaway",
        name: "Hewlett",
        latitude: 40.636698,
        longitude: -73.705231,
        westName: "To New York & Points West",
        eastName: "To Far Rockaway",
        zone: 4,
        exclude: false,
        old_StopID: "94",
    },
    {
        code: "HVL",
        GTFS_StopID: "92",
        branch: "PortJefferson",
        name: "Hicksville",
        latitude: 40.767145,
        longitude: -73.528523,
        westName: "To New York & Points West",
        eastName: "To Points East",
        zone: 7,
        exclude: false,
        old_StopID: "56",
    },
    {
        code: "HIL",
        GTFS_StopID: "86",
        branch: "CityTerminalZone",
        name: "Hillside-Employees",
        latitude: 40.707931,
        longitude: -73.776086,
        westName: "To New York & Points West",
        eastName: "To Points East",
        zone: 3,
        exclude: false,
        old_StopID: "",
    },
    {
        code: "HOL",
        GTFS_StopID: "89",
        branch: "Hempstead",
        name: "Hollis",
        latitude: 40.710227,
        longitude: -73.766608,
        westName: "To New York & Points West",
        eastName: "To Hempstead",
        zone: 3,
        exclude: false,
        old_StopID: "30",
    },
    {
        code: "HPA",
        GTFS_StopID: "90",
        branch: "CityTerminalZone",
        name: "Hunterspoint Av",
        latitude: 40.742329,
        longitude: -73.947028,
        westName: "To Long Island City",
        eastName: "To Points East",
        zone: 1,
        exclude: false,
        old_StopID: "2",
    },
    {
        code: "HUN",
        GTFS_StopID: "91",
        branch: "PortJefferson",
        name: "Huntington",
        latitude: 40.853013,
        longitude: -73.409539,
        westName: "To New York & Points West",
        eastName: "To Huntington & Port Jefferson",
        zone: 9,
        exclude: false,
        old_StopID: "60",
    },
    {
        code: "IWD",
        GTFS_StopID: "101",
        branch: "FarRockaway",
        name: "Inwood",
        latitude: 40.612295,
        longitude: -73.744142,
        westName: "To New York & Points West",
        eastName: "To Far Rockaway",
        zone: 4,
        exclude: false,
        old_StopID: "98",
    },
    {
        code: "IPK",
        GTFS_StopID: "99",
        branch: "LongBeach",
        name: "Island Park",
        latitude: 40.601413,
        longitude: -73.654586,
        westName: "To New York & Points West",
        eastName: "To Long Beach",
        zone: 7,
        exclude: false,
        old_StopID: "104",
    },
    {
        code: "ISP",
        GTFS_StopID: "100",
        branch: "Montauk",
        name: "Islip",
        latitude: 40.73593,
        longitude: -73.208911,
        westName: "To New York & Points West",
        eastName: "To Patchogue, Speonk, & Montauk",
        zone: 10,
        exclude: false,
        old_StopID: "120",
    },
    {
        code: "KGN",
        GTFS_StopID: "107",
        branch: "CityTerminalZone",
        name: "Kew Gardens",
        latitude: 40.709704,
        longitude: -73.830926,
        westName: "To New York",
        eastName: "To Jamaica & Points East",
        zone: 1,
        exclude: false,
        old_StopID: "11",
    },
    {
        code: "KPK",
        GTFS_StopID: "111",
        branch: "PortJefferson",
        name: "Kings Park",
        latitude: 40.883688,
        longitude: -73.256006,
        westName: "To New York & Points West",
        eastName: "To Port Jefferson",
        zone: 10,
        exclude: false,
        old_StopID: "63",
    },
    {
        code: "LVW",
        GTFS_StopID: "124",
        branch: "WestHempstead",
        name: "Lakeview",
        latitude: 40.685848,
        longitude: -73.652138,
        westName: "To New York & Points West",
        eastName: "To West Hempstead",
        zone: 4,
        exclude: false,
        old_StopID: "90",
    },
    {
        code: "LTN",
        GTFS_StopID: "122",
        branch: "FarRockaway",
        name: "Laurelton",
        latitude: 40.668531,
        longitude: -73.751796,
        westName: "To New York",
        eastName: "To Points East",
        zone: 3,
        exclude: false,
        old_StopID: "85",
    },
    {
        code: "LCE",
        GTFS_StopID: "114",
        branch: "FarRockaway",
        name: "Lawrence",
        latitude: 40.615761,
        longitude: -73.735837,
        westName: "To New York & Points West",
        eastName: "To Far Rockaway",
        zone: 4,
        exclude: false,
        old_StopID: "97",
    },
    {
        code: "LHT",
        GTFS_StopID: "117",
        branch: "Babylon",
        name: "Lindenhurst",
        latitude: 40.688285,
        longitude: -73.369284,
        westName: "To New York & Points West",
        eastName: "To Babylon & Points East",
        zone: 9,
        exclude: false,
        old_StopID: "117",
    },
    {
        code: "LNK",
        GTFS_StopID: "120",
        branch: "PortWashington",
        name: "Little Neck",
        latitude: 40.775099,
        longitude: -73.740564,
        westName: "To New York",
        eastName: "To Great Neck & Port Washington",
        zone: 3,
        exclude: false,
        old_StopID: "24",
    },
    {
        code: "LMR",
        GTFS_StopID: "119",
        branch: "FarRockaway",
        name: "Locust Manor",
        latitude: 40.675165,
        longitude: -73.76508,
        westName: "To New York",
        eastName: "To Points East",
        zone: 3,
        exclude: false,
        old_StopID: "84",
    },
    {
        code: "LVL",
        GTFS_StopID: "123",
        branch: "OysterBay",
        name: "Locust Valley",
        latitude: 40.874489,
        longitude: -73.598286,
        westName: "To New York & Points West",
        eastName: "To Oyster Bay",
        zone: 7,
        exclude: false,
        old_StopID: "51",
    },
    {
        code: "LBH",
        GTFS_StopID: "113",
        branch: "LongBeach",
        name: "Long Beach",
        latitude: 40.590472,
        longitude: -73.664525,
        westName: "Departures",
        eastName: "Arrivals",
        zone: 7,
        exclude: false,
        old_StopID: "105",
    },
    {
        code: "LIC",
        GTFS_StopID: "118",
        branch: "CityTerminalZone",
        name: "Long Island City",
        latitude: 40.741356,
        longitude: -73.956031,
        westName: "Arrivals",
        eastName: "Departures",
        zone: 1,
        exclude: false,
        old_StopID: "1",
    },
    {
        code: "LYN",
        GTFS_StopID: "125",
        branch: "LongBeach",
        name: "Lynbrook",
        latitude: 40.656154,
        longitude: -73.67604,
        westName: "To New York & Points West",
        eastName: "To Points East",
        zone: 4,
        exclude: false,
        old_StopID: "100",
    },
    {
        code: "MVN",
        GTFS_StopID: "142",
        branch: "WestHempstead",
        name: "Malverne",
        latitude: 40.675501,
        longitude: -73.66881,
        westName: "To New York & Points West",
        eastName: "To West Hempstead",
        zone: 4,
        exclude: false,
        old_StopID: "89",
    },
    {
        code: "MHT",
        GTFS_StopID: "131",
        branch: "PortWashington",
        name: "Manhasset",
        latitude: 40.796749,
        longitude: -73.699837,
        westName: "To New York",
        eastName: "To Port Washington",
        zone: 4,
        exclude: false,
        old_StopID: "26",
    },
    {
        code: "MQA",
        GTFS_StopID: "136",
        branch: "Babylon",
        name: "Massapequa",
        latitude: 40.676969,
        longitude: -73.468985,
        westName: "To New York & Points West",
        eastName: "To Babylon & Points East",
        zone: 7,
        exclude: false,
        old_StopID: "113",
    },
    {
        code: "MPK",
        GTFS_StopID: "135",
        branch: "Babylon",
        name: "Massapequa Park",
        latitude: 40.677901,
        longitude: -73.454667,
        westName: "To New York & Points West",
        eastName: "To Babylon & Points East",
        zone: 7,
        exclude: false,
        old_StopID: "114",
    },
    {
        code: "MSY",
        GTFS_StopID: "140",
        branch: "Montauk",
        name: "Mastic-Shirley",
        latitude: 40.798989,
        longitude: -72.864325,
        westName: "To New York & Points West",
        eastName: "To Speonk & Montauk",
        zone: 12,
        exclude: false,
        old_StopID: "127",
    },
    {
        code: "MAK",
        GTFS_StopID: "126",
        branch: "Ronkonkoma",
        name: "Mattituck",
        latitude: 40.99179,
        longitude: -72.536027,
        westName: "To New York & Points West",
        eastName: "To Greenport",
        zone: 14,
        exclude: false,
        old_StopID: "80",
    },
    {
        code: "MFD",
        GTFS_StopID: "129",
        branch: "Ronkonkoma",
        name: "Medford",
        latitude: 40.817415,
        longitude: -72.998593,
        westName: "To New York & Points West",
        eastName: "To Greenport",
        zone: 10,
        exclude: false,
        old_StopID: "77",
    },
    {
        code: "MAV",
        GTFS_StopID: "127",
        branch: "PortJefferson",
        name: "Merillon Av",
        latitude: 40.734941,
        longitude: -73.663482,
        westName: "To New York & Points West",
        eastName: "To Points East",
        zone: 4,
        exclude: false,
        old_StopID: "41",
    },
    {
        code: "MRK",
        GTFS_StopID: "226",
        branch: "Babylon",
        name: "Merrick",
        latitude: 40.663865,
        longitude: -73.55051,
        westName: "To New York & Points West",
        eastName: "To Babylon & Points East",
        zone: 7,
        exclude: false,
        old_StopID: "109",
    },
    {
        code: "SSM",
        GTFS_StopID: "199",
        branch: "PortWashington",
        name: "Mets-Willets Point",
        latitude: 40.752607,
        longitude: -73.84369,
        westName: "To Woodside & New York",
        eastName: "To Great Neck & Port Washington",
        zone: 1,
        exclude: false,
        old_StopID: "17",
    },
    {
        code: "MIN",
        GTFS_StopID: "132",
        branch: "PortJefferson",
        name: "Mineola",
        latitude: 40.74035,
        longitude: -73.640875,
        westName: "To New York & Points West",
        eastName: "To Points East",
        zone: 4,
        exclude: false,
        old_StopID: "42",
    },
    {
        code: "MTK",
        GTFS_StopID: "141",
        branch: "Montauk",
        name: "Montauk",
        latitude: 41.047455,
        longitude: -71.953691,
        westName: "Departures",
        eastName: "Arrivals",
        zone: 14,
        exclude: false,
        old_StopID: "138",
    },
    {
        code: "MHL",
        GTFS_StopID: "130",
        branch: "PortWashington",
        name: "Murray Hill",
        latitude: 40.762742,
        longitude: -73.814506,
        westName: "To New York",
        eastName: "To Great Neck & Port Washington",
        zone: 3,
        exclude: false,
        old_StopID: "19",
    },
    {
        code: "NBD",
        GTFS_StopID: "149",
        branch: "Hempstead",
        name: "Nassau Blvd",
        latitude: 40.722972,
        longitude: -73.663087,
        westName: "To New York & Points West",
        eastName: "To Hempstead",
        zone: 4,
        exclude: false,
        old_StopID: "36",
    },
    {
        code: "NHP",
        GTFS_StopID: "152",
        branch: "PortJefferson",
        name: "New Hyde Park",
        latitude: 40.730787,
        longitude: -73.680867,
        westName: "To New York & Points West",
        eastName: "To Points East",
        zone: 4,
        exclude: false,
        old_StopID: "40",
    },
    {
        code: "NPT",
        GTFS_StopID: "153",
        branch: "PortJefferson",
        name: "Northport",
        latitude: 40.880656,
        longitude: -73.328182,
        westName: "To New York & Points West",
        eastName: "To Port Jefferson",
        zone: 9,
        exclude: false,
        old_StopID: "62",
    },
    {
        code: "NAV",
        GTFS_StopID: "148",
        branch: "CityTerminalZone",
        name: "Nostrand Av",
        latitude: 40.678385,
        longitude: -73.948292,
        westName: "To Atlantic Terminal",
        eastName: "To Jamaica & Points East",
        zone: 1,
        exclude: false,
        old_StopID: "13",
    },
    {
        code: "ODL",
        GTFS_StopID: "157",
        branch: "Montauk",
        name: "Oakdale",
        latitude: 40.743465,
        longitude: -73.132483,
        westName: "To New York & Points West",
        eastName: "To Patchogue, Speonk, & Montauk",
        zone: 10,
        exclude: false,
        old_StopID: "122",
    },
    {
        code: "ODE",
        GTFS_StopID: "155",
        branch: "LongBeach",
        name: "Oceanside",
        latitude: 40.634766,
        longitude: -73.654655,
        westName: "To New York & Points West",
        eastName: "To Long Beach",
        zone: 7,
        exclude: false,
        old_StopID: "103",
    },
    {
        code: "OBY",
        GTFS_StopID: "154",
        branch: "OysterBay",
        name: "Oyster Bay",
        latitude: 40.875322,
        longitude: -73.533867,
        westName: "Departures",
        eastName: "Arrivals",
        zone: 7,
        exclude: false,
        old_StopID: "53",
    },
    {
        code: "PGE",
        GTFS_StopID: "163",
        branch: "Montauk",
        name: "Patchogue",
        latitude: 40.761894,
        longitude: -73.015576,
        westName: "To New York & Points West",
        eastName: "To Patchogue, Speonk, & Montauk",
        zone: 10,
        exclude: false,
        old_StopID: "124",
    },    
    {
        code: "PLN",
        GTFS_StopID: "165",
        branch: "Ronkonkoma",
        name: "Pinelawn",
        latitude: 40.74536,
        longitude: -73.399553,
        westName: "To New York & Points West",
        eastName: "To Ronkonkoma & Points East",
        zone: 9,
        exclude: false,
        old_StopID: "70",
    },
    {
        code: "PDM",
        GTFS_StopID: "162",
        branch: "PortWashington",
        name: "Plandome",
        latitude: 40.810545,
        longitude: -73.695241,
        westName: "To New York",
        eastName: "To Port Washington",
        zone: 4,
        exclude: false,
        old_StopID: "27",
    },
    {
        code: "PJN",
        GTFS_StopID: "164",
        branch: "PortJefferson",
        name: "Port Jefferson",
        latitude: 40.934476,
        longitude: -73.052448,
        westName: "Departures",
        eastName: "Arrivals",
        zone: 10,
        exclude: false,
        old_StopID: "67",
    },
    {
        code: "PWS",
        GTFS_StopID: "171",
        branch: "PortWashington",
        name: "Port Washington",
        latitude: 40.829154,
        longitude: -73.687394,
        westName: "Departures",
        eastName: "Arrivals",
        zone: 4,
        exclude: false,
        old_StopID: "28",
    },
    {
        code: "QVG",
        GTFS_StopID: "175",
        branch: "Hempstead",
        name: "Queens Village",
        latitude: 40.717524,
        longitude: -73.736219,
        westName: "To New York & Points West",
        eastName: "To Hempstead",
        zone: 3,
        exclude: false,
        old_StopID: "31",
    },
    {
        code: "RHD",
        GTFS_StopID: "176",
        branch: "Ronkonkoma",
        name: "Riverhead",
        latitude: 40.919801,
        longitude: -72.666859,
        westName: "To New York & Points West",
        eastName: "To Greenport",
        zone: 14,
        exclude: false,
        old_StopID: "79",
    },
    {
        code: "RVC",
        GTFS_StopID: "183",
        branch: "Babylon",
        name: "Rockville Centre",
        latitude: 40.658354,
        longitude: -73.646615,
        westName: "To New York & Points West",
        eastName: "To Babylon & Points East",
        zone: 7,
        exclude: false,
        old_StopID: "106",
    },
    {
        code: "RON",
        GTFS_StopID: "179",
        branch: "Ronkonkoma",
        name: "Ronkonkoma",
        latitude: 40.808121,
        longitude: -73.105946,
        westName: "To New York & Points West",
        eastName: "To Ronkonkoma & Points East",
        zone: 10,
        exclude: false,
        old_StopID: "75",
    },
    {
        code: "ROS",
        GTFS_StopID: "180",
        branch: "FarRockaway",
        name: "Rosedale",
        latitude: 40.66592,
        longitude: -73.735486,
        westName: "To New York",
        eastName: "To Points East",
        zone: 3,
        exclude: false,
        old_StopID: "86",
    },
    {
        code: "RSN",
        GTFS_StopID: "182",
        branch: "OysterBay",
        name: "Roslyn",
        latitude: 40.790543,
        longitude: -73.643281,
        westName: "To New York & Points West",
        eastName: "To Oyster Bay",
        zone: 7,
        exclude: false,
        old_StopID: "45",
    },
    {
        code: "SVL",
        GTFS_StopID: "204",
        branch: "Montauk",
        name: "Sayville",
        latitude: 40.740415,
        longitude: -73.086647,
        westName: "To New York & Points West",
        eastName: "To Patchogue, Speonk, & Montauk",
        zone: 10,
        exclude: false,
        old_StopID: "123",
    },
    {
        code: "SCF",
        GTFS_StopID: "185",
        branch: "OysterBay",
        name: "Sea Cliff",
        latitude: 40.85275,
        longitude: -73.625418,
        westName: "To New York & Points West",
        eastName: "To Oyster Bay",
        zone: 7,
        exclude: false,
        old_StopID: "48",
    },
    {
        code: "SFD",
        GTFS_StopID: "187",
        branch: "Babylon",
        name: "Seaford",
        latitude: 40.675764,
        longitude: -73.486477,
        westName: "To New York & Points West",
        eastName: "To Babylon & Points East",
        zone: 7,
        exclude: false,
        old_StopID: "112",
    },
    {
        code: "STN",
        GTFS_StopID: "202",
        branch: "PortJefferson",
        name: "Smithtown",
        latitude: 40.856617,
        longitude: -73.197677,
        westName: "To New York & Points West",
        eastName: "To Port Jefferson",
        zone: 10,
        exclude: false,
        old_StopID: "64",
    },
    {
        code: "SHN",
        GTFS_StopID: "191",
        branch: "Montauk",
        name: "Southampton",
        latitude: 40.895074,
        longitude: -72.389388,
        westName: "To New York & Points West",
        eastName: "To Montauk",
        zone: 14,
        exclude: false,
        old_StopID: "134",
    },
    {
        code: "SHD",
        GTFS_StopID: "190",
        branch: "Ronkonkoma",
        name: "Southold",
        latitude: 41.066317,
        longitude: -72.427843,
        westName: "To New York & Points West",
        eastName: "To Greenport",
        zone: 14,
        exclude: false,
        old_StopID: "81",
    },
    {
        code: "SPK",
        GTFS_StopID: "198",
        branch: "Montauk",
        name: "Speonk",
        latitude: 40.821326,
        longitude: -72.705339,
        westName: "To New York & Points West",
        eastName: "To Speonk & Montauk",
        zone: 12,
        exclude: false,
        old_StopID: "129",
    },
    {
        code: "SAB",
        GTFS_StopID: "184",
        branch: "WestHempstead",
        name: "St. Albans",
        latitude: 40.691267,
        longitude: -73.765491,
        westName: "To New York & Points West",
        eastName: "To Points East",
        zone: 3,
        exclude: false,
        old_StopID: "83",
    },
    {
        code: "SJM",
        GTFS_StopID: "193",
        branch: "PortJefferson",
        name: "St. James",
        latitude: 40.882284,
        longitude: -73.159342,
        westName: "To New York & Points West",
        eastName: "To Port Jefferson",
        zone: 10,
        exclude: false,
        old_StopID: "65",
    },
    {
        code: "SMR",
        GTFS_StopID: "195",
        branch: "Hempstead",
        name: "Stewart Manor",
        latitude: 40.723033,
        longitude: -73.681062,
        westName: "To New York & Points West",
        eastName: "To Hempstead",
        zone: 4,
        exclude: false,
        old_StopID: "35",
    },
    {
        code: "BK",
        GTFS_StopID: "14",
        branch: "PortJefferson",
        name: "Stony Brook",
        latitude: 40.920515,
        longitude: -73.128382,
        westName: "To New York & Points West",
        eastName: "To Port Jefferson",
        zone: 10,
        exclude: false,
        old_StopID: "66",
    },
    {
        code: "SYT",
        GTFS_StopID: "205",
        branch: "PortJefferson",
        name: "Syosset",
        latitude: 40.824828,
        longitude: -73.500547,
        westName: "To New York & Points West",
        eastName: "To Huntington & Port Jefferson",
        zone: 7,
        exclude: false,
        old_StopID: "58",
    },
    {
        code: "VSM",
        GTFS_StopID: "211",
        branch: "FarRockaway",
        name: "Valley Stream",
        latitude: 40.66155,
        longitude: -73.70481,
        westName: "To New York & Points West",
        eastName: "To Points East",
        zone: 4,
        exclude: false,
        old_StopID: "87",
    },
    {
        code: "WGH",
        GTFS_StopID: "215",
        branch: "Babylon",
        name: "Wantagh",
        latitude: 40.673048,
        longitude: -73.508888,
        westName: "To New York & Points West",
        eastName: "To Babylon & Points East",
        zone: 7,
        exclude: false,
        old_StopID: "111",
    },
    {
        code: "WHD",
        GTFS_StopID: "216",
        branch: "WestHempstead",
        name: "West Hempstead",
        latitude: 40.702026,
        longitude: -73.641543,
        westName: "Departures",
        eastName: "Arrivals",
        zone: 4,
        exclude: false,
        old_StopID: "92",
    },
    {
        code: "WBY",
        GTFS_StopID: "213",
        branch: "PortJefferson",
        name: "Westbury",
        latitude: 40.753538,
        longitude: -73.585559,
        westName: "To New York & Points West",
        eastName: "To Points East",
        zone: 7,
        exclude: false,
        old_StopID: "55",
    },
    {
        code: "WHN",
        GTFS_StopID: "233",
        branch: "Montauk",
        name: "Westhampton",
        latitude: 40.83032,
        longitude: -72.650084,
        westName: "To New York & Points West",
        eastName: "To Montauk",
        zone: 14,
        exclude: false,
        old_StopID: "130",
    },
    {
        code: "WWD",
        GTFS_StopID: "219",
        branch: "WestHempstead",
        name: "Westwood",
        latitude: 40.668379,
        longitude: -73.681183,
        westName: "To New York & Points West",
        eastName: "To West Hempstead",
        zone: 4,
        exclude: false,
        old_StopID: "88",
    },
    {
        code: "WMR",
        GTFS_StopID: "217",
        branch: "FarRockaway",
        name: "Woodmere",
        latitude: 40.631336,
        longitude: -73.713702,
        westName: "To New York & Points West",
        eastName: "To Far Rockaway",
        zone: 4,
        exclude: false,
        old_StopID: "95",
    },
    {
        code: "WDD",
        GTFS_StopID: "214",
        branch: "CityTerminalZone",
        name: "Woodside",
        latitude: 40.745822,
        longitude: -73.903003,
        westName: "To New York",
        eastName: "To Points East",
        zone: 1,
        exclude: false,
        old_StopID: "9",
    },
    {
        code: "WYD",
        GTFS_StopID: "220",
        branch: "Ronkonkoma",
        name: "Wyandanch",
        latitude: 40.754842,
        longitude: -73.357858,
        westName: "To New York & Points West",
        eastName: "To Ronkonkoma & Points East",
        zone: 9,
        exclude: false,
        old_StopID: "71",
    },
    {
        code: "YPK",
        GTFS_StopID: "223",
        branch: "Ronkonkoma",
        name: "Yaphank",
        latitude: 40.825615,
        longitude: -72.915855,
        westName: "To New York & Points West",
        eastName: "To Greenport",
        zone: 12,
        exclude: false,
        old_StopID: "78",
    },
    {
        code: "EMT",
        GTFS_StopID: "359",
        branch: "Hempstead",
        name: "Elmont-UBS Arena",
        latitude: 40.720074,
        longitude: -73.725549,
        westName: "To Jamaica & New York",
        eastName: "To Hempstead",
        zone: 4,
        exclude: false,
        old_StopID: "139",
    },
];
