import React, { Component } from "react";
import styled from "styled-components";
import { IAuthComponentProps } from "../../interfaces/ITemplateViewer";
import { NavbarComponent } from "../navbar/NavbarComponent";
import BlockUi from "react-block-ui";
import { LoadingSpinner } from "../common/LoadingSpinner";
import { NotificationContainer } from "react-notifications";
import { getDefaultScreenUrl, getScreenUrlByDevice } from "../../AppConfig";
import Select from "react-select";
import { GetDevices, GetScreens, GetStations } from "../../apis/HeliumApi";
import { IDeviceDto } from "../../apis/interfaces/IDeviceDto";

interface ISubwayComponentState {
  loading: boolean;
  showError: any;
  blocking: boolean;
  showScreen: boolean;
  screens: IDeviceDto[];
  stations: IDeviceDto[];
  devices: IDeviceDto[];
  selectedStation: IDeviceDto;
  selectedDevice: IDeviceDto;
  apiError?: any;
  currStationIndex: number;
  currDeviceIndex: number;
}

export class SubwayScreenComponent extends Component<
  IAuthComponentProps,
  ISubwayComponentState
> {
  InitialDeviceInfo: IDeviceDto = {
    name: "",
    type: "",
    mrn: 0,
    station_name: "Select Station",
    lines: [],
    direction: "",
    id: "",
  };

  constructor(props: IAuthComponentProps) {
    super(props);
    this.state = {
      loading: false, // true
      showError: false,
      blocking: false,
      showScreen: false,
      screens: new Array<IDeviceDto>(),
      stations: new Array<IDeviceDto>(),
      devices: new Array<IDeviceDto>(),
      selectedStation: this.InitialDeviceInfo,
      selectedDevice: this.InitialDeviceInfo,
      apiError: null,
      currStationIndex: -1,
      currDeviceIndex: -1,
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData = async () => {
    try {
      const screens = await GetScreens();
      const stations = await GetStations(screens ? screens : []);
      this.setState({
        screens,
        stations,
        loading: false,
      });
    } catch (error) {
      this.setState({ showError: true, apiError: error, loading: false });
    }
  };

  render() {
    const { loading, showError } = this.state;
    if (loading) {
      return <LoadingSpinner />;
    }

    if (showError) {
      //return <ErrorFullPageComponent apiError={apiError} location={this.props.location} authUser={this.props.wrapper.authUser} />
    }

    return this.renderSubwayForm();
  }

  renderSubwayForm = () => {
    const isEnabled = this.canBeSubmitted();
    const { selectedDevice, showScreen } = this.state;

    return (
      <div className="container">
        <NavbarComponent
          currentPath={this.props.location.pathname}
          activeCheck="idSubwayScreens"
          // authUser={this.props.wrapper.authUser}
        />
        <div className="card mt-2 bg-white pt-5">
          <div className="card-body">
            <BlockUi tag="div" blocking={this.state.blocking}>
              <h5 className="card-title ">Subway</h5>
              <hr />
              <form autoComplete="off">
                <fieldset>
                  <div className="row">
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-md-10">{this.renderStations()}</div>
                      </div>
                      <div className="row">
                        <div className="col-md-10">{this.renderDevices()}</div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div className="form-group">
                            <button
                              type="button"
                              className="btn btn-success btn-sm"
                              disabled={!isEnabled}
                              onClick={() =>
                                this.openInNewTab(
                                  `/#/heliumscreen/${selectedDevice.name}/${selectedDevice.type}`
                                )
                              }
                            >
                              Show in Full Screen
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-md-12">
                          {showScreen === false && (
                            <VerticalContainer>
                              <VerticalIFrame
                                src={getDefaultScreenUrl()}
                                frameBorder="0"
                                scrolling="no"
                              />
                            </VerticalContainer>
                          )}

                          {showScreen === true &&
                            selectedDevice.name !== "" &&
                            (selectedDevice.type === "CIC" || selectedDevice.type === "STRIP_MAP" )  && (
                              <VerticalContainer>
                                <VerticalIFrame
                                  src={getScreenUrlByDevice(
                                    selectedDevice.name
                                  )}
                                  frameBorder="0"
                                  scrolling="no"
                                />
                              </VerticalContainer>
                            )}

                          {showScreen === true &&
                            selectedDevice.name !== "" &&
                            selectedDevice.type === "DUP" && (
                              <HorizontalContainer>
                                <HorizontalIFrame
                                  src={getScreenUrlByDevice(
                                    selectedDevice.name
                                  )}
                                  frameborder="0"
                                  scrolling="no"
                                />
                              </HorizontalContainer>
                            )}
                        </div>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </form>
            </BlockUi>
          </div>
        </div>
        <NotificationContainer />
      </div>
    );
  };

  renderStations = () => {
    return (
      <div className="form-group required">
        <label className="control-label">
          <b>Station</b>
        </label>
        <Select
          options={this.state.stations}
          placeholder="Select Station"
          onChange={this.handleStationChange}
          formatOptionLabel={(station) => (
            <div>{this.renderStationInfo(station)}</div>
          )}
          getOptionValue={(station) => station.id} // this will avoid the default blue background color all rows
          isClearable={false}
          isSearchable={true}
          value={this.state.selectedStation}
        />
      </div>
    );
  };

  renderStationInfo = (station: IDeviceDto) => {
    return (
      <div>
        <span>{station.station_name}</span>
        &nbsp;
        {station.lines.map((line) => {
          return (
            <img
              src={`${process.env.REACT_APP_AppBaseUrl}/assets/images/routes/${line}.svg`}
              key={`${line}`}
              alt="route-image"
              style={{ padding: 2 }}
            />
          );
        })}
      </div>
    );
  };

  handleStationChange = (station: any) => {
    const { screens } = this.state;
    const selectedStation = !station || station.id === "" ? this.InitialDeviceInfo : station;
    const selectedDevice = this.InitialDeviceInfo;
    const devices = GetDevices(screens, station.id);
    this.setState({
      devices,
      selectedStation,
      selectedDevice,
      currDeviceIndex: -1,
      showScreen: this.showScreen(selectedStation, selectedDevice),
    });
  };

  renderDevices = () => {
    return (
      <div className="form-group required">
        <label className="control-label">
          <b>Device</b>
        </label>
        <select
          className="form-control"
          id="actionSelect"
          onChange={this.handleDeviceChange}
          value={this.state.currDeviceIndex}
        >
          <option key="0" value={-1}>
            Select Device
          </option>
          {this.state.devices.map((device, index) => {
            return (
              <option key={device.name} value={index}>
                {device.name}
              </option>
            );
          })}
        </select>
      </div>
    );
  };

  handleDeviceChange = (e: any) => {
    const selectedStation = this.state.selectedStation;
    const selectedIndex = parseInt(e.target.value);
    const selectedDevice =
      selectedIndex < 0
        ? this.InitialDeviceInfo
        : this.state.devices[selectedIndex];

    this.setState({
      selectedDevice,
      showScreen: this.showScreen(selectedStation, selectedDevice),
      currDeviceIndex: selectedIndex,
    });
  };

  canBeSubmitted = () => {
    const { selectedStation, selectedDevice } = this.state;

    if (selectedStation.id === "" || selectedDevice.name === "") {
      return false;
    }
    return true;
  };

  showScreen = (selectedStation: IDeviceDto, selectedDevice: IDeviceDto) => {
    const showScreen =
      selectedStation.id === "" || selectedDevice.name === "" ? false : true;

    return showScreen;
  };

  openInNewTab = (url: string) => {
    const newWindow = window.open(url, "_blank", "noopener,noreferrer");
    if (newWindow) newWindow.opener = null;
  };

}

const VerticalContainer = styled.div`
  width: calc(1080px * 0.5);
  height: calc(1920px * 0.5);
  position: relative;
  margin-left: 50px;
`;

const VerticalIFrame = styled.iframe`
  width: 1080px;
  height: 1920px;
  transform: scale(0.4);
  transform-origin: 0 0;
  position: absolute;
  left: 0;
  top: 0;
`;

const HorizontalContainer = styled.div`
  width: calc(1920px * 0.5);
  height: calc(1080px * 0.5);
  position: relative;
`;

const HorizontalIFrame = styled.iframe`
  width: 1920px;
  height: 1080px;
  transform: scale(0.37);
  transform-origin: 0 0;
  position: absolute;
  left: 0;
  top: 0;
`;

const Wrapper = styled.div`
  margin: 10px auto;
`;
