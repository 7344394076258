import React, { Component } from "react";
import { RouteComponentProps } from "react-router-dom";
import { getScreenUrl } from "../../AppConfig";
import { IAuthComponentProps } from "../../interfaces/ITemplateViewer";
import { templateJsonArray } from "../common/templateInfoJson";

interface IFullScreenComponentState {
    urlparams: any;
}

export class FullScreenComponent extends Component<IAuthComponentProps, IFullScreenComponentState> {
    constructor(props: IAuthComponentProps) {
        super(props);
        this.state = {
            urlparams: this.props.match.params,
        }
    }

  render() {
    const { urlparams } = this.state;
    const template = templateJsonArray.filter(({ Id }) => Id === this.state.urlparams.templateId);
    const track = !urlparams.track ? "" : urlparams.track;
    const url = getScreenUrl(urlparams.stationId, template[0], track);

    return (
      <div>
        <div className="container">
          <iframe
            src={url}
            frameBorder="0"
            scrolling="no"
            width={template[0].Width}
            height={template[0].Height}
          />
        </div>
      </div>
    );
  }
}
