import { IReleasesInfo } from "./interfaces/IReleasesInfo";

//Add latest release at position 1 in json to render latest on top
export const releaseInfoJson: IReleasesInfo[] = [
  {
    ReleaseId: "OFMTEMPL03142023",
    ReleaseDate: "03/14/2023",
    DownloadPath:
      "https://outfrontscreens.mta.info/release/Templates_03142023.zip",
    agencyInfo: [
      {
        Agency: "Subway/MNR/LIRR",
        screens: [
          {
            ScreensChanged: "All Screens",
            ReleaseInfo:
              "Colors are updated for subway routes, Fallback messages were updated for all templates, Minor fix on API calls for all templates",
          },
        ],
      },
    ],
  },
];
