import axios from "axios";
import { buildLoginUrlWithError } from "./AppConfig";

class AxiosConfig {
    private axiosConfigured = false;

    configure() {
        if (this.axiosConfigured) {
            console.log('axios already configured.');
            return;
        }
        this.axiosConfigured = true;

        axios.defaults.baseURL = `${process.env.REACT_APP_ApiBaseUrl}/api`;
        axios.defaults.xsrfCookieName = 'XSRF-TOKEN';
        axios.defaults.xsrfHeaderName = 'X-XSRF-TOKEN'; // default value; keeping it here anyway
        axios.defaults.withCredentials = true;

        // response interceptor
        axios.interceptors.response.use(undefined,
            error => this.axiosResponseError(error).catch((reason: any) => {
                if (reason && reason.redirectToLogin) {
                    const error = (reason.axiosError && reason.axiosError.response) ? reason.axiosError.response.data : '';
                    window.location.href = buildLoginUrlWithError(error);
                }
                return Promise.reject(reason);
            })
        );
    }

    private axiosResponseError(error: any) {
        const { response } = error;

        if (!response || !response.headers) {
            return Promise.reject(error);
        }

        if (response.status === 400 || response.status === 403) {
            return Promise.reject(error);
        }

        if (response.status === 401) {
            // Reject with a custom object for easy debugging
            return Promise.reject({
                redirectToLogin: true,
                axiosError: error
            });
        }

        return Promise.reject(error);
    }
}

const axiosConfig = new AxiosConfig();
export { axiosConfig }
